import { useState, useEffect } from 'react';

const useForm = (
    notificarErroPreenchimentoFormulario,
    estadoFormulario,
    setEstadoFormulario,
    restringirBotoes,
    callback,
    dadosPadrao,
    atualizar_estrutura_modulo_frete,
    validar
  ) => {
    const [erros, setErros] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Função acionada após alterar o campo "Desconto WEG(%)"
    function alteracao_desconto_WEG(e, indice) {
      if (indice > 0 && indice < 4) {
        restringirBotoes();

        let novoArrayDescontoWEG = estadoFormulario.desconto_weg_proposta;

        novoArrayDescontoWEG[indice] = parseFloat(e.target.value);

        setValueObject({
          desconto_weg_proposta: novoArrayDescontoWEG,
        });

      } else {
        throw (new Error("Índice inválido"));
      }
    }

    // Função acionada após alterar o campo "Lista de preços"
    function alteracao_tabela(e, tabelas_validas) {
      restringirBotoes();

      let dados_tabela_selecionada = {};

      for (let i in tabelas_validas) {
          if (tabelas_validas[i].chave === e.target.value) {
          dados_tabela_selecionada = tabelas_validas[i];
        }
      }

      setValueObject({
        tabela: e.target.value,
        modulo_modelo: dados_tabela_selecionada.modulo_preferencial.modelo,
        modulo_potencia: dados_tabela_selecionada.modulo_preferencial.potencia,
        modulo_area: dados_tabela_selecionada.modulo_preferencial.area,
        modulo_peso: dados_tabela_selecionada.modulo_preferencial.peso,
        modulo_garantia1: dados_tabela_selecionada.modulo_preferencial.garantia1,
        modulo_garantia2: dados_tabela_selecionada.modulo_preferencial.garantia2,
        modulo_campo_adicional1: dados_tabela_selecionada.modulo_preferencial.campo_adicional1
      }); 

      atualizar_estrutura_modulo_frete(e.target.value, tabelas_validas);

    }

    // Função acionada após alterar o campo "Concessionária"
    function alteracao_concessionaria(e, concessionarias) {
      restringirBotoes();

      const concessionaria_selecionada = e.target.value;
      const array_concessionarias = Object.assign({}, ...concessionarias.map((x) => ({[x.nome_concessionaria]: [x.parcela_icms_concessionaria, x.tarifa_padrao_concessionaria]})));
      
      if(concessionaria_selecionada !== ""){
        const { name, value } = e.target;
        const novoValor = {
          ...estadoFormulario,
          [name]: value,
          ['relacao_ICMS']: Math.round(array_concessionarias[concessionaria_selecionada][0] * 100) / 100,
          ['tarifa']: Math.round(array_concessionarias[concessionaria_selecionada][1] * 100) / 100
        }

        setEstadoFormulario(novoValor);
      }
    }

    // Função acionada após alterar o campo "Tipo inversor"
    function alteracao_tipo_inversor(e) {
      restringirBotoes();

      const tipo_selecionado = e.target.value;
      
      if(tipo_selecionado !== ""){
        const { name, value } = e.target;
        const novoValor = {
          ...estadoFormulario,
          [name]: value,
        }

        setEstadoFormulario(novoValor);
      }
    }

    // Função acionada após alterar o campo "Agente Comercial"
    function alteracao_responsavel(e, responsaveis) {
      restringirBotoes();
      
      const responsavel_selecionado = e.target.value;
      const array_responsaveis = Object.assign({}, ...responsaveis.map((x) => ({[x.nome_usuario]: [x.id_usuario, x.comissao_usuario]})));
      if(responsavel_selecionado !== ""){
        const { name, value } = e.target;

        const novoValor = {
          ...estadoFormulario,
          [name]: value,
          ['id_responsavel']: array_responsaveis[responsavel_selecionado][0],
          ['comissao']: parseFloat(array_responsaveis[responsavel_selecionado][1].toFixed(2))
        }

        setEstadoFormulario(novoValor);
      }
    }


    
    // Revisar abaixo nomes e nomes banco tbm
    // Função acionada após alterar o campo "Tipo de telhado"
    function alteracao_estrutura(e, telhados) {
      restringirBotoes();
      
      const telhado_selecionado = e.target.value;
      const array_telhado = Object.assign({}, ...telhados.map((x) => ({[x.aplicacao_estrutura]: [x.tipo_estrutura]})));
      if(telhado_selecionado !== ""){
        const { name, value } = e.target;
        const novoValor = {
          ...estadoFormulario,
          ['aplicacao_estrutura']: value,
          ['tipo_estrutura']: array_telhado[telhado_selecionado][0]
        }

        setEstadoFormulario(novoValor);
      }
    }

    // Função acionada após alterar o campo "Módulo"
    function alteracao_modulo(e, modulos) {
      restringirBotoes();
      
      const modulo_selecionado = e.target.value;
      const array_modulos = Object.assign({}, ...modulos.map((x) => ({[x.modelo]: [x.potencia, x.area, x.peso, x.garantia1, x.garantia2, x.campo_adicional1]})));
      if(modulo_selecionado !== ""){
        const { name, value } = e.target;
        const novoValor = {
          ...estadoFormulario,
          [name]: value,
          ['modulo_potencia']: Math.round(array_modulos[modulo_selecionado][0] * 100) / 100,
          ['modulo_area']: array_modulos[modulo_selecionado][1],
          ['modulo_peso']: array_modulos[modulo_selecionado][2],
          ['modulo_garantia1']: array_modulos[modulo_selecionado][3],
          ['modulo_garantia2']: array_modulos[modulo_selecionado][4],
          ['modulo_campo_adicional1']: array_modulos[modulo_selecionado][5]
        }      

        setEstadoFormulario(novoValor);
      }
    }

    // Função acionada após alterar o campo "Frete"
    function alteracao_frete(e, tipos_frete) {
      restringirBotoes();
      
      const frete_selecionado = e.target.value;
      const array_tipos_frete = Object.assign({}, ...tipos_frete.map((x) => ({[x.tipo]: [x.acrescimo]})));
      if(frete_selecionado !== ""){
        const { name, value } = e.target;
        const novoValor = {
          ...estadoFormulario,
          [name]: value,
          ['acrescimo_frete']: Math.round(array_tipos_frete[frete_selecionado][0] * 100) / 100
        }

        setEstadoFormulario(novoValor);
      }
    }

    // Função acionada ao pressionar o botão "Digitar mês a mês"
    function apagar_consumo() {
      restringirBotoes();

      const curva_vazia = ['', '', '', '', '', '', '', '', '', '', '', '']

      setValueObject({
        consumo_medio: '',
        curva_consumo: curva_vazia,
        consumo_janeiro: '',
        consumo_fevereiro: '',
        consumo_marco: '',
        consumo_abril: '',
        consumo_maio: '',
        consumo_junho: '',
        consumo_julho: '',
        consumo_agosto: '',
        consumo_setembro: '',
        consumo_outubro: '',
        consumo_novembro: '',
        consumo_dezembro: ''
      });
    }

    // Função acionada ao pressionar o botão "Restaurar Curva de Consumo"
    function restaurar_curva_consumo() {
      restringirBotoes();

      const curva_consumo_padrao = Object.values(dadosPadrao.curvas.consumo1);
      const media_curva_consumo_padrao = (curva_consumo_padrao.reduce((somatorio, valor) => somatorio + valor)) / 12;
      
      // Verifica se curva_consumo possui algum valor não numério -> valor excluido ou função "Digitar mês a mês" utilizada.
      const verificacao_curva_consumo = estadoFormulario.curva_consumo.map(x => isNaN(x)).filter(x => x !== false);
      const consumo_medio_atual = (verificacao_curva_consumo.length > 0 ? (
        estadoFormulario.consumo_medio
      ) : (
        (estadoFormulario.curva_consumo.reduce((somatorio, valor) => somatorio + valor)) / 12)
      );

      const taxa = consumo_medio_atual / media_curva_consumo_padrao;
      const nova_curva_consumo = curva_consumo_padrao.map((x) => (x * taxa));
      const consumo_exibição = nova_curva_consumo.map((x) => (Math.round(x * 100) / 100));

      setValueObject({
        curva_consumo: nova_curva_consumo,
        consumo_janeiro: consumo_exibição[0],
        consumo_fevereiro: consumo_exibição[1],
        consumo_marco: consumo_exibição[2],
        consumo_abril: consumo_exibição[3],
        consumo_maio: consumo_exibição[4],
        consumo_junho: consumo_exibição[5],
        consumo_julho: consumo_exibição[6],
        consumo_agosto: consumo_exibição[7],
        consumo_setembro: consumo_exibição[8],
        consumo_outubro: consumo_exibição[9],
        consumo_novembro: consumo_exibição[10],
        consumo_dezembro: consumo_exibição[11]
      });
    }

    // Função acionada após alterar o campo "Média de consumo"
    function alteracao_media(e) {
      restringirBotoes();

      const novo_consumo_medio = Math.round(parseFloat(e.target.value));
      const consumo_medio_atual = (estadoFormulario.curva_consumo.reduce((somatorio, valor) => somatorio + valor)) / 12;
      const taxa = novo_consumo_medio / consumo_medio_atual;

      const nova_curva_consumo = estadoFormulario.curva_consumo.map((x) => (x * taxa));
      const consumo_exibido = nova_curva_consumo.map((x) => (Math.round(x * 100) / 100));

      const novoValor = {
        ...estadoFormulario,
        ['consumo_medio']: novo_consumo_medio,
        ['curva_consumo']: nova_curva_consumo,
        ['consumo_janeiro']: consumo_exibido[0],
        ['consumo_fevereiro']: consumo_exibido[1],
        ['consumo_marco']: consumo_exibido[2],
        ['consumo_abril']: consumo_exibido[3],
        ['consumo_maio']: consumo_exibido[4],
        ['consumo_junho']: consumo_exibido[5],
        ['consumo_julho']: consumo_exibido[6],
        ['consumo_agosto']: consumo_exibido[7],
        ['consumo_setembro']: consumo_exibido[8],
        ['consumo_outubro']: consumo_exibido[9],
        ['consumo_novembro']: consumo_exibido[10],
        ['consumo_dezembro']: consumo_exibido[11]
      }

      setEstadoFormulario(novoValor);

    }

    // Função acionada após alterar o consumo de qualquer um dos 12 meses
    function alteracao_mes_consumo(e, indice) {
      restringirBotoes();

      const novo_consumo_mes = Math.round(parseFloat(e.target.value));

      const string_meses = [
        'consumo_janeiro',
        'consumo_fevereiro',
        'consumo_marco',
        'consumo_abril',
        'consumo_maio',
        'consumo_junho',
        'consumo_julho',
        'consumo_agosto',
        'consumo_setembro',
        'consumo_outubro',
        'consumo_novembro',
        'consumo_dezembro'
      ];
      const mes_modificado = string_meses[indice];

      let nova_curva_consumo = estadoFormulario.curva_consumo;
      nova_curva_consumo[indice] = novo_consumo_mes;

      const novo_consumo_medio = Math.round((nova_curva_consumo.reduce((acc, cur) => acc + cur)) / 12);

      const novoValor = {
        ...estadoFormulario,
        ['consumo_medio']: novo_consumo_medio,
        ['curva_consumo']: nova_curva_consumo,
        [mes_modificado]: novo_consumo_mes
      }

      setEstadoFormulario(novoValor);
    }

    // Função acionada ao pressionar o botão "Restaurar Curva de Geração"
    function restaurar_curva_geracao(e) {
      e.preventDefault();
      restringirBotoes();

      const curva_geracao_padrao = Object.values(dadosPadrao.curvas.geracao1);
      
      setValueObject({
        relacao_geracao: dadosPadrao.informacao_complementar_float.relacao_geracao_padrao.valor,
        curva_geracao: curva_geracao_padrao,
        geracao_janeiro: curva_geracao_padrao[0],
        geracao_fevereiro: curva_geracao_padrao[1],
        geracao_marco: curva_geracao_padrao[2],
        geracao_abril: curva_geracao_padrao[3],
        geracao_maio: curva_geracao_padrao[4],
        geracao_junho: curva_geracao_padrao[5],
        geracao_julho: curva_geracao_padrao[6],
        geracao_agosto: curva_geracao_padrao[7],
        geracao_setembro: curva_geracao_padrao[8],
        geracao_outubro: curva_geracao_padrao[9],
        geracao_novembro: curva_geracao_padrao[10],
        geracao_dezembro: curva_geracao_padrao[11]
      });
    }
    
    // Função acionada após alterar a geracao de qualquer um dos meses
    function alteracao_mes_geracao(e, indice) {
      restringirBotoes();
      const nova_geracao = parseFloat(e.target.value);

      if (nova_geracao > 0) {
        let array = estadoFormulario.curva_geracao;
        array[indice] = nova_geracao;

        const { name, value } = e.target;

        setEstadoFormulario({
          ...estadoFormulario,
          [name]: nova_geracao,
          ['curva_geracao']: array
        });
      }
    }

    function setValueObject(object) {
      setEstadoFormulario({
        ...estadoFormulario,
        ...object
      });
    }

    function handleChange(e) {
      restringirBotoes();
      
      const { name, value } = e.target;
      const novoValor = {
        ...estadoFormulario,
        [name]: parseFloat(value)
      }

      setEstadoFormulario(novoValor);
    };

    const handleSubmit = e => {
      e.preventDefault();

      const resultadoValidacao = validar(estadoFormulario);
      setErros(resultadoValidacao);

      Object.keys(resultadoValidacao).length > 0 && notificarErroPreenchimentoFormulario();

      setIsSubmitting(true);
    };

    useEffect(
      () => {
        if (Object.keys(erros).length === 0 && isSubmitting) {
          callback();
        };
      },
      [erros]
    );

    return {
      erros,
      alteracao_desconto_WEG,
      alteracao_tabela,
      alteracao_concessionaria,
      alteracao_tipo_inversor,
      alteracao_responsavel,
      alteracao_estrutura,
      alteracao_modulo,
      alteracao_frete,
      apagar_consumo,
      restaurar_curva_consumo,
      alteracao_media,
      alteracao_mes_consumo,
      restaurar_curva_geracao,
      alteracao_mes_geracao,
      setValueObject,
      handleChange,
      handleSubmit
    };
};

export default useForm;