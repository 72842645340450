// Referências externas
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Referências internas
import { Header, Loading } from '../../common'
import api from '../../services/api';
import Resumo from './Componentes/Resumo';

// Estilo
import './styles.css';

// Referências internas
const PropostasResumidasSimulacao = props => {
    const history = useHistory();

    const [token, setToken] = useState({});

    const [resumo, setResumo] = useState({
        sistema_sugerido: 0,
        potencia_total: 0
    });
    
    const [done, setDone] = useState(false);

    const [vocativo, setVocativo] = useState('');
    const [numero_proposta, setNumero_proposta] = useState(0);
    const [revisao_proposta, setRevisao_proposta] = useState(0);
    const [data_proposta_string, setData_proposta_string] = useState(0);

    // Dados enviados para <Form />
    const [dadosRoute, setDadosRoute] = useState('');

    // Variáveis Detalhes
    const [sistema_modulo, setSistema_modulo] = useState([{}]);
    const [sistema_inversor, setSistema_inversor] = useState([{}]);
    const [sistema_outros, setSistema_outros] = useState([{}]);
    const [dimensionamento, setDimensionamento] = useState('');

    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function identificacaoProposta(proposta) {
        try {
            const titulo_cliente = proposta.titulo_cliente
            const nome_razao_cliente = proposta.nome_razao_cliente
            let vocativo_definido;
            
            if (titulo_cliente === "Sr." || titulo_cliente === "Sra.") {
                vocativo_definido = `${titulo_cliente} ${nome_razao_cliente}`;
                setVocativo(vocativo_definido);
                
            } else if (titulo_cliente === "N/A"){
                vocativo_definido = nome_razao_cliente;
                setVocativo(vocativo_definido);
            }

        } catch (error) {
            history.push('/sistemas');
        }
    }

    function tituloPagina(proposta) {
        let vocativo_definido;

        if (proposta !== undefined && proposta.titulo_cliente !== undefined) {
            if (proposta.titulo_cliente === "Sr." || proposta.titulo_cliente === "Sra.") {
                vocativo_definido = `${proposta.titulo_cliente} ${proposta.nome_razao_cliente}`;
            } else if (proposta.titulo_cliente === "N/A"){
                vocativo_definido = proposta.nome_razao_cliente;
            }
    
            if (proposta.numero_proposta !== undefined && proposta.revisao_proposta !== undefined && vocativo_definido !== undefined) {
                if (proposta.revisao_proposta > 1) {
                    document.title = `${proposta.numero_proposta}_v${proposta.revisao_proposta} ${vocativo_definido}`
                } else {
                    document.title = `${proposta.numero_proposta} ${vocativo_definido}`
                }
                
            } else {
                proposta.numero_proposta === undefined && console.log('erro: ' + 'proposta.numero_proposta')
                proposta.revisao_proposta === undefined && console.log('erro: ' + 'proposta.revisao_proposta')
                vocativo_definido === undefined &&  console.log('erro: ' + 'vocativo_definido')
            }
        }
    }

    function verificarPropostaExistente(proposta_salva) {
        try {
            if (proposta_salva !== undefined) {
                setNumero_proposta((proposta_salva.numero_proposta));
                setRevisao_proposta(proposta_salva.revisao_proposta);
                setData_proposta_string((new Date(proposta_salva.data_apresentacao_proposta)).toLocaleDateString());

                setResumo({
                    sistema_sugerido: proposta_salva.sistema_sugerido,
                    potencia_total: proposta_salva.potencia_total,
                });

                setSistema_modulo(proposta_salva.sistema_modulo);
                setSistema_inversor(proposta_salva.sistema_inversor);
                setSistema_outros(proposta_salva.sistema_outros);
                setDimensionamento(proposta_salva.dimensionamento);

            } else {
                // history.push('/sistemas');
            }

        } catch (error) {  }
    }

    useEffect(() => {
        document.title = `SGC Brazil Solution`;

        const tokenJSON = JSON.parse(localStorage.getItem('sgc_br'));
        setToken(tokenJSON);

        async function carregarDados() {
            setDone(false);
    
            try {
                const response = await api.get('dados');

                setDadosRoute(response.data);

                identificacaoProposta(props.location.data);
                verificarPropostaExistente(props.location.proposal);

                tituloPagina(props.location.proposal);
                
            } catch (error) {
                notificarErro();
            }

            setDone(true);
        }
        
        carregarDados();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <div className="container">
            <Header />
            <ToastContainer />
            
            <div className="body-container">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="type1-title"> DETALHES SISTEMA </div>
                        
                        <button className="type1-main-button" onClick={() => history.push('/sistemas')} >
                            <FiArrowLeft/>
                            <span>Voltar</span>
                        </button>
                    </div>
                    <div className="proposal-client-title">
                        <table>
                            <thead>
                                {numero_proposta === 0 ? (
                                    <tr>
                                        <th>Cliente:</th><td>{vocativo}</td>
                                    </tr>
                                ) : (
                                    <>
                                        <tr>
                                            <th>Cliente:</th><td colSpan="3">{vocativo}</td>
                                        </tr>
                                        <tr>
                                            <th>Nº proposta:</th><td>{numero_proposta}</td><th>Versão:</th><td>{revisao_proposta}ª</td>
                                        </tr>
                                        <tr>
                                            <th>Data:</th><td colSpan="3">{data_proposta_string}</td>
                                        </tr>
                                    </>
                                )}
                            </thead>
                        </table>
                    </div>
                </div>

                <div className="proposal-container">
                    <div className="proposal-element-wide-wrapper-sistemas">
                        <Tabs>
                            <TabList>
                                <Tab>Kit considerado</Tab>
                            </TabList>

                            <div className="proposal-subelement">
                                <div className="proposal-element-body" style={{width: '70%'}}>

                                    <TabPanel>
                                        {!done ? (
                                            <div>
                                                <Loading />
                                            </div>
                                        ) : (
                                            <>
                                                Sistema fotovoltaico
                                                <table className="table-kit" >
                                                    <thead>
                                                        <tr>
                                                            <th className="table-kit-narrow">Quantidade</th>
                                                            <th>Item</th>
                                                        </tr>
                                                    </thead>
                                                    
                                                    <tbody>
                                                        {sistema_modulo.map((item, indice) => {
                                                            return item.quant === null ?
                                                            <tr key="0"></tr>
                                                            : item.quant === 0 ?
                                                            <tr key="0"></tr>
                                                            :
                                                            <tr key={indice}>
                                                                <td>{parseFloat(item.quant).toLocaleString('pt-BR', {style: 'decimal'})}</td>
                                                                <td>{item.modelo}</td>
                                                            </tr>
                                                        })}

                                                        {sistema_inversor.map(item => {
                                                            return item.quant == null ?
                                                            <tr key="0"></tr>
                                                            : item.quant === 0 ?
                                                            <tr key="0"></tr>
                                                            :
                                                            <tr key={item.modelo}>
                                                                <td>{parseFloat(item.quant).toLocaleString('pt-BR', {style: 'decimal'})}</td>
                                                                <td>{item.modelo}</td>
                                                            </tr>
                                                        })}

                                                        {sistema_outros.map(item => {
                                                            return item.quant == null ?
                                                            <tr key="0"></tr>
                                                            : item.quant === 0 ?
                                                            <tr key="0"></tr>
                                                            :
                                                            <tr key={item.modelo}>
                                                                <td>{parseFloat(item.quant).toLocaleString('pt-BR', {style: 'decimal'})}</td>
                                                                <td>{item.modelo}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>

                                                <div className="proposal-same-line-tables">
                                                    <div className="proposal-table-dimensioning">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="table-kit-wide">Dimensionamento</th>
                                                                    <td className="table-kit-narrow">{dimensionamento}</td>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </TabPanel>
                                
                                
                                </div>
                            </div>
                        </Tabs>
                    </div>
                    
                    <div className="proposal-element-narrow-wrapper-sistemas">
                        <Tabs>
                            <TabList>
                                <Tab>Resumo</Tab>
                            </TabList>

                            <div className="proposal-subelement">
                                <div className="proposal-element-body" style={{width: '100%'}}>
                                    <TabPanel>
                                        <Resumo token={token} done={done} resumo={resumo} tipo_estrutura={props.location.proposal.tipo_estrutura} dadosRoute={dadosRoute} sistema_modulo={sistema_modulo} sistema_inversor={sistema_inversor}/>
                                    </TabPanel>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropostasResumidasSimulacao;