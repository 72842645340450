// Referências externas
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { FiUserPlus, FiEye, FiEyeOff, FiPlus, FiRotateCw, FiInfo, FiCopy } from 'react-icons/fi';
import 'react-toastify/dist/ReactToastify.css';

// Referências internas
import api from '../../services/api';
import { Header, Loading, DynamicLoading } from '../../common';
import ModalConfirmarExclusao from './Formulario/ModalConfirmarExclusao';
import ModalUsuario from './Formulario/ModalUsuario';
import ModalForm from './Formulario/ModalForm';
import ModalCadastrarUsuario from './CadastrarUsuario/ModalCadastrarUsuario';

// Estilo
import './styles.css';
import './styles-usuario.css';

// Referências internas
const permissaoUsuario = require('../../services/permissaoUsuario');

export default function PainelControle(location) {
    const history = useHistory();
    const [token, setToken] = useState({});
    const [done, setDone] = useState(true);

    // const [removeCookie] = useCookies(["sessionId"]);
    // const [editMode, setEditMode] = useState(false);
    // const [dados, setDados] = useState(false);
    // const [modalCadastroAberto, setModalCadastroAberto] = useState(false);


    // #################### INÍCIO Bloco Revogar acesso e Usuários conectados ####################
    const [botaoRevogarCarregando, setBotaoRevogarCarregando] = useState(false);
    const [emailUsuarioRevogado, setEmailUsuarioRevogado] = useState('');


    // #################### FIM Bloco Revogar acesso e Usuários conectados ####################
    

    const [botaoAlterarCarregando, setBotaoAlterarCarregando] = useState(false);
    const [emailUsuarioAlteracaoSenha, setEmailUsuarioAlteracaoSenha] = useState('');
    const [novaSenhaUsuario, setNovaSenhaUsuario] = useState('');
    const [confirmacaoNovaSenhaUsuario, setConfirmacaoNovaSenhaUsuario] = useState('');
    const [senhaAdministradorConectado, setSenhaAdministradorConectado] = useState('');

    const [botaoMoverCarregando, setBotaoMoverCarregando] = useState(false);
    const [emailUsuarioAtual, setEmailUsuarioAtual] = useState('');
    const [emailUsuarioDestino, setEmailUsuarioDestino] = useState('');
    
    const [rotaDados, setRotaDados] = useState(false);
    const [rotaDadosCarregando, setRotaDadosCarregando] = useState(false);

    const [classeFuncoes, setClasseFuncoes] = useState('');

    const objetoClienteVazio = {
        ID_cliente: '',
        titulo_cliente: '',
        nome_razao_cliente: '',
        documento_cliente: '',
        documento_cliente_mascara: '',
        id_usuario: ''
    }

    const [estadoRecebido, setEstadoRecebido] = useState({});
    const [clienteExibido, setClienteExibido] = useState(objetoClienteVazio);
    const [modalUsuarioAberto, setModalUsuarioAberto] = useState(false);
    const [modalConfirmarExclusaoAberto, setModalConfirmarExclusaoAberto] = useState(false);
    const [clienteIntencaoExcluir, setClienteIntencaoExcluir] = useState('');
    


    const [senhaVisivel, setSenhaVisivel] = useState(false);
    
    const refCampoSenha = useRef(null);
    const refCampoConfirmacaoSenha = useRef(null);

    const [doneDadosPainelControle, setDoneDadosPainelControle] = useState(false);
    const [tabelasDisponiveis, setTabelasDisponiveis] = useState([]);
    const [tabelasDisponiveisEV, setTabelasDisponiveisEV] = useState([]);
    const [tabelasDisponiveisBAT, setTabelasDisponiveisBAT] = useState([]);
    const [tabelasDisponiveisGR, setTabelasDisponiveisGR] = useState([]);
    const [tabelasDisponiveisOM, setTabelasDisponiveisOM] = useState([]);
    const [tabelasDisponiveisNB, setTabelasDisponiveisNB] = useState([]);
    const [tabelasDisponiveisAlternativas, setTabelasDisponiveisAlternativas] = useState([]);
    const [tabelasConfiguracao, setTabelasConfiguracao] = useState([]);
    const [tabelasConfiguracaoMI, setTabelasConfiguracaoMI] = useState([]);
    const [tabelaVigenteChave, setTabelaVigenteChave] = useState();
    const [tabelaVigenteChaveEV, setTabelaVigenteChaveEV] = useState();
    const [tabelaVigenteChaveBAT, setTabelaVigenteChaveBAT] = useState();
    const [tabelaVigenteChaveGR, setTabelaVigenteChaveGR] = useState();
    const [tabelaVigenteChaveOM, setTabelaVigenteChaveOM] = useState();
    const [tabelaVigenteChaveNB, setTabelaVigenteChaveNB] = useState();
    const [tabelaVigenteChaveAlternativa, setTabelaVigenteChaveAlternativa] = useState();
    const [tabelaSelecionada, setTabelaSelecionada] = useState();
    const [tabelaSelecionadaAlternativa, setTabelaSelecionadaAlternativa] = useState();
    const [tabelaSelecionadaEV, setTabelaSelecionadaEV] = useState();
    const [tabelaSelecionadaBAT, setTabelaSelecionadaBAT] = useState();
    const [tabelaSelecionadaGR, setTabelaSelecionadaGR] = useState();
    const [tabelaSelecionadaOM, setTabelaSelecionadaOM] = useState();
    const [tabelaSelecionadaNB, setTabelaSelecionadaNB] = useState();
    const [doneAlterarTabela, setDoneAlterarTabela] = useState(true);
    const [doneAlterarTabelaEV, setDoneAlterarTabelaEV] = useState(true);
    const [doneAlterarTabelaBAT, setDoneAlterarTabelaBAT] = useState(true);
    const [doneAlterarTabelaGR, setDoneAlterarTabelaGR] = useState(true);
    const [doneAlterarTabelaOM, setDoneAlterarTabelaOM] = useState(true);
    const [doneAlterarTabelaNB, setDoneAlterarTabelaNB] = useState(true);
    const [doneAlterarTabelaAlternativa, setDoneAlterarTabelaAlternativa] = useState(true);
    const [usuariosConectados, setUsuariosConectados] = useState(0);
    const [listaTabelas, setListaTabelas] = useState([]);

    const notificarSucessoGenerico = () => {
        toast.success('Operação executada com sucesso!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroMensagem = (mensagem) => {
        toast.error(mensagem, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    // #################### INÍCIO Bloco Alterar Senha ####################

    // #################### FIM Bloco Alterar Senha ####################


    // #################### INÍCIO Bloco Usuários cadastrados ####################
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [usuarioProcurado, setUsuarioProcurado] = useState('');
    const [doneExcluirUsuario, setDoneExcluirUsuario] = useState(true);
    
    async function handleDeleteCustomer(id) {
        setDoneExcluirUsuario(false);
        try {
            await api.delete(`clientes/${id}/1`);

            setListaUsuarios(listaUsuarios.filter(customer => customer.ID_cliente !== id));

        } catch (error) {
            notificarErro();
            console.log('handleDeleteCustomer');
        }
        setDoneExcluirUsuario(true);
    }
    // #################### FIM Bloco Usuários cadastrados ####################



    // #################### INÍCIO Modal Cadastrar Usuário ####################
    const [modalCadastrarUsuarioAberto, setModalCadastrarUsuarioAberto] = useState(false);
    const [doneSalvarNovoUsuario, setDoneSalvarNovoUsuario] = useState(true);

    async function salvarNovoUsuario(objectNovoUsuario) {
        setDoneSalvarNovoUsuario(false);

        try {
            const response = await api.post('painel/cadastrar-usuario', objectNovoUsuario);

            setDoneSalvarNovoUsuario(true);
            setModalCadastrarUsuarioAberto(false);
            notificarSucessoGenerico();

        } catch (error) {
            setDoneSalvarNovoUsuario(true);
            notificarErro();
            console.log('Erro: salvarNovoUsuario');
        }
    }
    // #################### FIM Modal Cadastrar Usuário ####################


    // #################### INÍCIO Modal Informação Comum ####################
    const [doneEditarInformacoesComuns, setDoneEditarInformacoesComuns] = useState(true);
    const [modalFormAberto, setModalFormAberto] = useState(false);
    const [informacaoComum, setInformacaoComum] = useState([]);

    async function editarInformacoesComuns(arrayInformacaoComumFloat) {
        setDoneEditarInformacoesComuns(false);

        try {
            const response = await api.post('painel/editar_informacoes_comuns', arrayInformacaoComumFloat);

            setInformacaoComum(response.data.result_informacao_comum);

            setDoneEditarInformacoesComuns(true);
            setModalFormAberto(false);
            notificarSucessoGenerico();

        } catch (error) {
            setDoneEditarInformacoesComuns(true);
            notificarErro();
            console.log('Erro: editarInformacoesComuns');
        }
    }
    // #################### FIM Modal Informação Comum ####################

    function incluirOutraTabela() {
        history.push({
            pathname: `importar_tabela`,
            tipo:  'generica',
            origin: '/painel_controle'
        })
    }

    async function atualizar_usuarios_conectados(local) {
        try {
            const response = await api.get('sessionsStat');

            setUsuariosConectados(response.data.usuarios_conectados);

            local === 'botao' && notificarSucessoGenerico();
        } catch (error) {
            local === 'botao' && notificarErro();
            console.log('Erro: atualizar_usuarios_conectados');
        }
    }

    async function moverClientesPropostas(e) {
        e.preventDefault();
        setBotaoMoverCarregando(true);

        try {
            await api.post('painel/alterar-usuario', {
                email_usuario_atual: emailUsuarioAtual,
                email_usuario_destino: emailUsuarioDestino,
            });

            notificarSucessoGenerico();
        } catch (error) {
            notificarErro();
            console.log('moverClientesPropostas');
        }

        setBotaoMoverCarregando(false);
    }

    async function alterarSenha(e) {
        e.preventDefault();
        setBotaoAlterarCarregando(true);

        if (novaSenhaUsuario === confirmacaoNovaSenhaUsuario) {
            try {
                await api.post('painel/alterar-senha', {
                    email_usuario_alteracao_senha: emailUsuarioAlteracaoSenha,
                    nova_senha_usuario: novaSenhaUsuario,
                    senha_administrador_conectado: senhaAdministradorConectado
                });

                notificarSucessoGenerico();
            } catch (error) {
                notificarErro();
                console.log('alterarSenha');
            }
        } else {
            notificarErroMensagem('Senhas inseridas não coincidem!');
        }

        setBotaoAlterarCarregando(false);
    }

    async function revogar(e) {
        e.preventDefault();
        setBotaoRevogarCarregando(true);

        try {
            await api.post('painel/revogar-usuario', {
                email_usuario_revogado: emailUsuarioRevogado
            });

            notificarSucessoGenerico();
        } catch (error) {
            notificarErro();
            console.log('revogar');
        }

        setBotaoRevogarCarregando(false);
    }

    async function carregarDadosListaResponsavel() {
        setRotaDadosCarregando(true);

        try {
            const response = await api.get('dados');
            setRotaDados(response.data);

        } catch (error) {
            notificarErro();
            console.log('carregarDadosListaResponsavel');
        }

        setRotaDadosCarregando(false);
    }

    function fecharModalUsuario() {
        setModalUsuarioAberto(false);
        setClienteExibido(objetoClienteVazio);

        if (location.state) {
            history.goBack()
        }
    }

    function normalizar_texto(texto) {
        texto = texto.toString();
        texto = texto.toLowerCase();
        texto = texto.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
        texto = texto.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
        texto = texto.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
        texto = texto.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
        texto = texto.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
        texto = texto.replace(new RegExp('[Ç]','gi'), 'c');
        return texto;
    }

    async function handleNewProposal(id) {
        if (permissaoUsuario.verificar(50, token.tipo_usuario)) {
            history.push({
                pathname: `propostas_completas/simular`,
                data:  id,
                origin: '/clientes'
            })
        } else {
            history.push({
                pathname: `propostas/simular`,
                data:  id,
                origin: '/clientes'
            })
        }
    }

    async function handleIncluirLista(tipo) {
        history.push({
            pathname: `importar_tabela`,
            tipo:  tipo,
            origin: '/painel_controle'
        })
    }

    async function handleVisualizarTabela(dadosRecebidos) {
        history.push({
            pathname: `visualizar_tabela`,
            dadosTabela:  dadosRecebidos,
            origin: '/painel_controle'
        })
    }

    function alternarVisibilidadeSenha(e) {
        e.preventDefault();

        setSenhaVisivel(!senhaVisivel);

        // refCampoSenha.current.focus();
    }

    async function alterarTabelaVigente(chave_tabela_selecionada) {
        setDoneAlterarTabela(false);

        try {
            const result_painel_controle = await api.post('painel/alterar_tabela_vigente', {
                chave_tabela_selecionada: chave_tabela_selecionada
            });

            if (result_painel_controle.data.status = 'Tabela vigente alterada com sucesso') {
                notificarSucessoGenerico();
                setDoneDadosPainelControle(false);
    
                try {    
                    const painel_controle_data = (await api.get('painel')).data;
    
                    setTabelasDisponiveis(painel_controle_data.tabelas_disponiveis);
                    setTabelasDisponiveisEV(painel_controle_data.tabelas_disponiveis_ev);
                    setTabelasDisponiveisBAT(painel_controle_data.tabelas_disponiveis_bat);
                    setTabelasDisponiveisGR(painel_controle_data.tabelas_disponiveis_gr);
                    setTabelasDisponiveisOM(painel_controle_data.tabelas_disponiveis_om);
                    setTabelasDisponiveisNB(painel_controle_data.tabelas_disponiveis_nb);
                    setTabelaSelecionada(painel_controle_data.tabela_vigente.chave);
                    setTabelaVigenteChave(painel_controle_data.tabela_vigente.chave);
                    setTabelaSelecionadaEV(painel_controle_data.tabela_vigente_ev.chave);
                    setTabelaSelecionadaBAT(painel_controle_data.tabela_vigente_bat.chave);
                    setTabelaSelecionadaGR(painel_controle_data.tabela_vigente_gr.chave);
                    setTabelaSelecionadaOM(painel_controle_data.tabela_vigente_om.chave);
                    setTabelaSelecionadaNB(painel_controle_data.tabela_vigente_nb.chave);
                    setTabelaVigenteChaveEV(painel_controle_data.tabela_vigente_ev.chave);
                    setTabelaVigenteChaveBAT(painel_controle_data.tabela_vigente_bat.chave);
                    setTabelaVigenteChaveGR(painel_controle_data.tabela_vigente_gr.chave);
                    setTabelaVigenteChaveOM(painel_controle_data.tabela_vigente_om.chave);
                    setTabelaVigenteChaveNB(painel_controle_data.tabela_vigente_nb.chave);
                    
                    setDoneAlterarTabela(true);
                    setDoneDadosPainelControle(true);
    
                } catch (error) {
                    notificarErro();            
                    console.log('alterarTabelaVigente1');
                    setDoneAlterarTabela(true);
                    setDoneDadosPainelControle(true);
                }

            } else {
                notificarErro();
                console.log('alterarTabelaVigente2');
                setDoneAlterarTabela(true);
                setDoneDadosPainelControle(true);
            }
        } catch (error) {
            notificarErro();                    
            console.log('alterarTabelaVigente3');
            setDoneAlterarTabela(true);
            setDoneDadosPainelControle(true);
        }
    }
    
    async function alterarTabelaVigenteEV(chave_tabela_ev_selecionada) {
        setDoneAlterarTabelaEV(false);

        try {
            const result_painel_controle = await api.post('painel/alterar_tabela_ev_vigente', {
                chave_tabela_ev_selecionada: chave_tabela_ev_selecionada
            });

            if (result_painel_controle.data.status = 'Tabela vigente alterada com sucesso') {
                notificarSucessoGenerico();
                setDoneDadosPainelControle(false);
    
                try {    
                    const painel_controle_data = (await api.get('painel')).data;
    
                    setTabelasDisponiveis(painel_controle_data.tabelas_disponiveis);
                    setTabelasDisponiveisEV(painel_controle_data.tabelas_disponiveis_ev);
                    setTabelaSelecionada(painel_controle_data.tabela_vigente.chave);
                    setTabelaSelecionadaEV(painel_controle_data.tabela_vigente_ev.chave);
                    setTabelaVigenteChave(painel_controle_data.tabela_vigente.chave);
                    setTabelaVigenteChaveEV(painel_controle_data.tabela_vigente_ev.chave);
                    
                    setDoneAlterarTabelaEV(true);
                    setDoneDadosPainelControle(true);
    
                } catch (error) {
                    notificarErro();
                    console.log('alterarTabelaEVVigente1');
                    setDoneAlterarTabelaEV(true);
                    setDoneDadosPainelControle(true);
                }

            } else {
                notificarErro();
                console.log('alterarTabelaEVVigente2');
                setDoneAlterarTabelaEV(true);
                setDoneDadosPainelControle(true);
            }
        } catch (error) {
            notificarErro();                    
            console.log('alterarTabelaEVVigente3');
            setDoneAlterarTabelaEV(true);
            setDoneDadosPainelControle(true);
        }
    }
    
    async function alterarTabelaVigenteBAT(chave_tabela_bat_selecionada) {
        setDoneAlterarTabelaBAT(false);

        try {
            const result_painel_controle = await api.post('painel/alterar_tabela_bat_vigente', {
                chave_tabela_bat_selecionada: chave_tabela_bat_selecionada
            });

            if (result_painel_controle.data.status = 'Tabela vigente alterada com sucesso') {
                notificarSucessoGenerico();
                setDoneDadosPainelControle(false);
    
                try {    
                    const painel_controle_data = (await api.get('painel')).data;
    
                    setTabelasDisponiveis(painel_controle_data.tabelas_disponiveis);
                    setTabelasDisponiveisBAT(painel_controle_data.tabelas_disponiveis_bat);
                    setTabelaSelecionada(painel_controle_data.tabela_vigente.chave);
                    setTabelaSelecionadaBAT(painel_controle_data.tabela_vigente_bat.chave);
                    setTabelaVigenteChave(painel_controle_data.tabela_vigente.chave);
                    setTabelaVigenteChaveBAT(painel_controle_data.tabela_vigente_bat.chave);
                    
                    setDoneAlterarTabelaBAT(true);
                    setDoneDadosPainelControle(true);
    
                } catch (error) {
                    notificarErro();
                    console.log('alterarTabelaBATVigente1');
                    setDoneAlterarTabelaBAT(true);
                    setDoneDadosPainelControle(true);
                }

            } else {
                notificarErro();
                console.log('alterarTabelaBATVigente2');
                setDoneAlterarTabelaBAT(true);
                setDoneDadosPainelControle(true);
            }
        } catch (error) {
            notificarErro();                    
            console.log('alterarTabelaBATVigente3');
            setDoneAlterarTabelaBAT(true);
            setDoneDadosPainelControle(true);
        }
    }
    
    async function alterarTabelaVigenteGR(chave_tabela_gr_selecionada) {
        setDoneAlterarTabelaGR(false);

        try {
            const result_painel_controle = await api.post('painel/alterar_tabela_gr_vigente', {
                chave_tabela_gr_selecionada: chave_tabela_gr_selecionada
            });

            if (result_painel_controle.data.status = 'Tabela vigente alterada com sucesso') {
                notificarSucessoGenerico();
                setDoneDadosPainelControle(false);
    
                try {    
                    const painel_controle_data = (await api.get('painel')).data;
    
                    setTabelasDisponiveis(painel_controle_data.tabelas_disponiveis);
                    setTabelasDisponiveisGR(painel_controle_data.tabelas_disponiveis_gr);
                    setTabelaSelecionada(painel_controle_data.tabela_vigente.chave);
                    setTabelaSelecionadaGR(painel_controle_data.tabela_vigente_gr.chave);
                    setTabelaVigenteChave(painel_controle_data.tabela_vigente.chave);
                    setTabelaVigenteChaveGR(painel_controle_data.tabela_vigente_gr.chave);
                    
                    setDoneAlterarTabelaGR(true);
                    setDoneDadosPainelControle(true);
    
                } catch (error) {
                    notificarErro();
                    console.log('alterarTabelaGRVigente1');
                    setDoneAlterarTabelaGR(true);
                    setDoneDadosPainelControle(true);
                }

            } else {
                notificarErro();
                console.log('alterarTabelaGRVigente2');
                setDoneAlterarTabelaGR(true);
                setDoneDadosPainelControle(true);
            }
        } catch (error) {
            notificarErro();                    
            console.log('alterarTabelaGRVigente3');
            setDoneAlterarTabelaGR(true);
            setDoneDadosPainelControle(true);
        }
    }
    
    async function alterarTabelaVigenteOM(chave_tabela_om_selecionada) {
        setDoneAlterarTabelaOM(false);

        try {
            const result_painel_controle = await api.post('painel/alterar_tabela_om_vigente', {
                chave_tabela_om_selecionada: chave_tabela_om_selecionada
            });

            if (result_painel_controle.data.status = 'Tabela vigente alterada com sucesso') {
                notificarSucessoGenerico();
                setDoneDadosPainelControle(false);
    
                try {    
                    const painel_controle_data = (await api.get('painel')).data;
    
                    setTabelasDisponiveis(painel_controle_data.tabelas_disponiveis);
                    setTabelasDisponiveisOM(painel_controle_data.tabelas_disponiveis_om);
                    setTabelaSelecionada(painel_controle_data.tabela_vigente.chave);
                    setTabelaSelecionadaOM(painel_controle_data.tabela_vigente_om.chave);
                    setTabelaVigenteChave(painel_controle_data.tabela_vigente.chave);
                    setTabelaVigenteChaveOM(painel_controle_data.tabela_vigente_om.chave);
                    
                    setDoneAlterarTabelaOM(true);
                    setDoneDadosPainelControle(true);
    
                } catch (error) {
                    notificarErro();
                    console.log('alterarTabelaOMVigente1');
                    setDoneAlterarTabelaOM(true);
                    setDoneDadosPainelControle(true);
                }

            } else {
                notificarErro();
                console.log('alterarTabelaOMVigente2');
                setDoneAlterarTabelaOM(true);
                setDoneDadosPainelControle(true);
            }
        } catch (error) {
            notificarErro();                    
            console.log('alterarTabelaOMVigente3');
            setDoneAlterarTabelaOM(true);
            setDoneDadosPainelControle(true);
        }
    }
    
    async function alterarTabelaVigenteNB(chave_tabela_nb_selecionada) {
        setDoneAlterarTabelaNB(false);

        try {
            const result_painel_controle = await api.post('painel/alterar_tabela_nb_vigente', {
                chave_tabela_nb_selecionada: chave_tabela_nb_selecionada
            });

            if (result_painel_controle.data.status = 'Tabela vigente alterada com sucesso') {
                notificarSucessoGenerico();
                setDoneDadosPainelControle(false);
    
                try {    
                    const painel_controle_data = (await api.get('painel')).data;
    
                    setTabelasDisponiveis(painel_controle_data.tabelas_disponiveis);
                    setTabelasDisponiveisNB(painel_controle_data.tabelas_disponiveis_nb);
                    setTabelaSelecionada(painel_controle_data.tabela_vigente.chave);
                    setTabelaSelecionadaNB(painel_controle_data.tabela_vigente_nb.chave);
                    setTabelaVigenteChave(painel_controle_data.tabela_vigente.chave);
                    setTabelaVigenteChaveNB(painel_controle_data.tabela_vigente_nb.chave);
                    
                    setDoneAlterarTabelaNB(true);
                    setDoneDadosPainelControle(true);
    
                } catch (error) {
                    notificarErro();
                    console.log('alterarTabelaNBVigente1');
                    setDoneAlterarTabelaNB(true);
                    setDoneDadosPainelControle(true);
                }

            } else {
                notificarErro();
                console.log('alterarTabelaNBVigente2');
                setDoneAlterarTabelaNB(true);
                setDoneDadosPainelControle(true);
            }
        } catch (error) {
            notificarErro();                    
            console.log('alterarTabelaNBVigente3');
            setDoneAlterarTabelaNB(true);
            setDoneDadosPainelControle(true);
        }
    }

    async function alterarTabelaVigenteAlternativa(chave_tabela_selecionada_alternativa) {
        setDoneAlterarTabelaAlternativa(false);

        try {
            const result_painel_controle = await api.post('painel/alterar_tabela_vigente_alternativa', {
                chave_tabela_selecionada_alternativa: chave_tabela_selecionada_alternativa
            });

            if (result_painel_controle.data.status = 'Tabela vigente alternativa alterada com sucesso') {
                notificarSucessoGenerico();
                setDoneDadosPainelControle(false);
    
                try {    
                    const painel_controle_data = (await api.get('painel')).data;

                    setTabelasConfiguracao(painel_controle_data.tabelas_configuracao_disponiveis);
                    setTabelasConfiguracaoMI(painel_controle_data.tabelas_configuracao_MI_disponiveis);
                    setTabelaSelecionadaAlternativa(painel_controle_data.tabela_vigente_alternativa.chave);
                    setTabelasDisponiveisAlternativas(painel_controle_data.tabelas_disponiveis_alternativas);
                    setTabelaVigenteChaveAlternativa(painel_controle_data.tabela_vigente_alternativa.chave);
                    
                    setDoneAlterarTabelaAlternativa(true);
                    setDoneDadosPainelControle(true);
    
                } catch (error) {
                    notificarErro();                    
                    console.log('alterarTabelaVigenteAlternativa1');
                    setDoneAlterarTabelaAlternativa(true);
                    setDoneDadosPainelControle(true);
                }

            } else {
                notificarErro();
                console.log('alterarTabelaVigenteAlternativa2');
                setDoneAlterarTabelaAlternativa(true);
                setDoneDadosPainelControle(true);
            }
        } catch (error) {
            notificarErro();
            console.log('alterarTabelaVigenteAlternativa3');
            setDoneAlterarTabelaAlternativa(true);
            setDoneDadosPainelControle(true);
        }
    }


    
    useEffect(() => {
        document.title = `SGC Brazil Solution`;

        const tokenJSON = JSON.parse(localStorage.getItem('sgc_br'));
        setToken(tokenJSON);
        
        if (location.location.state) {
            setEstadoRecebido(location.location.state);
            setModalUsuarioAberto(true);
            
            // Redundante por já ser tratado no fechamento do modal e na inicialização do state clienteExibido.
            setClienteExibido(objetoClienteVazio);
        }

        atualizar_usuarios_conectados('inicializacao');

        async function carregarDadosPainelControle() {
            setDoneDadosPainelControle(false);

            try {
                // const resposta = await api.get('clientes');
                // setDados(resposta.data);

                const painel_controle_data = (await api.get('painel')).data;

                setTabelasConfiguracao(painel_controle_data.tabelas_configuracao_disponiveis);
                setTabelasConfiguracaoMI(painel_controle_data.tabelas_configuracao_MI_disponiveis);
                setTabelasDisponiveis(painel_controle_data.tabelas_disponiveis);
                setTabelasDisponiveisEV(painel_controle_data.tabelas_disponiveis_ev);
                setTabelasDisponiveisBAT(painel_controle_data.tabelas_disponiveis_bat);
                setTabelasDisponiveisGR(painel_controle_data.tabelas_disponiveis_gr);
                setTabelasDisponiveisOM(painel_controle_data.tabelas_disponiveis_om);
                setTabelasDisponiveisNB(painel_controle_data.tabelas_disponiveis_nb);
                setTabelaSelecionada(painel_controle_data.tabela_vigente.chave);
                setTabelaSelecionadaEV(painel_controle_data.tabela_vigente_ev.chave);
                setTabelaSelecionadaBAT(painel_controle_data.tabela_vigente_bat.chave);
                setTabelaSelecionadaGR(painel_controle_data.tabela_vigente_gr.chave);
                setTabelaSelecionadaOM(painel_controle_data.tabela_vigente_om.chave);
                setTabelaSelecionadaNB(painel_controle_data.tabela_vigente_nb.chave);
                setTabelaVigenteChave(painel_controle_data.tabela_vigente.chave);
                setTabelaVigenteChaveEV(painel_controle_data.tabela_vigente_ev.chave);
                setTabelaVigenteChaveBAT(painel_controle_data.tabela_vigente_bat.chave);
                setTabelaVigenteChaveGR(painel_controle_data.tabela_vigente_gr.chave);
                setTabelaVigenteChaveOM(painel_controle_data.tabela_vigente_om.chave);
                setTabelaVigenteChaveNB(painel_controle_data.tabela_vigente_nb.chave);

                setTabelasDisponiveisAlternativas(painel_controle_data.tabelas_disponiveis_alternativas);
                setTabelaSelecionadaAlternativa(painel_controle_data.tabela_vigente_alternativa.chave);
                setTabelaVigenteChaveAlternativa(painel_controle_data.tabela_vigente_alternativa.chave);
                setListaTabelas(painel_controle_data.result_lista_tabelas_formatado);
                setInformacaoComum(painel_controle_data.result_informacao_comum);
                
                setDoneDadosPainelControle(true);

            } catch (error) {
                notificarErro();
                console.log('carregarDadosPainelControle');
            }

        }

        async function solicitarClientes() {
            setDone(false);
            try {
                const response = await api.get('painel/usuario');

                setListaUsuarios(response.data);
                
            } catch (error) {
                notificarErro();
                console.log('solicitarClientes');
            }

            setDone(true);
        }

        // if (permissaoUsuario.verificar(50, tokenJSON.tipo_usuario)) {
        //     setClasseFuncoes('type5-functions');
        //     carregarDadosListaResponsavel();
        // } else {
        //     setClasseFuncoes('type5-functions');
        // }

        carregarDadosListaResponsavel();
        carregarDadosPainelControle();
        solicitarClientes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <div className="container">
                <Header />
                <ToastContainer />
                { (modalFormAberto && doneDadosPainelControle) &&
                    <ModalForm
                        informacaoComum={informacaoComum}
                        setInformacaoComum={setInformacaoComum}
                        editarInformacoesComuns={editarInformacoesComuns}
                        doneEditarInformacoesComuns={doneEditarInformacoesComuns}
                        fecharModal={() => setModalFormAberto(false)}
                /> }
                { (modalCadastrarUsuarioAberto && done) &&
                    <ModalCadastrarUsuario
                        salvarNovoUsuario={salvarNovoUsuario}
                        doneSalvarNovoUsuario={doneSalvarNovoUsuario}
                        setDoneSalvarNovoUsuario={setDoneSalvarNovoUsuario}
                        fecharModal={() => setModalCadastrarUsuarioAberto(false)}
                /> }

                <div className="body-container-2-margem-inferior-menor">
                    <div className="type1-element">
                        <div className="type1-top-line">
                            <div className="type1-title">
                                PAINEL DE CONTROLE
                            </div>
                        </div>
                    </div>

                    <div className="wrapper-blocos-painel-controle">
                        
                        {permissaoUsuario.verificar(50, token.tipo_usuario) && (
                            <>
                                <div className="bloco1-painel-controle">
                                    <div className="bloco1-painel-controle-cabecalho">
                                        <h3> Revogar acesso </h3>
                                    </div>
                                    <form onSubmit={e => { revogar(e); }}>
                                    <br />
                                        <div className="input-group-painel-controle">
                                            <label htmlFor="email_usuario_revogado" className="label-client-text">
                                                E-mail usuário revogado
                                            </label>
                                            <input
                                                className="painel-controle-input"
                                                type="text"
                                                name="email_usuario_revogado"
                                                id="email_usuario_revogado"
                                                onChange={e => setEmailUsuarioRevogado(e.target.value)}
                                            />
                                        </div>

                                        {botaoRevogarCarregando ? (
                                            <button className="white-background" >
                                                <DynamicLoading />
                                            </button>
                                        )  :  (
                                            <div className="painel-controle-wrapper-botoes">
                                                <button className="button-submit-azul" type="submit"> Revogar </button>
                                            </div>
                                        )}
                                    </form>

                                    <br />
                                    <br />

                                    <div className="bloco1-painel-controle-cabecalho">
                                        <h3> Usuários conectados: </h3> <p> {usuariosConectados} </p>
                                        
                                        <button className="alterna_visibilidade_painel_controle" type="button" onClick={() => atualizar_usuarios_conectados('botao')}>
                                            <FiRotateCw size={12}/>
                                        </button>
                                    </div>

                                    <p>(Incluindo você)</p>

                                    <br />
                                    <br />
                                </div>

                                <div className="bloco1-painel-controle">
                                    <div className="bloco1-painel-controle-cabecalho">
                                        <h3> Alterar Senha </h3>
                                    </div>
                                    <form onSubmit={e => { alterarSenha(e); }}>
                                        <br />
                                        <h4 className="h4-painel-controle">Usuário</h4>
                                        <div className="input-group-painel-controle">
                                            <label htmlFor="email_usuario_alteracao_senha" className="label-client-text">
                                                E-mail
                                            </label>
                                            <input
                                                className="painel-controle-input"
                                                type="text"
                                                name="email_usuario_alteracao_senha"
                                                id="email_usuario_alteracao_senha"
                                                onChange={e => setEmailUsuarioAlteracaoSenha(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group-painel-controle">
                                            <label htmlFor="nova_senha_usuario" className="label-client-text">
                                                Nova senha
                                            </label>
                                            <div className="input_senha">
                                                <input
                                                    className="painel-controle-input"
                                                    type={senhaVisivel ? "text" : "password"}
                                                    name="nova_senha_usuario"
                                                    id="nova_senha_usuario"
                                                    value={novaSenhaUsuario}
                                                    onChange={e => setNovaSenhaUsuario(e.target.value)}
                                                    ref={refCampoSenha}
                                                />
                                                <button className="alterna_visibilidade_painel_controle" type="button" onClick={e => alternarVisibilidadeSenha(e)}>
                                                    {senhaVisivel ? (<FiEye size={12}/>) : (<FiEyeOff size={12}/>)}
                                                </button>
                                            </div>
                                        </div>
                                        

                                        <div className="input-group-painel-controle">
                                            <label htmlFor="confirmacao_nova_senha_usuario" className="label-client-text">
                                                Confirmar nova senha
                                            </label>
                                            <div className="input_senha">
                                                <input
                                                    className="painel-controle-input"
                                                    type={senhaVisivel ? "text" : "password"}
                                                    name="confirmacao_nova_senha_usuario"
                                                    id="confirmacao_nova_senha_usuario"
                                                    value={confirmacaoNovaSenhaUsuario}
                                                    onChange={e => setConfirmacaoNovaSenhaUsuario(e.target.value)}
                                                    ref={refCampoConfirmacaoSenha}
                                                />
                                                <button className="alterna_visibilidade_painel_controle" type="button" onClick={e => alternarVisibilidadeSenha(e)}>
                                                    {senhaVisivel ? (<FiEye size={12}/>) : (<FiEyeOff size={12}/>)}
                                                </button>
                                            </div>
                                        </div>

                                        <br />

                                        <h4 className="h4-painel-controle">Administrador conectado</h4>
                                        <div className="input-group-painel-controle">
                                            <label htmlFor="senha_administrador_conectado" className="label-client-text">
                                                Senha
                                            </label>
                                            <input
                                                className="painel-controle-input"
                                                type="password"
                                                name="senha_administrador_conectado"
                                                id="senha_administrador_conectado"
                                                onChange={e => setSenhaAdministradorConectado(e.target.value)}
                                            />
                                        </div>

                                        {botaoAlterarCarregando ? (
                                            <button className="white-background" >
                                                <DynamicLoading />
                                            </button>
                                        )  :  (
                                            <div className="painel-controle-wrapper-botoes">
                                                <button className="button-submit-azul" type="submit"> Alterar senha </button>
                                            </div>
                                        )}

                                    </form>
                                </div>
                            
                                <div className="bloco1-painel-controle">
                                    <div className="bloco1-painel-controle-cabecalho">
                                        <h3> Mover clientes e propostas </h3>
                                    </div>
                                    <form onSubmit={e => { moverClientesPropostas(e); }}>
                                        <br />
                                        <div className="input-group-painel-controle">
                                            <label htmlFor="email_usuario_atual" className="label-client-text">
                                            E-mail usuário atual
                                            </label>
                                            <input
                                                className="painel-controle-input"
                                                type="text"
                                                name="email_usuario_atual"
                                                id="email_usuario_atual"
                                                onChange={e => setEmailUsuarioAtual(e.target.value)}
                                            />
                                        </div>
                                        <br />
                                        <div className="input-group-painel-controle">
                                            <label htmlFor="email_usuario_destino" className="label-client-text">
                                            E-mail usuário destino
                                            </label>
                                            <input
                                                className="painel-controle-input"
                                                type="text"
                                                name="email_usuario_destino"
                                                id="email_usuario_destino"
                                                onChange={e => setEmailUsuarioDestino(e.target.value)}
                                            />
                                        </div>
                                        {botaoMoverCarregando ? (
                                            <button className="white-background" >
                                                <DynamicLoading />
                                            </button>
                                        )  :  (
                                            <div className="painel-controle-wrapper-botoes">
                                                <button className="button-submit-azul" type="submit"> Mover </button>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </>
                        )}

                        {permissaoUsuario.verificar(47, token.tipo_usuario) && (
                            <>
                                <div className="bloco1-painel-controle">
                                    <div className="bloco1-painel-controle-cabecalho">
                                        <h3> Dados da empresa </h3>
                                    </div>
                                    <br />
                                    <div>
                                        <div className="grupo-dados-empresa">
                                            <label className="label-client-text">
                                                Razão Social:
                                            </label>
                                            <p>
                                                BRAZIL SOLUTION SERVICOS LTDA
                                            </p>
                                        </div>
                                        <div className="divisao-dados-empresa" ></div>
                                        <div className="grupo-dados-empresa-row">
                                            <label className="label-client-text">
                                                Nome Fantasia:
                                            </label>
                                            <p>
                                                Brazil Solution
                                            </p>
                                        </div>
                                        <div className="divisao-dados-empresa" ></div>
                                        <div className="grupo-dados-empresa-row">
                                            <label className="label-client-text">
                                                CPNJ:
                                            </label>
                                            <p>
                                                19.853.758/0001-09
                                            </p>
                                        </div>
                                        <div className="divisao-dados-empresa" ></div>
                                        <div className="grupo-dados-empresa-row">
                                            <label className="label-client-text">
                                                Inscrição Estadual:
                                            </label>
                                            <p>
                                                86.647.680
                                            </p>
                                        </div>
                                        <div className="divisao-dados-empresa" ></div>
                                        <div className="grupo-dados-empresa-row">
                                            <label className="label-client-text">
                                                Inscrição Municipal:
                                            </label>
                                            <p>
                                                046330
                                            </p>
                                        </div>
                                        <div className="divisao-dados-empresa" ></div>
                                        <div className="grupo-dados-empresa-row">
                                            <label className="label-client-text">
                                                Telefone:
                                            </label>
                                            <p>
                                                (21) 3876-3597
                                            </p>
                                        </div>
                                        <div className="divisao-dados-empresa" ></div>
                                        <div className="grupo-dados-empresa-row">
                                            <label className="label-client-text">
                                                Data de fundação:
                                            </label>
                                            <p>
                                                11/03/2014
                                            </p>
                                        </div>
                                        <div className="divisao-dados-empresa" ></div>
                                        <div className="grupo-dados-empresa-row">
                                            <label className="label-client-text">
                                                Banco:
                                            </label>
                                            <p>
                                                Itaú
                                            </p>
                                        </div>
                                        <div className="grupo-dados-empresa-row">
                                            <label className="label-client-text">
                                                Agência:
                                            </label>
                                            <p>
                                                8427
                                            </p>
                                            <div style={{width: '10%'}}></div>
                                            <label className="label-client-text">
                                                CC:
                                            </label>
                                            <p>
                                                29455-1
                                            </p>
                                        </div>
                                        <div className="grupo-dados-empresa-row">
                                            <label className="label-client-text">
                                                Chave pix:
                                            </label>
                                            <p>
                                                19.853.758/0001-09
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="bloco1-painel-controle">
                                    <div className="bloco1-painel-controle-cabecalho">
                                        <h3> Informação comum </h3>
                                    </div>
                                    <br />
                                    {doneDadosPainelControle ? (
                                        <div>
                                            {informacaoComum.length > 0 && informacaoComum.map((item, indice) => { return (
                                                <>
                                                    <div className="grupo-dados-empresa">
                                                        <p>
                                                            <label className="label-client-text"> {item.unidade}: </label>
                                                            {item.unidade === "economia" ? (item.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })) : (item.valor.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2}))}
                                                        </p>
                                                    </div>
                                                    {indice + 1 !== informacaoComum.length && <div className="divisao-dados-empresa" ></div>}
                                                </>
                                            )})}

                                            <div className="painel-controle-wrapper-botoes">
                                                <button className="button-submit-azul" onClick={() => setModalFormAberto(true)}> Editar </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ height: '304px'}}>
                                            <DynamicLoading />
                                        </div>
                                    )}
                                    
                                </div>
                            </>
                        )}

                        {(permissaoUsuario.verificar(47, token.tipo_usuario) && !permissaoUsuario.verificar(50, token.tipo_usuario)) && (
                            <>
                                <div className="bloco1-painel-controle">
                                </div>
                                <div className="bloco1-painel-controle">
                                </div>
                                <div className="bloco1-painel-controle">
                                </div>
                            </>
                        )}
                        
                        <div className="bloco2-painel-controle">
                            {permissaoUsuario.verificar(47, token.tipo_usuario) ? (
                                <>
                                    <div className="bloco2-painel-controle-cabecalho">
                                        <h3> Listas de preço Energia Solar </h3>

                                        <button style={{marginRight: "-30px"}} className="type1-main-button" onClick={() =>
                                                history.push({ pathname: `comparar_tabela`, tabelasDisponiveis: tabelasDisponiveis, origin: '/painel_controle' })
                                            }>
                                            <FiCopy size={18} />
                                            <span>Comparar listas</span>
                                        </button>

                                        <button className="type1-main-button" onClick={() => handleIncluirLista('lista')} > 
                                            <FiPlus size={18} />
                                            <span>Incluir lista de preço</span>
                                        </button>
                                    </div>
                                    <div className="input-group-painel-controle">
                                        
                                        {doneDadosPainelControle ? (
                                            <>
                                                <label htmlFor="tabela_vigente" className="label-client-text">Lista vigente</label>

                                                <div className="wrapper-alterar-lista-vigente">
                                                    <select
                                                        name="tabela_vigente"
                                                        onChange={e => { setTabelaSelecionada(e.target.value); }}
                                                        value={tabelaSelecionada}
                                                        className="painel-controle-select"
                                                    >
                                                        {tabelasDisponiveis.length > 0 && tabelasDisponiveis.map(tabela => (
                                                                <option key={tabela.id} value={tabela.chave}>{tabela.valor.valor}</option>
                                                        ))}
                                                    </select>

                                                    {!doneAlterarTabela ? (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-branco" disabled>
                                                                <DynamicLoading/>
                                                            </button>
                                                        </div>
                                                    ) : (tabelaVigenteChave !== tabelaSelecionada) ? (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-azul-ajustavel" onClick={() => { alterarTabelaVigente(tabelaSelecionada); }}> Alterar lista vigente </button>
                                                        </div>
                                                    ) : (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-cinza-ajustavel" disabled> Alterar lista vigente </button>
                                                        </div>
                                                    )}
                                                </div>

                                                <br />
                                                <label htmlFor="lista-tabelas-disponiveis" className="label-client-text">Listas disponíveis</label>

                                                <table className="table-pre-visualizacao">
                                                    <thead>
                                                        <tr className="texto-center-children">
                                                            <th className="texto-center tabela-sem-quebra-linha">Lista</th>
                                                            <th className="texto-center tabela-sem-quebra-linha">Tabela Servidor</th>
                                                            <th className="texto-center">Número de itens</th>
                                                            <th className="texto-center tabela-sem-quebra-linha">Módulo preferencial</th>
                                                            <th className="texto-center">Tabela configuração</th>
                                                            <th className="texto-center">Tabela configuração MI</th>
                                                            <th className="texto-center tabela-sem-quebra-linha">Tabela vigente</th>
                                                            <th className="texto-center">Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tabelasDisponiveis.length > 0 && tabelasDisponiveis.map(tabela => {
                                                            return(
                                                                <tr key={tabela.id}>
                                                                    <td>{tabela.valor.valor}</td>
                                                                    <td>{tabela.chave}</td>
                                                                    <td>{tabela.numero_de_itens}</td>
                                                                    <td>{tabela.valor.modulo_preferencial.modelo}</td>
                                                                    <td>{tabela.valor.configuracao}</td>
                                                                    <td>{tabela.valor.configuracao_MI}</td>
                                                                    <td>{tabela.descricao === "Tabela vigente" && (<>Sim</>)}</td>
                                                                    <td>
                                                                        <div className={classeFuncoes}>
                                                                            <button onClick={() => {handleVisualizarTabela({nome_exibicao: tabela.valor.valor, nome_mysql: tabela.chave, tabela: tabela});}} className="edit1">
                                                                                <FiInfo size={18} />
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : (
                                            <DynamicLoading />
                                        )}
                                    </div>
                                </>
                            ) : (<></>)}
                        </div>

                        
                        <div className="bloco2-painel-controle">
                            {permissaoUsuario.verificar(47, token.tipo_usuario) ? (
                                <>
                                    <div className="bloco4-painel-controle-cabecalho">
                                        <h3> Tabelas de configuração </h3>

                                        <button className="type1-main-button" onClick={() => handleIncluirLista('configuracao')}> 
                                            <FiPlus size={18} />
                                            <span>Incluir tabela de configuração</span>
                                        </button>
                                    </div>
                                    <div className="input-group-painel-controle-altura1">
                                        
                                        {doneDadosPainelControle ? (
                                            <>
                                                {/* <label htmlFor="lista-tabelas-disponiveis" className="label-client-text">Tabelas de configuração disponíveis</label> */}

                                                <table className="table-pre-visualizacao">
                                                    <thead>
                                                        <tr className="texto-center-children">
                                                            <th className="texto-center">Tabela de configuração</th>
                                                            <th className="texto-center">Número de configurações</th>
                                                            <th className="texto-center">Inversores usados</th>
                                                            <th className="texto-center">Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tabelasConfiguracao.length > 0 && tabelasConfiguracao.map(tabela => {
                                                            return(
                                                                <>
                                                                    <tr key={tabela.tabela_configuracao}>
                                                                        <td>{tabela.tabela_configuracao}</td>
                                                                        <td>{tabela.numero_de_configuracoes}</td>
                                                                        <td>
                                                                            <div className="subtabela-painel-controle">
                                                                                {tabela.inversores_usados.map((x, indice) => {
                                                                                    return <div key={indice} className="tabela-sem-quebra-linha">{x}</div>
                                                                                })}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className={classeFuncoes}>
                                                                                <button onClick={() => {handleVisualizarTabela({nome_exibicao: tabela.tabela_configuracao, nome_mysql: tabela.tabela_configuracao, tabela: tabela});}} className="edit1">
                                                                                    <FiInfo size={18} />
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                    {/* Espaço (exceto na última linha)
                                                                    {(indice + 1 !== tabelasConfiguracao.length) && <td colSpan={linhas_tabela[0].length + 2}></td>} */}
                                                                </>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : (
                                            <div style={{ height: '304px'}}>
                                                <DynamicLoading />
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : (<></>)}
                        </div>

                        <div className="bloco2-painel-controle">
                            {permissaoUsuario.verificar(47, token.tipo_usuario) ? (
                                <>
                                    <div className="bloco4-painel-controle-cabecalho">
                                        <h3> Tabelas de configuração Microinversor </h3>

                                        <button className="type1-main-button" onClick={() => handleIncluirLista('configuracao-MI')}> 
                                            <FiPlus size={18} />
                                            <span>Incluir tabela de configuração MI</span>
                                        </button>
                                    </div>
                                    <div className="input-group-painel-controle-altura1">
                                        
                                        {doneDadosPainelControle ? (
                                            <>
                                                {/* <label htmlFor="lista-tabelas-disponiveis" className="label-client-text">Tabelas de configuração disponíveis</label> */}

                                                <table className="table-pre-visualizacao">
                                                    <thead>
                                                        <tr className="texto-center-children">
                                                            <th className="texto-center">Tabela de configuração MI</th>
                                                            <th className="texto-center">Número de configurações</th>
                                                            <th className="texto-center">Inversores usados</th>
                                                            <th className="texto-center">Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tabelasConfiguracaoMI.length > 0 && tabelasConfiguracaoMI.map(tabela => {
                                                            return(
                                                                <>
                                                                    <tr key={tabela.tabela_configuracao_MI}>
                                                                        <td>{tabela.tabela_configuracao_MI}</td>
                                                                        <td>{tabela.numero_de_configuracoes}</td>
                                                                        <td>
                                                                            <div className="subtabela-painel-controle">
                                                                                {tabela.inversores_usados.map((x, indice) => {
                                                                                    return <div key={indice} className="tabela-sem-quebra-linha">{x}</div>
                                                                                })}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className={classeFuncoes}>
                                                                                <button onClick={() => {handleVisualizarTabela({nome_exibicao: tabela.tabela_configuracao_MI, nome_mysql: tabela.tabela_configuracao_MI, tabela: tabela});}} className="edit1">
                                                                                    <FiInfo size={18} />
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : (
                                            <div style={{ height: '304px'}}>
                                                <DynamicLoading />
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : (<></>)}
                        </div>
                        
                        <div className="bloco2-painel-controle">
                            {permissaoUsuario.verificar(47, token.tipo_usuario) ? (
                                <>
                                    <div className="bloco2-painel-controle-cabecalho">
                                        <h3> Listas de preço Estação Veicular </h3>

                                        <button className="type1-main-button" onClick={() => handleIncluirLista('lista_EV')}> 
                                            <FiPlus size={18} />
                                            <span>Incluir lista Estação Veicular</span>
                                        </button>
                                    </div>
                                    <div className="input-group-painel-controle">
                                        
                                        {doneDadosPainelControle ? (
                                            <>
                                                <label htmlFor="tabela_vigente" className="label-client-text">Lista vigente Estação Veicular</label>

                                                <div className="wrapper-alterar-lista-vigente">
                                                    <select
                                                        name="tabela_vigente"
                                                        onChange={e => { setTabelaSelecionadaEV(e.target.value);}}
                                                        value={tabelaSelecionadaEV}
                                                        className="painel-controle-select"
                                                    >
                                                        {tabelasDisponiveisEV.length > 0 && tabelasDisponiveisEV.map(tabela => (
                                                                <option key={tabela.id} value={tabela.chave}>{tabela.valor.valor}</option>
                                                        ))}
                                                    </select>

                                                    {!doneAlterarTabelaEV ? (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-branco" disabled>
                                                                <DynamicLoading/>
                                                            </button>
                                                        </div>
                                                    ) : (tabelaVigenteChaveEV !== tabelaSelecionadaEV) ? (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-azul-ajustavel" onClick={() => { alterarTabelaVigenteEV(tabelaSelecionadaEV); }}> Alterar lista vigente </button>
                                                        </div>
                                                    ) : (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-cinza-ajustavel" disabled> Alterar lista vigente </button>
                                                        </div>
                                                    )}
                                                </div>

                                                <br />
                                                <label htmlFor="lista-tabelas-disponiveis" className="label-client-text">Listas Estação Veicular disponíveis</label>

                                                <table className="table-pre-visualizacao">
                                                    <thead>
                                                        <tr className="texto-center-children">
                                                            <th className="texto-center tabela-sem-quebra-linha">Lista</th>
                                                            <th className="texto-center tabela-sem-quebra-linha">Tabela Servidor</th>
                                                            <th className="texto-center tabela-sem-quebra-linha">Tabela vigente</th>
                                                            <th className="texto-center">Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tabelasDisponiveisEV.length > 0 && tabelasDisponiveisEV.map(tabela => {
                                                            return(
                                                                <tr key={tabela.id}>
                                                                    <td>{tabela.valor.valor}</td>
                                                                    <td>{tabela.chave}</td>
                                                                    <td>{tabela.descricao === "Tabela vigente EV" && (<>Sim</>)}</td>
                                                                    <td>
                                                                        <div className={classeFuncoes}>
                                                                            <button onClick={() => {handleVisualizarTabela({nome_exibicao: tabela.valor.valor, nome_mysql: tabela.chave, tabela: tabela});}} className="edit1">
                                                                                <FiInfo size={18} />
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : (
                                            <DynamicLoading />
                                        )}
                                    </div>
                                </>
                            ) : (<></>)}
                        </div>
                        
                        <div className="bloco2-painel-controle">
                            {permissaoUsuario.verificar(47, token.tipo_usuario) ? (
                                <>
                                    <div className="bloco2-painel-controle-cabecalho">
                                        <h3> Listas de preço Bateria </h3>

                                        <button className="type1-main-button" onClick={() => handleIncluirLista('lista_BAT')}> 
                                            <FiPlus size={18} />
                                            <span>Incluir lista Bateria</span>
                                        </button>
                                    </div>
                                    <div className="input-group-painel-controle">
                                        
                                        {doneDadosPainelControle ? (
                                            <>
                                                <label htmlFor="tabela_vigente" className="label-client-text">Lista vigente Bateria</label>

                                                <div className="wrapper-alterar-lista-vigente">
                                                    <select
                                                        name="tabela_vigente"
                                                        onChange={e => { setTabelaSelecionadaBAT(e.target.value);}}
                                                        value={tabelaSelecionadaBAT}
                                                        className="painel-controle-select"
                                                    >
                                                        {tabelasDisponiveisBAT.length > 0 && tabelasDisponiveisBAT.map(tabela => (
                                                                <option key={tabela.id} value={tabela.chave}>{tabela.valor.valor}</option>
                                                        ))}
                                                    </select>

                                                    {!doneAlterarTabelaBAT ? (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-branco" disabled>
                                                                <DynamicLoading/>
                                                            </button>
                                                        </div>
                                                    ) : (tabelaVigenteChaveBAT !== tabelaSelecionadaBAT) ? (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-azul-ajustavel" onClick={() => { alterarTabelaVigenteBAT(tabelaSelecionadaBAT); }}> Alterar lista vigente </button>
                                                        </div>
                                                    ) : (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-cinza-ajustavel" disabled> Alterar lista vigente </button>
                                                        </div>
                                                    )}
                                                </div>

                                                <br />
                                                <label htmlFor="lista-tabelas-disponiveis" className="label-client-text">Listas Bateria disponíveis</label>

                                                <table className="table-pre-visualizacao">
                                                    <thead>
                                                        <tr className="texto-center-children">
                                                            <th className="texto-center tabela-sem-quebra-linha">Lista</th>
                                                            <th className="texto-center tabela-sem-quebra-linha">Tabela Servidor</th>
                                                            <th className="texto-center tabela-sem-quebra-linha">Tabela vigente</th>
                                                            <th className="texto-center">Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tabelasDisponiveisBAT.length > 0 && tabelasDisponiveisBAT.map(tabela => {
                                                            return(
                                                                <tr key={tabela.id}>
                                                                    <td>{tabela.valor.valor}</td>
                                                                    <td>{tabela.chave}</td>
                                                                    <td>{tabela.descricao === "Tabela vigente BAT" && (<>Sim</>)}</td>
                                                                    <td>
                                                                        <div className={classeFuncoes}>
                                                                            <button onClick={() => {handleVisualizarTabela({nome_exibicao: tabela.valor.valor, nome_mysql: tabela.chave, tabela: tabela});}} className="edit1">
                                                                                <FiInfo size={18} />
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : (
                                            <DynamicLoading />
                                        )}
                                    </div>
                                </>
                            ) : (<></>)}
                        </div>
                        
                        <div className="bloco2-painel-controle">
                            {permissaoUsuario.verificar(47, token.tipo_usuario) ? (
                                <>
                                    <div className="bloco2-painel-controle-cabecalho">
                                        <h3> Listas de preço Gerador </h3>

                                        <button className="type1-main-button" onClick={() => handleIncluirLista('lista_GR')}> 
                                            <FiPlus size={18} />
                                            <span>Incluir lista Gerador</span>
                                        </button>
                                    </div>
                                    <div className="input-group-painel-controle">
                                        
                                        {doneDadosPainelControle ? (
                                            <>
                                                <label htmlFor="tabela_vigente" className="label-client-text">Lista vigente Gerador</label>

                                                <div className="wrapper-alterar-lista-vigente">
                                                    <select
                                                        name="tabela_vigente"
                                                        onChange={e => { setTabelaSelecionadaGR(e.target.value);}}
                                                        value={tabelaSelecionadaGR}
                                                        className="painel-controle-select"
                                                    >
                                                        {tabelasDisponiveisGR.length > 0 && tabelasDisponiveisGR.map(tabela => (
                                                                <option key={tabela.id} value={tabela.chave}>{tabela.valor.valor}</option>
                                                        ))}
                                                    </select>

                                                    {!doneAlterarTabelaGR ? (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-branco" disabled>
                                                                <DynamicLoading/>
                                                            </button>
                                                        </div>
                                                    ) : (tabelaVigenteChaveGR !== tabelaSelecionadaGR) ? (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-azul-ajustavel" onClick={() => { alterarTabelaVigenteGR(tabelaSelecionadaGR); }}> Alterar lista vigente </button>
                                                        </div>
                                                    ) : (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-cinza-ajustavel" disabled> Alterar lista vigente </button>
                                                        </div>
                                                    )}
                                                </div>

                                                <br />
                                                <label htmlFor="lista-tabelas-disponiveis" className="label-client-text">Listas Gerador disponíveis</label>

                                                <table className="table-pre-visualizacao">
                                                    <thead>
                                                        <tr className="texto-center-children">
                                                            <th className="texto-center tabela-sem-quebra-linha">Lista</th>
                                                            <th className="texto-center tabela-sem-quebra-linha">Tabela Servidor</th>
                                                            <th className="texto-center tabela-sem-quebra-linha">Tabela vigente</th>
                                                            <th className="texto-center">Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tabelasDisponiveisGR.length > 0 && tabelasDisponiveisGR.map(tabela => {
                                                            return(
                                                                <tr key={tabela.id}>
                                                                    <td>{tabela.valor.valor}</td>
                                                                    <td>{tabela.chave}</td>
                                                                    <td>{tabela.descricao === "Tabela vigente GR" && (<>Sim</>)}</td>
                                                                    <td>
                                                                        <div className={classeFuncoes}>
                                                                            <button onClick={() => {handleVisualizarTabela({nome_exibicao: tabela.valor.valor, nome_mysql: tabela.chave, tabela: tabela});}} className="edit1">
                                                                                <FiInfo size={18} />
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : (
                                            <DynamicLoading />
                                        )}
                                    </div>
                                </>
                            ) : (<></>)}
                        </div>

                        
                        <div className="bloco2-painel-controle">
                            {permissaoUsuario.verificar(47, token.tipo_usuario) ? (
                                <>
                                    <div className="bloco2-painel-controle-cabecalho">
                                        <h3> Listas de preço O&M </h3>

                                        <button className="type1-main-button" onClick={() => handleIncluirLista('lista_OM')}> 
                                            <FiPlus size={18} />
                                            <span>Incluir lista O&M</span>
                                        </button>
                                    </div>
                                    <div className="input-group-painel-controle">
                                        
                                        {doneDadosPainelControle ? (
                                            <>
                                                <label htmlFor="tabela_vigente" className="label-client-text">Lista vigente O&M</label>

                                                <div className="wrapper-alterar-lista-vigente">
                                                    <select
                                                        name="tabela_vigente"
                                                        onChange={e => { setTabelaSelecionadaOM(e.target.value);}}
                                                        value={tabelaSelecionadaOM}
                                                        className="painel-controle-select"
                                                    >
                                                        {tabelasDisponiveisOM.length > 0 && tabelasDisponiveisOM.map(tabela => (
                                                                <option key={tabela.id} value={tabela.chave}>{tabela.valor.valor}</option>
                                                        ))}
                                                    </select>

                                                    {!doneAlterarTabelaOM ? (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-branco" disabled>
                                                                <DynamicLoading/>
                                                            </button>
                                                        </div>
                                                    ) : (tabelaVigenteChaveOM !== tabelaSelecionadaOM) ? (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-azul-ajustavel" onClick={() => { alterarTabelaVigenteOM(tabelaSelecionadaOM); }}> Alterar lista vigente </button>
                                                        </div>
                                                    ) : (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-cinza-ajustavel" disabled> Alterar lista vigente </button>
                                                        </div>
                                                    )}
                                                </div>

                                                <br />
                                                <label htmlFor="lista-tabelas-disponiveis" className="label-client-text">Listas O&M disponíveis</label>

                                                <table className="table-pre-visualizacao">
                                                    <thead>
                                                        <tr className="texto-center-children">
                                                            <th className="texto-center tabela-sem-quebra-linha">Lista</th>
                                                            <th className="texto-center tabela-sem-quebra-linha">Tabela Servidor</th>
                                                            <th className="texto-center tabela-sem-quebra-linha">Tabela vigente</th>
                                                            <th className="texto-center">Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tabelasDisponiveisOM.length > 0 && tabelasDisponiveisOM.map(tabela => {
                                                            return(
                                                                <tr key={tabela.id}>
                                                                    <td>{tabela.valor.valor}</td>
                                                                    <td>{tabela.chave}</td>
                                                                    <td>{tabela.descricao === "Tabela vigente OM" && (<>Sim</>)}</td>
                                                                    <td>
                                                                        <div className={classeFuncoes}>
                                                                            <button onClick={() => {handleVisualizarTabela({nome_exibicao: tabela.valor.valor, nome_mysql: tabela.chave, tabela: tabela});}} className="edit1">
                                                                                <FiInfo size={18} />
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : (
                                            <DynamicLoading />
                                        )}
                                    </div>
                                </>
                            ) : (<></>)}
                        </div>

                        <div className="bloco2-painel-controle">
                            {permissaoUsuario.verificar(47, token.tipo_usuario) ? (
                                <>
                                    <div className="bloco2-painel-controle-cabecalho">
                                        <h3> Listas de preço Nobreak </h3>

                                        <button className="type1-main-button" onClick={() => handleIncluirLista('lista_NB')}> 
                                            <FiPlus size={18} />
                                            <span>Incluir lista Nobreak</span>
                                        </button>
                                    </div>
                                    <div className="input-group-painel-controle">
                                        
                                        {doneDadosPainelControle ? (
                                            <>
                                                <label htmlFor="tabela_vigente" className="label-client-text">Lista vigente Nobreak</label>

                                                <div className="wrapper-alterar-lista-vigente">
                                                    <select
                                                        name="tabela_vigente"
                                                        onChange={e => { setTabelaSelecionadaNB(e.target.value);}}
                                                        value={tabelaSelecionadaNB}
                                                        className="painel-controle-select"
                                                    >
                                                        {tabelasDisponiveisNB.length > 0 && tabelasDisponiveisNB.map(tabela => (
                                                                <option key={tabela.id} value={tabela.chave}>{tabela.valor.valor}</option>
                                                        ))}
                                                    </select>

                                                    {!doneAlterarTabelaNB ? (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-branco" disabled>
                                                                <DynamicLoading/>
                                                            </button>
                                                        </div>
                                                    ) : (tabelaVigenteChaveNB !== tabelaSelecionadaNB) ? (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-azul-ajustavel" onClick={() => { alterarTabelaVigenteNB(tabelaSelecionadaNB); }}> Alterar lista vigente </button>
                                                        </div>
                                                    ) : (
                                                        <div className="wrapper-botao-alterar-lista-vigente">
                                                            <button className="button-submit-cinza-ajustavel" disabled> Alterar lista vigente </button>
                                                        </div>
                                                    )}
                                                </div>

                                                <br />
                                                <label htmlFor="lista-tabelas-disponiveis" className="label-client-text">Listas Nobreak disponíveis</label>

                                                <table className="table-pre-visualizacao">
                                                    <thead>
                                                        <tr className="texto-center-children">
                                                            <th className="texto-center tabela-sem-quebra-linha">Lista</th>
                                                            <th className="texto-center tabela-sem-quebra-linha">Tabela Servidor</th>
                                                            <th className="texto-center tabela-sem-quebra-linha">Tabela vigente</th>
                                                            <th className="texto-center">Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tabelasDisponiveisNB.length > 0 && tabelasDisponiveisNB.map(tabela => {
                                                            return(
                                                                <tr key={tabela.id}>
                                                                    <td>{tabela.valor.valor}</td>
                                                                    <td>{tabela.chave}</td>
                                                                    <td>{tabela.descricao === "Tabela vigente NB" && (<>Sim</>)}</td>
                                                                    <td>
                                                                        <div className={classeFuncoes}>
                                                                            <button onClick={() => {handleVisualizarTabela({nome_exibicao: tabela.valor.valor, nome_mysql: tabela.chave, tabela: tabela});}} className="edit1">
                                                                                <FiInfo size={18} />
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : (
                                            <DynamicLoading />
                                        )}
                                    </div>
                                </>
                            ) : (<></>)}
                        </div>
                        

                        <div className="bloco2-painel-controle">
                            {permissaoUsuario.verificar(47, token.tipo_usuario) ? (
                                <>
                                    <div className="bloco4-painel-controle-cabecalho">
                                        <h3> Outras tabelas </h3>

                                        <button className="type1-main-button" onClick={() => incluirOutraTabela()}> 
                                            <FiPlus size={18} />
                                            <span>Incluir outra tabela</span>
                                        </button>
                                    </div>
                                    <div className="input-group-painel-controle-altura1">
                                        {doneDadosPainelControle ? (
                                            <>
                                                <table className="table-pre-visualizacao">
                                                    <thead>
                                                        <tr className="texto-center-children">
                                                            <th className="texto-center">Nome</th>
                                                            <th className="texto-center">Número de linhas</th>
                                                            <th className="texto-center">Criação da tabela</th>
                                                            <th className="texto-center">Auto incremento</th>
                                                            <th className="texto-center">Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {listaTabelas.length > 0 && listaTabelas.map(tabela => {
                                                            return(
                                                                <>
                                                                    <tr key={tabela.Name}>
                                                                        <td>{tabela.Name}</td>
                                                                        <td>{tabela.Rows > 0 ? (tabela.Rows) : ("")}</td>
                                                                        <td>{(new Date(tabela.Create_time)).toLocaleDateString() + " - " + (new Date(tabela.Create_time)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</td>
                                                                        <td>{tabela.Auto_increment}</td>
                                                                        <td>
                                                                            <div className={classeFuncoes}>
                                                                                <button onClick={() => {handleVisualizarTabela({nome_exibicao: tabela.Name, nome_mysql: tabela.Name});}} className="edit1">
                                                                                    <FiInfo size={18} />
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : (
                                            <div style={{ height: '304px'}}>
                                                <DynamicLoading />
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : (<></>)}
                        </div>
                    </div>
                
                </div>
            </div>
            <div className="container">
                { modalConfirmarExclusaoAberto && <ModalConfirmarExclusao excluir_cliente={handleDeleteCustomer} clienteIntencaoExcluir={clienteIntencaoExcluir} setClienteIntencaoExcluir={setClienteIntencaoExcluir} setModalConfirmarExclusaoAberto={setModalConfirmarExclusaoAberto} /> }
                { modalUsuarioAberto && <ModalUsuario listaUsuarios={listaUsuarios} setListaUsuarios={setListaUsuarios} rotaDados={rotaDados} rotaDadosCarregando={rotaDadosCarregando} clienteExibido={clienteExibido} fecharModalUsuario={fecharModalUsuario} estadoRecebido={estadoRecebido} /> }

                <div className="body-container-2-sem-margem-superior">
                    <div className="type1-element">
                        <div className="painel-controle-top-line2">
                            <h3>
                                Usuários cadastrados
                            </h3>

                            <input
                                autoFocus
                                name="nome_razao_cliente"
                                className="text-input-search"
                                type="text"
                                id="nome_razao_cliente"
                                placeholder="Pesquisar"
                                onChange={e => { setUsuarioProcurado(e.target.value); }}
                                autoComplete="off"
                            />

                            <button className="type1-main-button" onClick={() => setModalCadastrarUsuarioAberto(true)}>
                                <FiUserPlus/>
                                <span>Cadastrar usuário</span>
                            </button>
                        </div>

                        <div className="tabela-generica-content">
                            {!done ? (
                                <div>
                                    <Loading />
                                </div>
                            )  :  (
                                <table className="table-pre-visualizacao">
                                    <thead>
                                        <tr className="texto-center-children">
                                            <th className="texto-center tabela-sem-quebra-linha">Nome</th>
                                            <th className="texto-center tabela-sem-quebra-linha">E-mail</th>
                                            <th className="texto-center tabela-sem-quebra-linha">Tipo usuário</th>
                                            <th className="texto-center tabela-sem-quebra-linha">Título</th>
                                            <th className="texto-center tabela-sem-quebra-linha">Comissao</th>
                                            <th className="texto-center tabela-sem-quebra-linha">Superior</th>
                                            <th className="texto-center">Comissao superior</th>
                                            <th className="texto-center tabela-sem-quebra-linha">Telefone 1</th>
                                            <th className="texto-center tabela-sem-quebra-linha">Telefone 2</th>
                                            <th className="texto-center tabela-sem-quebra-linha">Data cadastro</th>
                                            <th className="texto-center tabela-sem-quebra-linha">Data última alteração</th>
                                            {/* <th className="texto-center tabela-sem-quebra-linha">Ações</th> */}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {listaUsuarios.length > 0 && listaUsuarios
                                            .filter(usuario => {
                                                const usuario_atual_normalizado = normalizar_texto(usuario.nome_usuario);
                                                const usuario_procurado_normalizado = normalizar_texto(usuarioProcurado);

                                                if (usuario_atual_normalizado.includes(usuario_procurado_normalizado)) {
                                                    return usuario.nome_usuario;
                                                }
                                            })
                                            .map(usuario => {
                                                return(
                                                    <tr key={usuario.email_usuario}>
                                                        <td className="tabela-sem-quebra-linha">{usuario.nome_usuario}</td>
                                                        <td>{usuario.email_usuario}</td>
                                                        <td>{usuario.tipo_usuario}</td>
                                                        <td>{usuario.titulo_usuario}</td>
                                                        <td>{(usuario.comissao_usuario).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                        <td>{usuario.superior}</td>
                                                        <td>{(usuario.comissao_superior).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                                        <td>{usuario.telefone1_usuario}</td>
                                                        <td>{usuario.telefone2_usuario}</td>
                                                        <td>{(new Date(usuario.data_cadastro_usuario)).toLocaleDateString() + " - " + (new Date(usuario.data_cadastro_usuario)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</td>
                                                        <td>{(new Date(usuario.data_alteracao_usuario)).toLocaleDateString() + " - " + (new Date(usuario.data_alteracao_usuario)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</td>

                                                        {/* <td>
                                                            <div className={classeFuncoes}>

                                                                <button disabled onClick={() => {setClienteExibido(customer); setModalUsuarioAberto(true);}} className="disabled-button edit1">
                                                                    <FiEdit size={18} />
                                                                </button>

                                                                {!doneExcluirUsuario ? (
                                                                    <button disabled className="disabled-button white-background">
                                                                        <DynamicLoading />
                                                                    </button>
                                                                )  :  (
                                                                    <button disabled className="disabled-button delete1" onClick={() => {setClienteIntencaoExcluir(customer.ID_cliente); setModalConfirmarExclusaoAberto(true);}} >
                                                                        <FiTrash2 size={18} />
                                                                    </button>
                                                                )}
                                                                
                                                            </div>
                                                        </td> */}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    
                                </table>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}