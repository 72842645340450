// Referências externas
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiPrinter, FiBatteryCharging, FiAlertTriangle } from 'react-icons/fi';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Referências internas
import { Header, Loading, DynamicLoading, ProposalPrintGR, BotaoSalvarComoLarguraPersonalizada } from '../../common'
import api from '../../services/api';
// import Form from './Componentes/Formulario/Form';
import FormLoading from './Componentes/Formulario/FormLoading';
import ModalVoltar from './Componentes/Formulario/ModalVoltar';
import Resumo from './Componentes/Resumo';
import ModalSalvarComo from '../../common/BotaoSalvarComoLarguraPersonalizada/ModalSalvarComo';
import ItemGerador from './Componentes/ItemGerador';
import validar from './Componentes/Formulario/validarEstado';

// Estilo
import './styles.css';

// Referências internas
const permissaoUsuario = require('../../services/permissaoUsuario');

const PropostaGRSimulacao = props => {
    const history = useHistory();

    const [token, setToken] = useState({});
    const [modalSalvarComoAberto, setModalSalvarComoAberto] = useState(false);
    const [modalVoltarAberto, setModalVoltarAberto] = useState(false);
    
    const [done, setDone] = useState(false);

    const [novaProposta, setNovaProposta] = useState(true);

    // Variável para controlar se alguma informação inserida pelo usuário ainda não foi salva.
    // O propósito é controlar a saída desta página. Caso seja 'true' irá exibir o modal de
    // confirmação após o botão 'Voltar' ser pressionado. 
    const [propostaAlterada, setPropostaAlterada] = useState(false);

    const [salvarBloqueado, setSalvarBloqueado] = useState(false);
    const [salvarCarregando, setSalvarCarregando] = useState(false);

    const [impressaoBloqueada, setImpressaoBloqueada] = useState(true);
    const [impressaoCarregando, setImpressaoCarregando] = useState(false);
    const [dadosImpressao, setDadosImpressao] = useState({});

    // Cabeçalho e controle da proposta exibida
    const [clientID, setClientID] = useState('');
    const [vocativo, setVocativo] = useState('');
    const [numero_proposta, setNumero_proposta] = useState(0);
    const [revisao_proposta, setRevisao_proposta] = useState(0);
    const [data_proposta_string, setData_proposta_string] = useState(0);

    // Dados enviados para <Form />
    const [chave_tabela_vigente, setChave_tabela_vigente] = useState({chave: ''});
    const [tabelas_validas, setTabelas_validas] = useState([]);
    const [responsaveis_validos, setResponsaveis_validos] = useState([]);
    const [dadosRoute, setDadosRoute] = useState('');
    const [estadoProposta, setEstadoProposta] = useState({
        tabela: '',
        responsavel: '',
        id_usuario_responsavel_proposta: 0,
        percentual_comissao: 0.00,
        outros_valores: 0,
        percentual_margem: 0,
        desconto_weg_proposta: [0, 0, 0, 0],
        percentual_imposto: 0,
        status_proposta: '',
        valor_global: 0,
        nota_fiscal_produto: 0,
        nota_fiscal_servico: 0,
        total_produto: 0,
        faturamento_direto_cliente: 1,
    });

    const [resumo, setResumo] = useState({
        nota_fiscal_produto: 0,
        nota_fiscal_servico: 0,
        valor_comissao: 0,
        outros_valores: 0,
        valor_imposto: 0,
        valor_margem: 0,
        valor_global: 0,
    });

    // Variáveis Detalhes
    const [sistema_outros, setSistema_outros] = useState([]);
    const [totalProduto, setTotalProduto] = useState(0);

    const [erros, setErros] = useState({});

    function BotaoImprimir(props) {
        if (props.impressaoBloqueada === false) {
            if (props.impressaoCarregando === true) {
                return <button disabled> <DynamicLoading width='40px' /> </button>
            }
            else {
                return <ProposalPrintGR propostaImpressa={props.dadosImpressao} dadosRoute={dadosRoute} setImpressaoCarregando={props.setImpressaoCarregando} />
            }
        } else {
            return <button disabled><span><FiPrinter/> Imprimir </span></button>
        }
    }

    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarSucessoAoSalvar = () => {
        toast.success('Proposta salva com sucesso!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroPreenchimentoFormulario = () => {
        toast.warn('Fomulário incompleto ou inválido!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    
    function informacaoAlterada() {
        setPropostaAlterada(true);
        setImpressaoBloqueada(true);
    }
    
    function identificacaoProposta(proposta) {
        try {
            const ID_cliente = proposta.ID_cliente
            const titulo_cliente = proposta.titulo_cliente
            const nome_razao_cliente = proposta.nome_razao_cliente
            let vocativo_definido;
            
            setClientID(ID_cliente);

            if (titulo_cliente === "Sr." || titulo_cliente === "Sra.") {
                vocativo_definido = `${titulo_cliente} ${nome_razao_cliente}`;
                setVocativo(vocativo_definido);
                
            } else if (titulo_cliente === "N/A"){
                vocativo_definido = nome_razao_cliente;
                setVocativo(vocativo_definido);
            }

        } catch (error) {
            history.push('/propostas_gr');
        }
    }

    function tituloPagina(proposta) {
        let vocativo_definido;

        if (proposta !== undefined && proposta.titulo_cliente !== undefined) {
            if (proposta.titulo_cliente === "Sr." || proposta.titulo_cliente === "Sra.") {
                vocativo_definido = `${proposta.titulo_cliente} ${proposta.nome_razao_cliente}`;
            } else if (proposta.titulo_cliente === "N/A"){
                vocativo_definido = proposta.nome_razao_cliente;
            }
    
            if (proposta.numero_proposta !== undefined && proposta.revisao_proposta !== undefined && vocativo_definido !== undefined) {
                if (proposta.revisao_proposta > 1) {
                    document.title = `${proposta.numero_proposta}_GR_v${proposta.revisao_proposta} ${vocativo_definido}`
                } else {
                    document.title = `${proposta.numero_proposta}_GR ${vocativo_definido}`
                }
                
            } else {
                proposta.numero_proposta === undefined && console.log('erro: ' + 'proposta.numero_proposta' + "_GR");
                proposta.revisao_proposta === undefined && console.log('erro: ' + 'proposta.revisao_proposta');
                vocativo_definido === undefined &&  console.log('erro: ' + 'vocativo_definido');
            }
        }
    }

    function verificarPropostaExistente(proposta_salva) {
        try {
            if (proposta_salva !== undefined) {
                setSalvarBloqueado(true);
                setImpressaoBloqueada(false);

                setNovaProposta(false);
                setDadosImpressao(proposta_salva);

                setNumero_proposta((proposta_salva.numero_proposta));
                setRevisao_proposta(proposta_salva.revisao_proposta);
                setData_proposta_string((new Date(proposta_salva.data_apresentacao_proposta)).toLocaleDateString());

                setEstadoProposta({
                    ...estadoProposta,
                    tabela: proposta_salva.tabela_considerada,
                    responsavel: proposta_salva.nome_usuario,
                    id_usuario_responsavel_proposta: proposta_salva.id_usuario_responsavel_proposta,
                    percentual_comissao: proposta_salva.percentual_comissao,
                    outros_valores: proposta_salva.outros_valores,
                    percentual_margem: proposta_salva.percentual_margem,
                    desconto_weg_proposta: proposta_salva.desconto_weg_proposta,
                    percentual_imposto: proposta_salva.percentual_imposto,
                    status_proposta: proposta_salva.status_proposta,
                    valor_global: proposta_salva.valor_global,
                    nota_fiscal_produto: proposta_salva.nota_fiscal_produto,
                    nota_fiscal_servico: proposta_salva.nota_fiscal_servico,
                    id_cliente_proposta: proposta_salva.id_cliente_proposta,
                    total_produto: proposta_salva.total_produto,
                    faturamento_direto_cliente: proposta_salva.faturamento_direto_cliente,

                });

                let valor_imposto;

                if (proposta_salva.faturamento_direto_cliente === 1) {
                    valor_imposto = proposta_salva.nota_fiscal_servico * (proposta_salva.percentual_imposto / 100)
                } else {
                    valor_imposto = proposta_salva.valor_global * (proposta_salva.percentual_imposto / 100)
                }

                const valor_comissao = proposta_salva.valor_global * (proposta_salva.percentual_comissao / 100);
                const valor_margem = proposta_salva.valor_global * (proposta_salva.percentual_margem / 100);

                setResumo({
                    nota_fiscal_produto: proposta_salva.nota_fiscal_produto,
                    nota_fiscal_servico: proposta_salva.nota_fiscal_servico,
                    valor_comissao: valor_comissao,
                    outros_valores: proposta_salva.outros_valores,
                    valor_imposto: valor_imposto,
                    valor_margem: valor_margem,
                    valor_global: proposta_salva.valor_global,
                });

                setSistema_outros(proposta_salva.sistema_outros);
                setTotalProduto(proposta_salva.total_produto);
            }

        } catch (error) {  }
    }

    function alteracao_responsavel(e, responsaveis, objetoRecebido) {
        informacaoAlterada();
        
        const responsavel_selecionado = e.target.value;
        const array_responsaveis = Object.assign({}, ...responsaveis.map((x) => ({[x.nome_usuario]: [x.id_usuario, x.comissao_usuario]})));
        if(responsavel_selecionado !== ""){
          const { name, value } = e.target;
    
          const comissao_padrao = parseFloat(array_responsaveis[responsavel_selecionado][1].toFixed(2));
    
          const objetoCalcularValorGlobal = calcularValorGlobal({...objetoRecebido, "percentual_comissao": comissao_padrao}, 'retornar_objeto');
    
          const novoValor = {
            ...objetoCalcularValorGlobal,
            [name]: value,
            ['id_usuario_responsavel_proposta']: array_responsaveis[responsavel_selecionado][0],
            ['percentual_comissao']: comissao_padrao
          }
          
    
          setSalvarBloqueado(false);
          informacaoAlterada();
    
          setEstadoProposta(novoValor);
          setErros(validar(novoValor));
        }
    }
  
    function handleChange(e) {
        informacaoAlterada();

        const { name, value } = e.target;

        const novoValor = {
        ...estadoProposta,
        [name]: value
        }

        setEstadoProposta(novoValor);
        setErros(validar(novoValor));
    };

    function alterarFaturamento(e) {
        informacaoAlterada();

        const { name, value } = e.target;

        const novoValor = {
        ...estadoProposta,
        [name]: parseInt(value)
        }

        setEstadoProposta(novoValor);
        setErros(validar(novoValor));
    };

    async function salvarProposta(numero_proposta_param = 0, revisao_proposta_param = 0) {
        const resultadoValidacao = validar(estadoProposta);
        setErros(resultadoValidacao);

        if (Object.keys(resultadoValidacao).length > 0) {
            notificarErroPreenchimentoFormulario();
        } else if (Object.keys(resultadoValidacao).length === 0) {
            setSalvarBloqueado(true);
            setImpressaoBloqueada(true);
    
            setSalvarCarregando(true);
    
            let bool_sucesso = false;
    
            if (!permissaoUsuario.verificar(50, token.tipo_usuario)) {
                numero_proposta_param = numero_proposta;
                revisao_proposta_param = revisao_proposta;
            }
    
            try {
                if (novaProposta === true || !permissaoUsuario.verificar(50, token.tipo_usuario)) {
                    // POST
                    const send = {
                        numero_proposta: numero_proposta_param,
                        revisao_proposta: revisao_proposta_param,
    
                        id_cliente_proposta: clientID,
                        id_usuario_responsavel_proposta: estadoProposta.id_usuario_responsavel_proposta,
                        percentual_comissao: estadoProposta.percentual_comissao,
    
                        outros_valores: estadoProposta.outros_valores,
                        tabela_considerada: estadoProposta.tabela,
                        sistema_outros: sistema_outros,
                        total_produto: totalProduto,
    
                        valor_global: estadoProposta.valor_global,
                        nota_fiscal_produto: estadoProposta.nota_fiscal_produto,
                        nota_fiscal_servico: estadoProposta.nota_fiscal_servico,
                        desconto_weg_proposta: estadoProposta.desconto_weg_proposta,
                        
                        percentual_imposto: estadoProposta.percentual_imposto,
                        percentual_margem: estadoProposta.percentual_margem,
                        status_proposta: estadoProposta.status_proposta,
                        faturamento_direto_cliente: estadoProposta.faturamento_direto_cliente,
                    };
    
                    const result = await api.post('propostas_gr', send);
    
                    identificacaoProposta(result.data);
                    verificarPropostaExistente(result.data);
    
                    tituloPagina(result.data);
    
                    setNovaProposta(false);
    
                } else if (permissaoUsuario.verificar(50, token.tipo_usuario)) {
                    // PATCH
                    const send = {
                        id_cliente_proposta: clientID,
                        id_usuario_responsavel_proposta: estadoProposta.id_usuario_responsavel_proposta,

                        percentual_comissao: estadoProposta.percentual_comissao,
                        
                        outros_valores: estadoProposta.outros_valores,
                        tabela_considerada: estadoProposta.tabela,
                        sistema_outros: sistema_outros,
                        total_produto: totalProduto,
                        
                        valor_global: estadoProposta.valor_global,
                        nota_fiscal_produto: estadoProposta.nota_fiscal_produto,
                        nota_fiscal_servico: estadoProposta.nota_fiscal_servico,
                        desconto_weg_proposta: estadoProposta.desconto_weg_proposta,
                        
                        percentual_imposto: estadoProposta.percentual_imposto,
                        percentual_margem: estadoProposta.percentual_margem,
                        status_proposta: estadoProposta.status_proposta,
    
                        numero_proposta: numero_proposta,
                        revisao_proposta: revisao_proposta,
                        faturamento_direto_cliente: estadoProposta.faturamento_direto_cliente,
                    };
                    
                    const result = await api.patch('propostas_gr', send);
    
                    identificacaoProposta(result.data);
                    verificarPropostaExistente(result.data);
    
                    tituloPagina(result.data);
                }
                notificarSucessoAoSalvar();
    
                setPropostaAlterada(false);
                setSalvarBloqueado(true);
                setImpressaoBloqueada(false);
    
                bool_sucesso = true;
    
            } catch (error) {
                notificarErro();
                console.log('salvarProposta');
    
                setSalvarCarregando(false);
                setSalvarBloqueado(false);
                setImpressaoBloqueada(true);
            }
    
            setSalvarCarregando(false);
            return bool_sucesso;
        }
    }

    function aumentarQuantidade(objetoRecebido, dados) {
        console.log(estadoProposta)
        console.log(objetoRecebido)
        setSistema_outros(
            sistema_outros.map(x => (
                x.item === dados.item ? (
                    {...x, quantidade: dados.quantidade + 1}
                ) : (x)
            ))
        );

        const total_produto_calculado = parseFloat(totalProduto) + parseFloat(dados.preco_com_frete);
        setTotalProduto(total_produto_calculado);

        calcularValorGlobal({...objetoRecebido, "total_produto": total_produto_calculado});
        informacaoAlterada();
        setSalvarBloqueado(false);
    }

    function diminuirQuantidade(objetoRecebido, dados) {
        console.log(objetoRecebido)
        if (dados.quantidade > 0) {
            setSistema_outros(
                sistema_outros.map(x => (
                    x.item === dados.item ? (
                        {...x, quantidade: dados.quantidade - 1}
                    ) : (x)
                ))
            )
    
            const total_produto_calculado = parseFloat(totalProduto) - parseFloat(dados.preco_com_frete)
            setTotalProduto(total_produto_calculado);

            calcularValorGlobal({...objetoRecebido, "total_produto": total_produto_calculado});
            informacaoAlterada();
            setSalvarBloqueado(false);
        }
    }

    function calcularValorGlobal(objetoRecebido, modo='executar') {
        let outros_valores = objetoRecebido.outros_valores;
        let total_produto = objetoRecebido.total_produto;
        let percentual_comissao = objetoRecebido.percentual_comissao;
        let percentual_margem = objetoRecebido.percentual_margem;
        let percentual_imposto = objetoRecebido.percentual_imposto;
        let faturamento_direto_cliente = objetoRecebido.faturamento_direto_cliente;
        
        let valor_global;
        let valor_imposto;
        let nota_fiscal_produto;
        let nota_fiscal_servico;

        // Definição valor global
        if (faturamento_direto_cliente === 1) {
            valor_global =
                total_produto +
                (
                    (   
                        outros_valores + 
                        (total_produto * ((percentual_comissao / 100) + (percentual_margem / 100)))
                    )
                    /
                    (1 - (percentual_comissao / 100) - (percentual_margem / 100) - (percentual_imposto / 100))
                )

            valor_imposto = (valor_global - total_produto) * (percentual_imposto / 100);
            nota_fiscal_produto = total_produto;
            nota_fiscal_servico = valor_global - total_produto;
        
        } else if (faturamento_direto_cliente === 0) {
            valor_global =
                (total_produto + outros_valores)
                /
                (1 - (percentual_comissao / 100) - (percentual_margem / 100) - (percentual_imposto / 100))

            valor_imposto = valor_global * (percentual_imposto / 100)
            nota_fiscal_produto = 0;
            nota_fiscal_servico = valor_global;
        } else {
            console.log("faturamento_direto_cliente Inválido")
        }

        const valor_margem = valor_global * (percentual_margem / 100);
        const valor_comissao = valor_global * (percentual_comissao / 100);
        
        setResumo({
            nota_fiscal_produto: nota_fiscal_produto,
            nota_fiscal_servico: nota_fiscal_servico,
            valor_comissao: valor_comissao,
            outros_valores: outros_valores,
            valor_imposto: valor_imposto,
            valor_margem: valor_margem,
            valor_global: valor_global,
        });

// CORRIGIR
        if (modo === 'executar') {
            setEstadoProposta({
                ...objetoRecebido,
                percentual_comissao: percentual_comissao,
                outros_valores: outros_valores,
                percentual_margem: percentual_margem,
                percentual_imposto: percentual_imposto,
                valor_global: valor_global,
                nota_fiscal_produto: nota_fiscal_produto,
                nota_fiscal_servico: nota_fiscal_servico,
                total_produto: total_produto,
            });
        } else if(modo === "retornar_objeto") {
            return({
                ...objetoRecebido,
                percentual_comissao: percentual_comissao,
                outros_valores: outros_valores,
                percentual_margem: percentual_margem,
                percentual_imposto: percentual_imposto,
                valor_global: valor_global,
                nota_fiscal_produto: nota_fiscal_produto,
                nota_fiscal_servico: nota_fiscal_servico,
                total_produto: total_produto,
            });
        }

        setSalvarBloqueado(false);
    }

    useEffect(() => {
        document.title = `SGC Brazil Solution`;

        const tokenJSON = JSON.parse(localStorage.getItem('sgc_br'));
        setToken(tokenJSON);

        async function carregarDados() {
            setDone(false);
            
            try {
                const response = await api.get('dados');
                setDadosRoute(response.data);

                setEstadoProposta({
                    ...estadoProposta,
                    tabela: response.data.tabela_vigente_gr.chave,
                    percentual_margem: Math.round(response.data.informacao_complementar_float.Margem.valor * 100) / 100,
                    desconto_weg_proposta: response.data.desconto_weg.map(x => (Math.round(x * 100) / 100)),
                    percentual_imposto: Math.round(response.data.informacao_complementar_float.Imposto.valor * 100) / 100,
                });

                setChave_tabela_vigente(response.data.tabela_vigente_gr.chave);
                setTabelas_validas(response.data.tabelas_disponiveis_gr);
                setResponsaveis_validos(response.data.responsaveis);

                const dados_tabela_vigente_gr = await api.post('propostas_gr/detalhes_tabela', {
                    "tabela_selecionada": response.data.tabela_vigente_gr.chave
                });                

                setSistema_outros(dados_tabela_vigente_gr.data.map(x => ({...x, "quantidade": 0})));
                
                identificacaoProposta(props.location.data);
                
                verificarPropostaExistente(props.location.proposal);

                tituloPagina(props.location.proposal);
                
            } catch (error) {
                notificarErro();
                console.log(error);
                console.log('carregarDados');
            }

            setDone(true);
        }
        
        carregarDados();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <div className="container">
            <Header />
            <ToastContainer />

            {modalSalvarComoAberto && <ModalSalvarComo
                setModalSalvarComoAberto={setModalSalvarComoAberto}
                salvarProposta={salvarProposta}
                salvarCarregando={salvarCarregando}
            />}
            
            <div className="body-container-2">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="type1-title">
                            {novaProposta === true ? "NOVA PROPOSTA GR" : "PROPOSTA GR"} <span className="icone-actionTitle"><FiBatteryCharging size={13}/></span>
                        </div>
                    </div>
                    <div className="proposal-client-title">
                        <table>
                            <thead>
                                {numero_proposta === 0 ? (
                                    <tr>
                                        <th>Cliente:</th><td>{vocativo}</td>
                                    </tr>
                                ) : (
                                    <>
                                        <tr>
                                            <th>Cliente:</th><td colSpan="3">{vocativo}</td>
                                        </tr>
                                        <tr>
                                            <th>Nº proposta:</th><td>{numero_proposta}_GR</td><th>Versão:</th><td>{revisao_proposta}ª</td>
                                        </tr>
                                        <tr>
                                            <th>Data:</th><td colSpan="3">{data_proposta_string}</td>
                                        </tr>
                                    </>
                                )}
                            </thead>
                        </table>

                        <div className="proposal-element-body-functions-gerador">
                            { modalVoltarAberto && <ModalVoltar voltar={() => history.push(props.location.origin)} setModalVoltarAberto={setModalVoltarAberto} /> }
                            <div className="proposal-button-gerador">
                                <button onClick={() => {propostaAlterada ? setModalVoltarAberto(true) : history.push(props.location.origin);}}>
                                    <div> <span> Voltar </span> </div>
                                </button>
                            </div>

                            {salvarCarregando ? (
                                <div className="proposal-button-gerador">
                                    <button disabled> <DynamicLoading width='40px' /> </button>
                                </div>
                            ) : permissaoUsuario.verificar(50, token.tipo_usuario) && novaProposta === true ? (
                                <BotaoSalvarComoLarguraPersonalizada
                                    larguraRecebida="30%"
                                    setModalSalvarComoAberto={setModalSalvarComoAberto}
                                    notificarErro={notificarErro}
                                    salvarCarregando={salvarCarregando}
                                    salvarProposta={salvarProposta}
                                    salvarBloqueado={salvarBloqueado}
                                />
                            ) : (
                                <div className="proposal-button-gerador">
                                    <button onClick={() => salvarProposta()} disabled={salvarBloqueado}><span>Salvar</span></button>
                                </div>
                            )}
                            
                            <div className="proposal-button-gerador">
                                <BotaoImprimir
                                    impressaoBloqueada={impressaoBloqueada}
                                    impressaoCarregando={impressaoCarregando}
                                    setImpressaoCarregando={setImpressaoCarregando}
                                    dadosImpressao={dadosImpressao}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="proposal-container">
                    <div className="proposal-element-narrow-wrapper">
                        {!done ? (
                            <FormLoading />
                        ) : (
                            <form>
                                <Tabs>
                                    <TabList>
                                        <Tab>Geral {erros.geral && <FiAlertTriangle size={12} color="#ff0000"/>}</Tab>
                                    </TabList>

                                    <div className="proposal-subelement">
                                        <div className="proposal-element-body">
                                            <TabPanel>
                                                <div className="input-group-2">
                                                <label htmlFor="responsavel">
                                                    Agente Comercial {erros.responsavel && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                </label>
                                                <select
                                                    id="responsavel"
                                                    name="responsavel"
                                                    value={estadoProposta.responsavel}
                                                    onChange={e => { alteracao_responsavel(e, responsaveis_validos, estadoProposta); }}>
                                                    <option value ='' >Selecione</option>

                                                    {responsaveis_validos.map(responsavel => (
                                                        <option key = {responsavel.nome_usuario} value = {responsavel.nome_usuario}>{responsavel.nome_usuario}</option>
                                                    ))}

                                                </select>
                                                </div>

                                                <div className="input-group-narrow-2">
                                                    <label htmlFor="percentual_comissao" className='form-label'>
                                                        Comissão (%) {erros.percentual_comissao && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                    </label>
                                                    <input
                                                        value={(Math.round((estadoProposta.percentual_comissao + Number.EPSILON) * 10_000) / 10_000)}
                                                        type='number'
                                                        min="0"
                                                        max='10'
                                                        step="0.1"
                                                        name='percentual_comissao'
                                                        onChange={e => {handleChange(e); calcularValorGlobal({...estadoProposta, "percentual_comissao": parseFloat(e.target.value)})}}
                                                    />
                                                </div>

                                                <div className="input-group-narrow-2-right">
                                                    <label htmlFor="faturamento_direto_cliente" className='form-label'>
                                                        Fat. direto cliente {erros.faturamento_direto_cliente && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                    </label>
                                                    <select
                                                        id="faturamento_direto_cliente"
                                                        name="faturamento_direto_cliente"
                                                        value={String(estadoProposta.faturamento_direto_cliente)}
                                                        onChange={e => {alterarFaturamento(e); calcularValorGlobal({...estadoProposta, "faturamento_direto_cliente": (parseInt(e.target.value))})}}
                                                    >
                                                        <option value ='1' >Sim</option>
                                                        <option value ='0' >Não</option>
                                                    </select>
                                                </div>

                                                <p className="secao-formulario">
                                                    DETALHES
                                                </p>
                                                
                                                <div className="input-group-2">
                                                <label htmlFor="tabela">
                                                    Lista de preços {erros.tabela && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                </label>
                                                <select
                                                    disabled
                                                    id="tabela"
                                                    name="tabela"
                                                    value={estadoProposta.tabela}
                                                    // onChange={e => {alteracao_tabela(e, tabelas_validas);}}
                                                >
                                                    {(tabelas_validas && tabelas_validas.length > 0) &&
                                                    tabelas_validas
                                                        .filter(function (tabela) {return tabela.chave === estadoProposta.tabela})
                                                        .map(tabela => (
                                                        <option key = {tabela.chave} value = {tabela.chave}>{tabela.valor.valor}</option>
                                                    ))}

                                                    {(tabelas_validas && tabelas_validas.length > 0) && tabelas_validas
                                                        .filter(function (tabela) {return tabela.chave !== estadoProposta.tabela})
                                                        .map(tabela => (
                                                        <option key = {tabela.chave} value = {tabela.chave}>{tabela.valor.valor}</option>
                                                    ))}
                                                </select>

                                                {estadoProposta.tabela !== chave_tabela_vigente ? (
                                                    <div className="alerta-tabela">
                                                    <p>
                                                        Atenção! Tabela desatualizada
                                                    </p>
                                                    <span>
                                                        <FiAlertTriangle size={18} />
                                                    </span>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                </div>

                                                <div className="input-group-2">
                                                <label htmlFor="outros_valores">
                                                    Outros valores (R$) {erros.outros_valores && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                </label>
                                                <input
                                                    value={estadoProposta.outros_valores}
                                                    type='number'
                                                    min="0"
                                                    step="0.01"
                                                    name='outros_valores'
                                                    onChange={e => {handleChange(e); calcularValorGlobal({...estadoProposta, "outros_valores": parseFloat(e.target.value)})}}
                                                />
                                                </div>

                                                <p className="secao-formulario">
                                                RESTRITO
                                                </p>
                                                
                                                <div className="input-group-narrow-2">
                                                <label htmlFor="percentual_margem" className='form-label'>
                                                    Margem (%) {erros.percentual_margem && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                </label>
                                                <input
                                                    value={estadoProposta.percentual_margem}
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    step="0.05"
                                                    name="percentual_margem"
                                                    onChange={e => {handleChange(e); calcularValorGlobal({...estadoProposta, "percentual_margem": parseFloat(e.target.value)})}}
                                                />
                                                </div>
                                                
                                                <div className="input-group-narrow-2-right">
                                                <label htmlFor="percentual_margem" className='form-label'>
                                                    Imposto (%) {erros.percentual_imposto && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                </label>
                                                <input
                                                    value={estadoProposta.percentual_imposto}
                                                    type='number'
                                                    min="0"
                                                    max='100'
                                                    step="0.1"
                                                    name='percentual_imposto'
                                                    onChange={e => {handleChange(e); calcularValorGlobal({...estadoProposta, "percentual_imposto": parseFloat(e.target.value)})}}
                                                />
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </Tabs>
                            </form>
                        )}
                    </div>
                    
                    <div className="proposal-element-wide-wrapper">
                        <Tabs>
                            <TabList>
                                <Tab>Produtos</Tab>
                            </TabList>

                            <div className="proposal-subelement">
                                <div className="proposal-element-body">

                                    <TabPanel>
                                        {!done ? (
                                            <div>
                                                <Loading />
                                            </div>
                                        )  :  (
                                            <>
                                                <table className="table-kit">
                                                    <thead>
                                                        <tr>
                                                            <th style={{width: "80px"}} className="table-kit-narrow">Quantidade</th>
                                                            <th>Item</th>
                                                            <th className="table-kit-narrow">Preço Unit. Compra</th>
                                                            <th className="table-kit-narrow round-br">Preço Unit. Venda</th>
                                                            <th style={{width: "10px", borderTop: "1px solid #fff", borderBottom: "1px solid #fff"}}></th>
                                                            <th className="table-kit-narrow round-br">Subtotal Venda</th>
                                                        </tr>
                                                    </thead>
                                                    
                                                    <tbody>
                                                        {sistema_outros.map(dados => {
                                                            return <ItemGerador
                                                                key={dados.item}
                                                                dados={dados}
                                                                setSistema_outros={setSistema_outros}
                                                                aumentarQuantidade={(e) => aumentarQuantidade(estadoProposta, dados)}
                                                                diminuirQuantidade={(e) => diminuirQuantidade(estadoProposta, dados)}
                                                                estadoProposta={estadoProposta}
                                                                totalProduto={totalProduto}
                                                            />
                                                        })}
                                                    </tbody>
                                                </table>

                                                <div className="proposal-same-line-tables">
                                                    <div className="proposal-table-total">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="table-50p">Total compra</th>
                                                                    <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(totalProduto)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="table-50p">Total venda</th>
                                                                    <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(estadoProposta.valor_global)}</td>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </TabPanel>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                    
                    <div className="proposal-element-narrow-wrapper">
                        <Tabs>
                            <TabList>
                                <Tab>Resumo</Tab>
                            </TabList>

                            <div className="proposal-subelement">
                                <div className="proposal-element-body">
                                    <TabPanel>
                                        <Resumo
                                            done={done}
                                            resumo={resumo}
                                            estadoProposta={estadoProposta}
                                            dadosRoute={dadosRoute}
                                        />
                                    </TabPanel>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropostaGRSimulacao;