// Referências externas
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiPrinter, FiBatteryCharging, FiAlertTriangle } from 'react-icons/fi';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Referências internas
import { Header, Loading, DynamicLoading, ProposalPrintOM, BotaoSalvarComoLarguraPersonalizada } from '../../common'
import api from '../../services/api';
// import Form from './Componentes/Formulario/Form';
import FormLoading from './Componentes/Formulario/FormLoading';
import ModalVoltar from './Componentes/Formulario/ModalVoltar';
import Resumo from './Componentes/Resumo';
import ModalSalvarComo from '../../common/BotaoSalvarComoLarguraPersonalizada/ModalSalvarComo';
import ItemOM from './Componentes/ItemOM';
import validar from './Componentes/Formulario/validarEstado';

// Estilo
import './styles.css';

// Referências internas
const permissaoUsuario = require('../../services/permissaoUsuario');

const PropostaOMSimulacao = props => {
    const history = useHistory();

    const [token, setToken] = useState({});
    const [modalSalvarComoAberto, setModalSalvarComoAberto] = useState(false);
    const [modalVoltarAberto, setModalVoltarAberto] = useState(false);
    
    const [done, setDone] = useState(false);

    const [novaProposta, setNovaProposta] = useState(true);

    // Variável para controlar se alguma informação inserida pelo usuário ainda não foi salva.
    // O propósito é controlar a saída desta página. Caso seja 'true' irá exibir o modal de
    // confirmação após o botão 'Voltar' ser pressionado. 
    const [propostaAlterada, setPropostaAlterada] = useState(false);

    const [salvarBloqueado, setSalvarBloqueado] = useState(false);
    const [salvarCarregando, setSalvarCarregando] = useState(false);

    const [impressaoBloqueada, setImpressaoBloqueada] = useState(true);
    const [impressaoCarregando, setImpressaoCarregando] = useState(false);
    const [dadosImpressao, setDadosImpressao] = useState({});

    // Cabeçalho e controle da proposta exibida
    const [clientID, setClientID] = useState('');
    const [vocativo, setVocativo] = useState('');
    const [numero_proposta, setNumero_proposta] = useState(0);
    const [revisao_proposta, setRevisao_proposta] = useState(0);
    const [data_proposta_string, setData_proposta_string] = useState(0);

    // Dados enviados para <Form />
    const [chave_tabela_vigente, setChave_tabela_vigente] = useState({chave: ''});
    const [tabelas_validas, setTabelas_validas] = useState([]);
    const [responsaveis_validos, setResponsaveis_validos] = useState([]);
    const [dadosRoute, setDadosRoute] = useState('');
    const [propostaExistente, setPropostaExistente] = useState({});
    const [estadoProposta, setEstadoProposta] = useState({
        tabela: '',
        responsavel: '',
        id_responsavel: 0,
        comissao: 0.00,
        desconto: 0,
        outros_valores: 0,
        margem_proposta: 0,
        material_proposta: 0,
        desconto_weg_proposta: [0, 0, 0, 0],
        imposto_proposta: 0,
        status_proposta: '',
        valor_final: 0,
        valor_comissao: 0,
        parcial_produto: 0,
        parcial_servico: 0,
        id_cliente_proposta: 0,
        valor_kit: 0,
    });

    // Variáveis Detalhes
    const [sistema_outros, setSistema_outros] = useState([]);
    const [totalProduto, setTotalProduto] = useState(0);

    const [erros, setErros] = useState({});

    function BotaoImprimir(props) {
        if (props.impressaoBloqueada === false) {
            if (props.impressaoCarregando === true) {
                return <button disabled> <DynamicLoading width='40px' /> </button>
            }
            else {
                return <ProposalPrintOM propostaImpressa={props.dadosImpressao} dadosRoute={dadosRoute} setImpressaoCarregando={props.setImpressaoCarregando} />
            }
        } else {
            return <button disabled><span><FiPrinter/> Imprimir </span></button>
        }
    }

    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarSucessoAoSalvar = () => {
        toast.success('Proposta salva com sucesso!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroPreenchimentoFormulario = () => {
        toast.warn('Fomulário incompleto ou inválido!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    
    function informacaoAlterada() {
        setPropostaAlterada(true);
        setImpressaoBloqueada(true);
    }
    
    function identificacaoProposta(proposta) {
        try {
            const ID_cliente = proposta.ID_cliente
            const titulo_cliente = proposta.titulo_cliente
            const nome_razao_cliente = proposta.nome_razao_cliente
            let vocativo_definido;
            
            setClientID(ID_cliente);

            if (titulo_cliente === "Sr." || titulo_cliente === "Sra.") {
                vocativo_definido = `${titulo_cliente} ${nome_razao_cliente}`;
                setVocativo(vocativo_definido);
                
            } else if (titulo_cliente === "N/A"){
                vocativo_definido = nome_razao_cliente;
                setVocativo(vocativo_definido);
            }

        } catch (error) {
            history.push('/propostas_om');
        }
    }

    function tituloPagina(proposta) {
        let vocativo_definido;

        if (proposta !== undefined && proposta.titulo_cliente !== undefined) {
            if (proposta.titulo_cliente === "Sr." || proposta.titulo_cliente === "Sra.") {
                vocativo_definido = `${proposta.titulo_cliente} ${proposta.nome_razao_cliente}`;
            } else if (proposta.titulo_cliente === "N/A"){
                vocativo_definido = proposta.nome_razao_cliente;
            }
    
            if (proposta.numero_proposta !== undefined && proposta.revisao_proposta !== undefined && vocativo_definido !== undefined) {
                if (proposta.revisao_proposta > 1) {
                    document.title = `${proposta.numero_proposta}_OM_v${proposta.revisao_proposta} ${vocativo_definido}`
                } else {
                    document.title = `${proposta.numero_proposta}_OM ${vocativo_definido}`
                }
                
            } else {
                proposta.numero_proposta === undefined && console.log('erro: ' + 'proposta.numero_proposta' + "_OM");
                proposta.revisao_proposta === undefined && console.log('erro: ' + 'proposta.revisao_proposta');
                vocativo_definido === undefined &&  console.log('erro: ' + 'vocativo_definido');
            }
        }
    }

    function verificarPropostaExistente(proposta_salva) {
        try {
            if (proposta_salva !== undefined) {
                setSalvarBloqueado(true);
                setImpressaoBloqueada(false);

                setNovaProposta(false);
                setDadosImpressao(proposta_salva);

                setNumero_proposta((proposta_salva.numero_proposta));
                setRevisao_proposta(proposta_salva.revisao_proposta);
                setData_proposta_string((new Date(proposta_salva.data_apresentacao_proposta)).toLocaleDateString());

                setEstadoProposta({
                    ...estadoProposta,
                    valor_final: proposta_salva.valor_final,
                    valor_comissao: proposta_salva.valor_final * (proposta_salva.comissao_proposta / 100),
                    comissao: proposta_salva.comissao_proposta,
                    desconto: proposta_salva.desconto_proposta,
                    parcial_produto: proposta_salva.parcial_produto,
                    parcial_servico: proposta_salva.parcial_servico,
                    desconto_weg_proposta: proposta_salva.desconto_weg_proposta,

                    imposto_proposta: proposta_salva.imposto_proposta,
                    margem_proposta: proposta_salva.margem_proposta,
                    material_proposta: proposta_salva.material_proposta,
                    status_proposta: proposta_salva.status_proposta,

                    responsavel: proposta_salva.nome_usuario,
                    tabela: proposta_salva.tabela_considerada,
                    outros_valores: proposta_salva.outros_valores,
                    desconto_weg_proposta: proposta_salva.desconto_weg_proposta,

                    id_cliente_proposta: proposta_salva.id_cliente_proposta,
                    id_usuario_responsavel_proposta: proposta_salva.id_usuario_responsavel_proposta,
                });

                setSistema_outros(proposta_salva.sistema_outros);
                setTotalProduto(proposta_salva.valor_kit);

                // Variável enviada para 'Form.js'
                setPropostaExistente(proposta_salva);
            }

        } catch (error) {  }
    }

    
  function alteracao_responsavel(e, responsaveis) {
    informacaoAlterada();
    
    const responsavel_selecionado = e.target.value;
    const array_responsaveis = Object.assign({}, ...responsaveis.map((x) => ({[x.nome_usuario]: [x.id_usuario, x.comissao_usuario]})));
    if(responsavel_selecionado !== ""){
      const { name, value } = e.target;

      const comissao_padrao = parseFloat(array_responsaveis[responsavel_selecionado][1].toFixed(2));

      const objetoCalcularValorGlobal = calcularValorGlobal({"comissao": comissao_padrao}, 'retornar_objeto');

      const novoValor = {
        ...objetoCalcularValorGlobal,
        [name]: value,
        ['id_responsavel']: array_responsaveis[responsavel_selecionado][0],
        ['comissao']: comissao_padrao
      }
      

      setSalvarBloqueado(false);
      informacaoAlterada();

      setEstadoProposta(novoValor);
      setErros(validar(novoValor));
    }
  }

  function handleChange(e) {
    informacaoAlterada();
    
    const { name, value } = e.target;
    const novoValor = {
      ...estadoProposta,
      [name]: parseFloat(value)
    }

    setEstadoProposta(novoValor);
    setErros(validar(novoValor));
  };

    async function salvarProposta(numero_proposta_param = 0, revisao_proposta_param = 0) {
        const resultadoValidacao = validar(estadoProposta);
        setErros(resultadoValidacao);

        if (Object.keys(resultadoValidacao).length > 0) {
            notificarErroPreenchimentoFormulario();
        } else if (Object.keys(resultadoValidacao).length === 0) {
            setSalvarBloqueado(true);
            setImpressaoBloqueada(true);
    
            setSalvarCarregando(true);
    
            let bool_sucesso = false;
    
            if (!permissaoUsuario.verificar(50, token.tipo_usuario)) {
                numero_proposta_param = numero_proposta;
                revisao_proposta_param = revisao_proposta;
            }
    
            try {
                if (novaProposta === true || !permissaoUsuario.verificar(50, token.tipo_usuario)) {
                    // POST
                    const send = {
                        numero_proposta: numero_proposta_param,
                        revisao_proposta: revisao_proposta_param,
    
                        id_cliente_proposta: clientID,
                        id_usuario_responsavel_proposta: estadoProposta.id_responsavel,
                        comissao_proposta: estadoProposta.comissao,
                        desconto_proposta: estadoProposta.desconto,
    
                        outros_valores: estadoProposta.outros_valores,
                        tabela_considerada: estadoProposta.tabela,
                        sistema_outros: sistema_outros,
                        valor_kit: totalProduto,
    
                        valor_final: estadoProposta.valor_final,
                        parcial_produto: estadoProposta.parcial_produto,
                        parcial_servico: estadoProposta.parcial_servico,
                        desconto_weg_proposta: estadoProposta.desconto_weg_proposta,
                        
                        imposto_proposta: estadoProposta.imposto_proposta,
                        margem_proposta: estadoProposta.margem_proposta,
                        material_proposta: estadoProposta.material_proposta,
                        status_proposta: estadoProposta.status_proposta
                    };
    
                    const result = await api.post('propostas_om', send);
    
                    identificacaoProposta(result.data);
                    verificarPropostaExistente(result.data);
    
                    tituloPagina(result.data);
    
                    setNovaProposta(false);
    
                } else if (permissaoUsuario.verificar(50, token.tipo_usuario)) {
                    // PATCH
                    const send = {
                        id_cliente_proposta: clientID,
                        id_usuario_responsavel_proposta: estadoProposta.id_usuario_responsavel_proposta,

                        comissao_proposta: estadoProposta.comissao,
                        desconto_proposta: estadoProposta.desconto,
                        
                        outros_valores: estadoProposta.outros_valores,
                        tabela_considerada: estadoProposta.tabela,
                        sistema_outros: sistema_outros,
                        valor_kit: totalProduto,
                        
                        valor_final: estadoProposta.valor_final,
                        parcial_produto: estadoProposta.parcial_produto,
                        parcial_servico: estadoProposta.parcial_servico,
                        desconto_weg_proposta: estadoProposta.desconto_weg_proposta,
                        
                        imposto_proposta: estadoProposta.imposto_proposta,
                        margem_proposta: estadoProposta.margem_proposta,
                        material_proposta: estadoProposta.material_proposta,
                        status_proposta: estadoProposta.status_proposta,
    
                        numero_proposta: numero_proposta,
                        revisao_proposta: revisao_proposta
                    };
                    
                    const result = await api.patch('propostas_om', send);
    
                    identificacaoProposta(result.data);
                    verificarPropostaExistente(result.data);
    
                    tituloPagina(result.data);
                }
                notificarSucessoAoSalvar();
    
                setPropostaAlterada(false);
                setSalvarBloqueado(true);
                setImpressaoBloqueada(false);
    
                bool_sucesso = true;
    
            } catch (error) {
                notificarErro();
                console.log('salvarProposta');
    
                setSalvarCarregando(false);
                setSalvarBloqueado(false);
                setImpressaoBloqueada(true);
            }
    
            setSalvarCarregando(false);
            return bool_sucesso;
        }
    }

    function aumentarQuantidade(dados) {
        setSistema_outros(
            sistema_outros.map(x => (
                x.item === dados.item ? (
                    {...x, quantidade: dados.quantidade + 1}
                ) : (x)
            ))
        );

        const total_produto_calculado = parseFloat(totalProduto) + parseFloat(dados.preco_com_frete);
        setTotalProduto(total_produto_calculado);

        calcularValorGlobal({"total_produto": total_produto_calculado});
        informacaoAlterada();
        setSalvarBloqueado(false);
    }

    function diminuirQuantidade(dados) {
        if (dados.quantidade > 0) {
            setSistema_outros(
                sistema_outros.map(x => (
                    x.item === dados.item ? (
                        {...x, quantidade: dados.quantidade - 1}
                    ) : (x)
                ))
            )
    
            const total_produto_calculado = parseFloat(totalProduto) - parseFloat(dados.preco_com_frete)
            setTotalProduto(total_produto_calculado);

            calcularValorGlobal({"total_produto": total_produto_calculado});
            informacaoAlterada();
            setSalvarBloqueado(false);
        }
    }

    function calcularValorGlobal(objetoRecebido, modo='executar') {
        let outros_valores_considerado;
        let total_produto_considerado;
        let comissao_considerado;
        let desconto_considerado;
        let margem_proposta_considerado;
        let imposto_proposta_considerado;

        objetoRecebido.outros_valores !== undefined ? (outros_valores_considerado = objetoRecebido.outros_valores) : (outros_valores_considerado = estadoProposta.outros_valores);
        objetoRecebido.total_produto !== undefined ? (total_produto_considerado = objetoRecebido.total_produto) : (total_produto_considerado = totalProduto);
        objetoRecebido.comissao !== undefined ? (comissao_considerado = objetoRecebido.comissao) : (comissao_considerado = estadoProposta.comissao);
        objetoRecebido.desconto !== undefined ? (desconto_considerado = objetoRecebido.desconto) : (desconto_considerado = estadoProposta.desconto);
        objetoRecebido.margem_proposta !== undefined ? (margem_proposta_considerado = objetoRecebido.margem_proposta) : (margem_proposta_considerado = estadoProposta.margem_proposta);
        objetoRecebido.imposto_proposta !== undefined ? (imposto_proposta_considerado = objetoRecebido.imposto_proposta) : (imposto_proposta_considerado = estadoProposta.imposto_proposta);

        const valor_global_calculado = (100  - desconto_considerado) * (
            (outros_valores_considerado + total_produto_considerado)
                /
            (100 - comissao_considerado - margem_proposta_considerado - imposto_proposta_considerado)
        );

        if (modo === 'executar') {
            setEstadoProposta({
                ...estadoProposta,
                outros_valores: outros_valores_considerado,
                comissao: comissao_considerado,
                desconto: desconto_considerado,
                margem_proposta: margem_proposta_considerado,
                material_proposta: 0,
                imposto_proposta: imposto_proposta_considerado,
                valor_final: valor_global_calculado,
                valor_comissao: valor_global_calculado * comissao_considerado / 100,
                parcial_produto: total_produto_considerado,
                parcial_servico: valor_global_calculado - total_produto_considerado,
            });
        } else if(modo === "retornar_objeto") {
            return({
                ...estadoProposta,
                outros_valores: outros_valores_considerado,
                comissao: comissao_considerado,
                desconto: desconto_considerado,
                margem_proposta: margem_proposta_considerado,
                material_proposta: 0,
                imposto_proposta: imposto_proposta_considerado,
                valor_final: valor_global_calculado,
                valor_comissao: valor_global_calculado * comissao_considerado / 100,
                parcial_produto: total_produto_considerado,
                parcial_servico: valor_global_calculado - total_produto_considerado,
            });
        }

        setSalvarBloqueado(false);
    }

    useEffect(() => {
        document.title = `SGC Brazil Solution`;

        const tokenJSON = JSON.parse(localStorage.getItem('sgc_br'));
        setToken(tokenJSON);

        async function carregarDados() {
            setDone(false);

            try {
                const response = await api.get('dados');
                setDadosRoute(response.data);
                console.log(response.data);
                console.log(response.data.responsaveis);

                setEstadoProposta({
                    ...estadoProposta,
                    tabela: response.data.tabela_vigente_om.chave,
                    desconto_weg_proposta: response.data.desconto_weg.map(x => (Math.round(x * 100) / 100)),
                    imposto_proposta: Math.round(response.data.informacao_complementar_float.Imposto.valor * 100) / 100,
                    margem_proposta: Math.round(response.data.informacao_complementar_float.Margem.valor * 100) / 100,
                });

                setChave_tabela_vigente(response.data.tabela_vigente_om.chave);
                setTabelas_validas(response.data.tabelas_disponiveis_om);
                setResponsaveis_validos(response.data.responsaveis);
                console.log(response.data.responsaveis);

                const dados_tabela_vigente_om = await api.post('propostas_om/detalhes_tabela', {
                    "tabela_selecionada": response.data.tabela_vigente_om.chave
                });                

                setSistema_outros(dados_tabela_vigente_om.data.map(x => ({...x, "quantidade": 0})));
                
                identificacaoProposta(props.location.data);
                
                verificarPropostaExistente(props.location.proposal);

                tituloPagina(props.location.proposal);
                
            } catch (error) {
                notificarErro();
                console.log(error);
                console.log('carregarDados');
            }

            setDone(true);
        }
        
        carregarDados();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <div className="container">
            <Header />
            <ToastContainer />

            {modalSalvarComoAberto && <ModalSalvarComo
                setModalSalvarComoAberto={setModalSalvarComoAberto}
                salvarProposta={salvarProposta}
                salvarCarregando={salvarCarregando}
            />}
            
            <div className="body-container-2">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="type1-title">
                            {novaProposta === true ? "NOVA PROPOSTA OM" : "PROPOSTA OM"} <span className="icone-actionTitle"><FiBatteryCharging size={13}/></span>
                        </div>
                    </div>
                    <div className="proposal-client-title">
                        <table>
                            <thead>
                                {numero_proposta === 0 ? (
                                    <tr>
                                        <th>Cliente:</th><td>{vocativo}</td>
                                    </tr>
                                ) : (
                                    <>
                                        <tr>
                                            <th>Cliente:</th><td colSpan="3">{vocativo}</td>
                                        </tr>
                                        <tr>
                                            <th>Nº proposta:</th><td>{numero_proposta}_OM</td><th>Versão:</th><td>{revisao_proposta}ª</td>
                                        </tr>
                                        <tr>
                                            <th>Data:</th><td colSpan="3">{data_proposta_string}</td>
                                        </tr>
                                    </>
                                )}
                            </thead>
                        </table>

                        <div className="proposal-element-body-functions-om">
                            { modalVoltarAberto && <ModalVoltar voltar={() => history.push(props.location.origin)} setModalVoltarAberto={setModalVoltarAberto} /> }
                            <div className="proposal-button-om">
                                <button onClick={() => {propostaAlterada ? setModalVoltarAberto(true) : history.push(props.location.origin);}}>
                                    <div> <span> Voltar </span> </div>
                                </button>
                            </div>

                            {salvarCarregando ? (
                                <div className="proposal-button-om">
                                    <button disabled> <DynamicLoading width='40px' /> </button>
                                </div>
                            ) : permissaoUsuario.verificar(50, token.tipo_usuario) && novaProposta === true ? (
                                <BotaoSalvarComoLarguraPersonalizada
                                    larguraRecebida="30%"
                                    setModalSalvarComoAberto={setModalSalvarComoAberto}
                                    notificarErro={notificarErro}
                                    salvarCarregando={salvarCarregando}
                                    salvarProposta={salvarProposta}
                                    salvarBloqueado={salvarBloqueado}
                                />
                            ) : (
                                <div className="proposal-button-om">
                                    <button onClick={() => salvarProposta()} disabled={salvarBloqueado}><span>Salvar</span></button>
                                </div>
                            )}
                            
                            <div className="proposal-button-om">
                                <BotaoImprimir
                                    impressaoBloqueada={impressaoBloqueada}
                                    impressaoCarregando={impressaoCarregando}
                                    setImpressaoCarregando={setImpressaoCarregando}
                                    dadosImpressao={dadosImpressao}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="proposal-container">
                    <div className="proposal-element-narrow-wrapper">
                        {!dadosRoute ? (
                            <FormLoading />
                        ) : (
                            <form>
                                <Tabs>
                                    <TabList>
                                        <Tab>Geral {erros.geral && <FiAlertTriangle size={12} color="#ff0000"/>}</Tab>
                                    </TabList>

                                    <div className="proposal-subelement">
                                        <div className="proposal-element-body">
                                            <TabPanel>
                                                <div className="input-group-2">
                                                <label htmlFor="responsavel">
                                                    Agente Comercial {erros.responsavel && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                </label>
                                                <select
                                                    id="responsavel"
                                                    name="responsavel"
                                                    value={estadoProposta.responsavel}
                                                    onChange={e => { alteracao_responsavel(e, responsaveis_validos); }}>
                                                    <option value ='' >Selecione</option>

                                                    {responsaveis_validos.map(responsavel => (
                                                        <option key = {responsavel.nome_usuario} value = {responsavel.nome_usuario}>{responsavel.nome_usuario}</option>
                                                    ))}

                                                </select>
                                                </div>

                                                <div className="input-group-narrow-2">
                                                <label htmlFor="comissao" className='form-label'>
                                                    Comissão (%) {erros.comissao && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                </label>
                                                <input
                                                    value={(Math.round((estadoProposta.comissao + Number.EPSILON) * 10_000) / 10_000)}
                                                    type='number'
                                                    min="0"
                                                    max='10'
                                                    step="0.1"
                                                    name='comissao'
                                                    onChange={e => {handleChange(e); calcularValorGlobal({"comissao": parseFloat(e.target.value)})}}
                                                />
                                                </div>

                                                <div className="input-group-narrow-2-right">
                                                <label htmlFor="desconto" className='form-label'>
                                                    Desconto (%) {erros.desconto && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                </label>
                                                <input
                                                    value={(Math.round((estadoProposta.desconto + Number.EPSILON) * 10_000) / 10_000)}
                                                    type='number'
                                                    min="0"
                                                    max='5'
                                                    step="0.1"
                                                    name='desconto'
                                                    onChange={e => {
                                                    handleChange(e);
                                                    calcularValorGlobal({"desconto": parseFloat(e.target.value)})
                                                    }}
                                                />
                                                </div>

                                                <p className="secao-formulario">
                                                DETALHES
                                                </p>
                                                
                                                <div className="input-group-2">
                                                <label htmlFor="tabela">
                                                    Lista de preços {erros.tabela && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                </label>
                                                <select
                                                    disabled
                                                    id="tabela"
                                                    name="tabela"
                                                    value={estadoProposta.tabela}
                                                    // onChange={e => {alteracao_tabela(e, tabelas_validas);}}
                                                >
                                                    {(tabelas_validas && tabelas_validas.length > 0) &&
                                                    tabelas_validas
                                                        .filter(function (tabela) {return tabela.chave === estadoProposta.tabela})
                                                        .map(tabela => (
                                                        <option key = {tabela.chave} value = {tabela.chave}>{tabela.valor.valor}</option>
                                                    ))}

                                                    {(tabelas_validas && tabelas_validas.length > 0) && tabelas_validas
                                                        .filter(function (tabela) {return tabela.chave !== estadoProposta.tabela})
                                                        .map(tabela => (
                                                        <option key = {tabela.chave} value = {tabela.chave}>{tabela.valor.valor}</option>
                                                    ))}
                                                </select>

                                                {estadoProposta.tabela !== chave_tabela_vigente ? (
                                                    <div className="alerta-tabela">
                                                    <p>
                                                        Atenção! Tabela desatualizada
                                                    </p>
                                                    <span>
                                                        <FiAlertTriangle size={18} />
                                                    </span>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                </div>

                                                <div className="input-group-2">
                                                <label htmlFor="outros_valores">
                                                    Outros valores (R$) {erros.outros_valores && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                </label>
                                                <input
                                                    value={estadoProposta.outros_valores}
                                                    type='number'
                                                    min="0"
                                                    step="0.01"
                                                    name='outros_valores'
                                                    onChange={e => {handleChange(e); calcularValorGlobal({"outros_valores": parseFloat(e.target.value)})}}
                                                />
                                                </div>

                                                <p className="secao-formulario">
                                                RESTRITO
                                                </p>
                                                
                                                <div className="input-group-narrow-2">
                                                <label htmlFor="margem_proposta" className='form-label'>
                                                    Margem alvo (%) {erros.margem_proposta && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                </label>
                                                <input
                                                    value={estadoProposta.margem_proposta}
                                                    type="number"
                                                    min="0"
                                                    max="100"
                                                    step="0.05"
                                                    name="margem_proposta"
                                                    onChange={e => {handleChange(e); calcularValorGlobal({"margem_proposta": parseFloat(e.target.value)})}}
                                                />
                                                </div>
                                                
                                                <div className="input-group-narrow-2-right">
                                                <label htmlFor="margem_proposta" className='form-label'>
                                                    Imposto (%) {erros.imposto_proposta && <FiAlertTriangle size={12} color="#ff0000"/>}
                                                </label>
                                                <input
                                                    value={estadoProposta.imposto_proposta}
                                                    type='number'
                                                    min="0"
                                                    max='100'
                                                    step="0.1"
                                                    name='imposto_proposta'
                                                    onChange={e => {handleChange(e); calcularValorGlobal({"imposto_proposta": parseFloat(e.target.value)})}}
                                                />
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </Tabs>
                            </form>
                        )}
                    </div>
                    
                    <div className="proposal-element-wide-wrapper">
                        <Tabs>
                            <TabList>
                                <Tab>Produtos</Tab>
                            </TabList>

                            <div className="proposal-subelement">
                                <div className="proposal-element-body">

                                    <TabPanel>
                                        {!done ? (
                                            <div>
                                                <Loading />
                                            </div>
                                        )  :  (
                                            <>
                                                <table className="table-kit">
                                                    <thead>
                                                        <tr>
                                                            <th style={{width: "80px"}} className="table-kit-narrow">Quantidade</th>
                                                            <th>Item</th>
                                                            <th className="table-kit-narrow">Preço Unit. Compra</th>
                                                            <th style={{width: "70px"}} className="table-kit-narrow round-br">Margem</th>
                                                            <th className="table-kit-narrow round-br">Preço Unit. Venda</th>
                                                            <th style={{width: "10px", borderTop: "1px solid #fff", borderBottom: "1px solid #fff"}}></th>
                                                            <th className="table-kit-narrow round-br">Subtotal</th>
                                                        </tr>
                                                    </thead>
                                                    
                                                    <tbody>
                                                        {sistema_outros.map(dados => {
                                                            return <ItemOM
                                                                key={dados.item}
                                                                dados={dados}
                                                                sistema_outros={sistema_outros}
                                                                setSistema_outros={setSistema_outros}
                                                                aumentarQuantidade={aumentarQuantidade}
                                                                diminuirQuantidade={diminuirQuantidade}
                                                                estadoProposta={estadoProposta}
                                                            />
                                                        })}
                                                    </tbody>
                                                </table>

                                                <div className="proposal-same-line-tables">
                                                    <div className="proposal-table-total">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="table-50p">Total compra</th>
                                                                    <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(totalProduto)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="table-50p">Total venda</th>
                                                                    <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(estadoProposta.valor_final)}</td>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </TabPanel>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                    
                    <div className="proposal-element-narrow-wrapper">
                        <Tabs>
                            <TabList>
                                <Tab>Resumo</Tab>
                            </TabList>

                            <div className="proposal-subelement">
                                <div className="proposal-element-body">
                                    <TabPanel>
                                        <Resumo done={done} resumo={estadoProposta} estadoProposta={estadoProposta} dadosRoute={dadosRoute} />
                                    </TabPanel>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropostaOMSimulacao;