// Referências externas
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiGrid, FiUsers, FiFileText, FiFile, FiFileMinus, FiBookOpen, FiBatteryCharging, FiTruck, FiPower, FiCode, FiActivity, FiMap } from 'react-icons/fi'

// Estilo
import './styles.css';

// Referências internas
const permissaoUsuario = require('../../services/permissaoUsuario');

export default function BarraNavegacao () {
    const location = useLocation();
    const [classeInicio, setClasseInicio] = useState('text-link');
    const [classeClientes, setClasseClientes] = useState('text-link');
    const [classePropostas, setClassePropostas] = useState('text-link');
    const [classePropostasResumidas, setClassePropostasResumidas] = useState('text-link');
    const [classePropostasBranco, setClassePropostasBranco] = useState('text-link');
    const [classePropostasEV, setClassePropostasEV] = useState('text-link');
    const [classePropostasBAT, setClassePropostasBAT] = useState('text-link');
    const [classePropostasGR, setClassePropostasGR] = useState('text-link');
    const [classePropostasOM, setClassePropostasOM] = useState('text-link');
    const [classePropostasNB, setClassePropostasNB] = useState('text-link');
    const [classeSistemas, setClasseSistemas] = useState('text-link');
    const [classeListaUsuarios, setClasseListaUsuarios] = useState('text-link');
    const [classeRotaTeste, setClasseRotaTeste] = useState('text-link');
    
    const [token, setToken] = useState({});
    
    useEffect(() => {
        setToken(JSON.parse(localStorage.getItem('sgc_br')));

        switch (location.pathname) {
            case '/inicio':
                setClasseInicio(classeInicio + ' active');
                break;
            case '/clientes':
                setClasseClientes(classeClientes + ' active');
                break;
            case '/propostas_completas':
                setClassePropostas(classePropostas + ' active');
                break;
            case '/propostas':
                setClassePropostasResumidas(classePropostasResumidas + ' active');
                break;
            case '/propostas_branco':
                setClassePropostasBranco(`${classePropostasBranco} active`);
                break;
            case '/propostas_ev':
                setClassePropostasEV(`${classePropostasEV} active`);
                break;
            case '/propostas_bat':
                setClassePropostasBAT(`${classePropostasBAT} active`);
                break;
            case '/propostas_gr':
                setClassePropostasGR(`${classePropostasGR} active`);
                break;
            case '/propostas_om':
                setClassePropostasOM(`${classePropostasOM} active`);
                break;
            case '/propostas_nb':
                setClassePropostasNB(`${classePropostasNB} active`);
                break;
            case '/sistemas':
                setClasseSistemas(`${classeSistemas} active`);
                break;
            case '/ListaUsuarios':
                setClasseListaUsuarios(`${classeListaUsuarios} active`);
                break;
            case '/RotaTeste':
                setClasseRotaTeste(`${classeRotaTeste} active`);
                break;
            default:
                break;
          }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {permissaoUsuario.verificar(60, token.tipo_usuario) ? (
                <div className="barra_navegacao">
                    <ul className="lista_navegacao4">
                        <Link className={classeInicio} to="/inicio"> <li><FiGrid size={18}/> <span>Início</span></li> </Link>
                        <Link className={classeClientes} to="/clientes"> <li><FiUsers size={18}/> <span>Clientes</span></li> </Link>
                        <Link className={classeSistemas} to="/sistemas"> <li><FiBookOpen size={18}/> <span>Sistemas</span></li> </Link>
                        <Link className={classePropostas} to="/propostas_completas"> <li><FiFileText size={18}/> <span>Propostas</span></li> </Link>
                        <Link className={classePropostasResumidas} to="/propostas"> <li><FiFileMinus size={18}/> <span>Propostas resumidas</span></li> </Link>
                        <Link className={classePropostasBranco} to="/propostas_branco"> <li><FiFile size={18}/> <span>Propostas em branco</span></li> </Link>
                        <Link className={classePropostasEV} to="/propostas_ev"> <li><FiTruck size={18}/> <span>Estação Veicular</span></li> </Link>
                        <Link className={classePropostasBAT} to="/propostas_bat"> <li><FiBatteryCharging size={18}/> <span>Bateria</span></li> </Link>
                        <Link className={classePropostasGR} to="/propostas_gr"> <li><FiActivity size={18}/> <span>Gerador</span></li> </Link>
                        <Link className={classePropostasOM} to="/propostas_om"> <li><FiMap size={18}/> <span>O&M</span></li> </Link>
                        <Link className={classePropostasNB} to="/propostas_nb"> <li><FiPower size={18}/> <span>Nobreak</span></li> </Link>
                        {/* <Link className={classeListaUsuarios} to="/ListaUsuarios"> <li><FiBook size={18}/> <span>Usuários</span></li> </Link> */}
                        <Link className={classeRotaTeste} to="/RotaTeste"> <li><FiCode size={18} /><span>RotaTeste</span></li> </Link>
                    </ul>
                </div>
            ) : permissaoUsuario.verificar(50, token.tipo_usuario) ? (
                <div className="barra_navegacao">
                    <ul className="lista_navegacao3">
                        <Link className={classeInicio} to="/inicio"> <li><FiGrid size={18}/> <span>Início</span></li> </Link>
                        <Link className={classeClientes} to="/clientes"> <li><FiUsers size={18}/> <span>Clientes</span></li> </Link>
                        <Link className={classeSistemas} to="/sistemas"> <li><FiBookOpen size={18}/> <span>Sistemas</span></li> </Link>
                        <Link className={classePropostas} to="/propostas_completas"> <li><FiFileText size={18}/> <span>Propostas</span></li> </Link>
                        <Link className={classePropostasResumidas} to="/propostas"> <li><FiFileMinus size={18}/> <span>Propostas resumidas</span></li> </Link>
                        <Link className={classePropostasBranco} to="/propostas_branco"> <li><FiFile size={18}/> <span>Propostas em branco</span></li> </Link>
                        <Link className={classePropostasEV} to="/propostas_ev"> <li><FiTruck size={18}/> <span>Estação Veicular</span></li> </Link>
                        <Link className={classePropostasBAT} to="/propostas_bat"> <li><FiBatteryCharging size={18}/> <span>Bateria</span></li> </Link>
                        <Link className={classePropostasGR} to="/propostas_gr"> <li> <FiActivity size={18}/> <span>Gerador</span></li> </Link>
                        <Link className={classePropostasOM} to="/propostas_om"> <li> <FiMap size={18}/> <span>O&M</span></li> </Link>
                        <Link className={classePropostasNB} to="/propostas_nb"> <li><FiPower size={18}/> <span>Nobreak</span></li> </Link>
                        {/* <Link className={classeListaUsuarios} to="/ListaUsuarios"> <li><FiBook size={18}/> <span>Usuários</span></li> </Link> */}
                    </ul>
                </div>
            ) : permissaoUsuario.verificar(47, token.tipo_usuario) ? (
                <div className="barra_navegacao">
                    <ul className="lista_navegacao2">
                        <Link className={classeClientes} to="/clientes"> <li><FiUsers size={18}/> <span>Clientes</span></li> </Link>
                        <Link className={classeSistemas} to="/sistemas"> <li><FiBookOpen size={18}/> <span>Sistemas</span></li> </Link>
                        <Link className={classePropostasResumidas} to="/propostas"> <li><FiFile size={18}/> <span>Propostas</span></li> </Link>
                        <Link className={classePropostasEV} to="/propostas_ev"> <li><FiBatteryCharging size={18}/> <span>Estação Veicular</span></li> </Link>
                        <Link className={classePropostasNB} to="/propostas_nb"> <li><FiPower size={18}/> <span>Nobreak</span></li> </Link>
                        {/* <Link className={classeListaUsuarios} to="/ListaUsuarios"> <li><FiBook size={18}/> <span>Usuários</span></li> </Link> */}
                    </ul>
                </div>
            ) : permissaoUsuario.verificar(45, token.tipo_usuario) ? (
                <div className="barra_navegacao">
                    <ul className="lista_navegacao2">
                        <Link className={classeClientes} to="/clientes"> <li><FiUsers size={18}/> <span>Clientes</span></li> </Link>
                        <Link className={classeSistemas} to="/sistemas"> <li><FiBookOpen size={18}/> <span>Sistemas</span></li> </Link>
                        <Link className={classePropostasResumidas} to="/propostas"> <li><FiFile size={18}/> <span>Propostas</span></li> </Link>
                        <Link className={classePropostasEV} to="/propostas_ev"> <li><FiBatteryCharging size={18}/> <span>Estação Veicular</span></li> </Link>
                        <Link className={classePropostasNB} to="/propostas_nb"> <li><FiPower size={18}/> <span>Nobreak</span></li> </Link>
                        {/* <Link className={classeListaUsuarios} to="/ListaUsuarios"> <li><FiBook size={18}/> <span>Usuários</span></li> </Link> */}
                    </ul>
                </div>
            ) : permissaoUsuario.verificar(10, token.tipo_usuario) ? (
                <div className="barra_navegacao">
                    <ul className="lista_navegacao1">
                        <Link className={classeClientes} to="/clientes"> <li><FiUsers size={18}/> <span>Clientes</span></li> </Link>
                        <Link className={classePropostasResumidas} to="/propostas"> <li><FiFile size={18}/> <span>Propostas</span></li> </Link>
                        {/* <Link className={classePropostasEV} to="/propostas_ev"> <li><FiBatteryCharging size={18}/> <span>Estação Veicular</span></li> </Link> */}
                    </ul>
                </div>
            ) : permissaoUsuario.verificar(5, token.tipo_usuario) ? (
                <div className="barra_navegacao">
                    <ul className="lista_navegacao1">
                        <Link className={classeSistemas} to="/sistemas"> <li><FiBookOpen size={18}/> <span>Sistemas</span></li> </Link>
                    </ul>
                </div>
            ) : (<></>)}
        </>
    )
}