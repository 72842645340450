// Referências externas
import React, { useState, useEffect } from 'react';
import { toast} from 'react-toastify';
import { parse } from "papaparse";
import { FiAlertTriangle } from 'react-icons/fi';

// Referências internas
import api from '../../../../services/api';
import { DynamicLoading } from '../../../../common';

// Estilo
import './style.css';

export const FileUploader = ({props, onSuccess}) => {
    const [files, setFiles] = useState([]);
    const [done, setDone] = useState(false);
    const [dadosLista, setDadosLista] = useState(false);

    const [tableHeader, setTableHeader] = useState([]);
    const [tableBody, setTableBody] = useState([]);

    const [tipoTabela, setTipoTabela] = useState("");
    const [nomeTabelaServidor, setNomeTabelaServidor] = useState("");
    const [nomeTabelaVisualizacao, setNomeTabelaVisualizacao] = useState("");
    const [modulosDisponiveis, setModulosDisponiveis] = useState([]);
    const [moduloPreferencial, setModuloPreferencial] = useState("");
    const [preferenciaVigente, setPreferenciaVigente] = useState("");
    const [frete, setFrete] = useState("");
    
    const [nomeArquivo, setNomeArquivo] = useState("");

    const [logImportar, setLogImportar] = useState(["Aguardando..."]);

    const [erros, setErros] = useState({});

    const notificarSucessoGenerico = () => {
        toast.success('Operação executada com sucesso!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function obterModulos(tipo_tabela_considerado, corpoTabela, cabecalhoTabela) {
        if (tipo_tabela_considerado === "lista" && corpoTabela.length > 0) {
            const array_objetos = corpoTabela.map(linha_tabela => {
                return Object.assign({}, ...cabecalhoTabela.map((chave, i) => ({ [chave]: linha_tabela[i] })));
            })

            const array_modulos_disponiveis_tipo1 = array_objetos.filter(x => x.tipo.includes('cristalino'));
            const array_modulos_disponiveis_tipo2 = array_objetos.filter(x => x.tipo.includes('Célula'));
            const array_modulos_disponiveis = [...array_modulos_disponiveis_tipo1, ...array_modulos_disponiveis_tipo2];
            const array_modulos_disponiveis_filtrado = array_modulos_disponiveis.map(x => (
                {
                    "modelo": x.item,
                    "potencia": x.parametro_valor,
                    "area": parseFloat((x.campo1).replace(',','.')),
                    "peso": parseFloat((x.campo2).replace(',','.')),
                    "garantia1": x.garantia1,
                    "garantia2": x.garantia2,
                    "campo_adicional1": x.campo_adicional1,
                }
            ));

            // Foi inserido apenas "rete" para evitar questões como F maiúsculo ou minúsculo
            const fretes_disponiveis = array_objetos.filter(x => (x.tipo.includes('rete') || x.item.includes('CIF') || x.item.includes('FOB')));
            const fretes_disponiveis_filtrado = fretes_disponiveis.map(x => (
                {
                    "tipo": x.item,
                    "acrescimo": parseFloat((x.preco).replace(',','.'))
                }
                
            ));

            setModulosDisponiveis(array_modulos_disponiveis_filtrado);
            setFrete(JSON.stringify(fretes_disponiveis_filtrado));
        }
    }

    function obterModulosHook(tipo_tabela_considerado) {
        obterModulos(tipo_tabela_considerado, tableBody, tableHeader)
    }

    async function parseArquivo(e) {
        setFiles(e.target.files);

        let dados = Array.from(e.target.files).filter((file) => file.type === "text/csv");

        await dados.forEach(async (file, index) => {
            setNomeArquivo(file.name)
            const text = await file.text();
            const result = parse(text, { header: true });
            const fields_valido = result.meta.fields.filter(x => x !== "");
            setTableHeader(fields_valido);

            let body_values = result.data.map((x) => {
                let array_body = [];
                
                for (let i in x){
                    array_body.push(x[i]);
                }
                
                return array_body;
            }).map(x => x.slice(0, fields_valido.length)).filter((x) => x[0] !== "");

            setTableBody(body_values);
            obterModulos(tipoTabela, body_values, fields_valido);
        });

    };

    async function uploadEImportacaoCSV(arquivo) {
        try {
            console.log(moduloPreferencial)
            const dados_tabela = {
                arquivo: nomeArquivo,
                nome_tabela_servidor: nomeTabelaServidor,
                tipo: tipoTabela,
    
                valor: nomeTabelaVisualizacao,
                modulo_preferencial: moduloPreferencial,
                preferencia_vigente: preferenciaVigente,
                tipos_frete: frete
            }
            console.log(dados_tabela)
    
            const result_upload = await api.post('painel/uploadCSV', arquivo);
            onSuccess(result_upload.data);
            setLogImportar(["UPLOAD: "+JSON.stringify(result_upload.data, null, '\t')]);

            if (result_upload.data !== undefined) {
                if (result_upload.data.resultado_operacao === 'arquivo enviado') {
                    notificarSucessoGenerico();

                    const result_importar = await api.post('painel/importarCSV', dados_tabela);
                    onSuccess(result_importar.data);
                    setLogImportar(["UPLOAD: " + JSON.stringify(result_upload.data, null, '\t'), "IMPORTAR: " + JSON.stringify(result_importar.data, null, '\t')]);

                    if (result_importar.data !== undefined) {
                        if (result_importar.data.mensagem === 'Tabela atualizada' || result_importar.data.mensagem === 'Tabela salva') {
                            notificarSucessoGenerico();
                        } else {
                            notificarErro();
                        }
                    } else {
                        notificarErro();
                    }
                } else {
                    notificarErro();
                }
            } else {
                notificarErro();
            }
        } catch (error) {
            console.log(error);
            notificarErro();
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();

        const errosVerificados = verificarErros();

        if ((Object.keys(errosVerificados)).length === 0) {
            const data = new FormData();
    
            data.append('file', files[0]);
    
            uploadEImportacaoCSV(data);
        }
    };

    function alterarTipoTabela(tipo) {
        if (tipo !== "selecione"){
            setTipoTabela(tipo);
            obterModulosHook(tipo); 
        }
    }

    function alterarModuloPreferencial(selecionado) {
        if (selecionado !== "selecione") {
            setModuloPreferencial(selecionado)
            console.log(moduloPreferencial)
        }
    }

    function alterarPreferenciaVigente(selecionado) {
        if (selecionado !== "selecione") {
            setPreferenciaVigente(selecionado)
        }
    }

    function verificarErros() {
        let errosVerificados = {};
        console.log(tipoTabela);

        if (tipoTabela !== 'lista' && tipoTabela !== 'lista_alternativa' && tipoTabela !== 'lista_EV' && tipoTabela !== 'lista_BAT' && tipoTabela !== 'lista_GR' && tipoTabela !== 'lista_OM' && tipoTabela !== 'lista_NB' && tipoTabela !== 'configuracao' && tipoTabela !== 'configuracao-MI' && tipoTabela !== 'generica') {
            errosVerificados.tipo_tabela = "Valor inválido";
            console.log(tipoTabela);
        }

        if (nomeTabelaServidor.trim().length <= 0) {
            errosVerificados.nome_tabela_servidor = "Valor inválido";
            console.log(nomeTabelaServidor);
        }
        
        if ((tipoTabela === 'lista' || tipoTabela === 'lista_alternativa' || tipoTabela === 'lista_EV' || tipoTabela === 'lista_BAT' || tipoTabela === 'lista_GR' || tipoTabela === 'lista_OM' || tipoTabela === 'lista_NB') && nomeTabelaVisualizacao.trim().length <= 0) {
            errosVerificados.nome_tabela_visualizacao = "Valor inválido";
            console.log(nomeTabelaVisualizacao);
        }
        
        if (tipoTabela === 'lista' && (moduloPreferencial === 'selecione' || moduloPreferencial === '')) {
            errosVerificados.modulo_preferencial = "Valor inválido";
            console.log(moduloPreferencial);
        }
        
        if ((tipoTabela === 'lista' || tipoTabela === 'lista_alternativa' || tipoTabela === 'lista_EV' || tipoTabela === 'lista_BAT' || tipoTabela === 'lista_GR' || tipoTabela === 'lista_OM' || tipoTabela === 'lista_NB') && (preferenciaVigente !== 'Sim' && preferenciaVigente !== 'Não')) {
            errosVerificados.preferencia_vigente = "Valor inválido";
            console.log(preferenciaVigente);
        }
        
        if (nomeArquivo.trim().length <= 0) {
            errosVerificados.input_arquivo = "Valor inválido";
            console.log(nomeArquivo);
        }
        
        if (tipoTabela === 'lista' && frete === '') {
            errosVerificados.frete = "Frete inválido";
            console.log(tipoTabela);
        }

        setErros(errosVerificados);
        console.log(errosVerificados);
        return errosVerificados;
    }
    
    useEffect(() => {
        
        async function carregarDados() {

        try {
            const response = await api.get('dados/listas');
            setDadosLista(response.data);
            
            setDone(true);

        } catch (error) {
            setDone(false);
            notificarErro();
        }

    }
    
    carregarDados();

        if (props !== undefined) {
            if (props.location !== undefined) {
                if (props.location.tipo !== undefined) {
                    if (props.location.tipo === 'lista' || props.location.tipo === 'lista_alternativa' || props.location.tipo === 'lista_EV' || props.location.tipo === 'lista_BAT' || props.location.tipo === 'lista_GR' || props.location.tipo === 'lista_OM' || props.location.tipo === 'lista_NB' || props.location.tipo === 'configuracao' || props.location.tipo === 'configuracao-MI' || props.location.tipo === 'generica') {
                        alterarTipoTabela(props.location.tipo);
                    }
                }
            }
        }

    }, [])

    return (
        <div>

            {!done ? (
                <div style={{ height: '304px', width: '200px', margin: "auto"}}>
                    <DynamicLoading />
                </div>
            )  :  (
                <div className="wrapper-importar-superior">
                    <form method="post" action="#" id="#" className="form-importar-tabela"
                    onSubmit={onSubmit}>
                        <div className="input-group-importar">
                            <label htmlFor="tipo_tabela" className="label-client-text">
                                Tipo {erros.tipo_tabela && <span title={erros.tipo_tabela}> <FiAlertTriangle size={12} color="#ff0000"/> </span>}
                            </label>

                            <select
                                name="tipo_tabela"
                                className="importar-select"
                                value={tipoTabela}
                                onChange={e => { setNomeTabelaServidor(""); alterarTipoTabela(e.target.value); }}
                            >
                                <option value="selecione">Selecione</option>
                                <option value="lista">Lista de preços</option>
                                <option value="lista_alternativa">Lista de preços alternativa</option>
                                <option value="lista_EV">Lista de preços EV</option>
                                <option value="lista_BAT">Lista de preços BAT</option>
                                <option value="lista_GR">Lista de preços GR</option>
                                <option value="lista_OM">Lista de preços OM</option>
                                <option value="lista_NB">Lista de preços NB</option>
                                <option value="configuracao">Configuração de kits</option>
                                <option value="configuracao-MI">Configuração Microinversor de kits</option>
                                <option value="generica">Tabela genérica</option>
                            </select>
                        </div>

                        <div className="input-group-importar">
                            <label htmlFor="nome_tabela_servidor" className="label-client-text">
                                Nome Tabela Servidor {erros.nome_tabela_servidor && <span title={erros.nome_tabela_servidor}> <FiAlertTriangle size={12} color="#ff0000"/> </span>}
                            </label>


                            {(tipoTabela === 'configuracao') ? (
                                <select
                                    name="id_usuario"
                                    // onChange={e => { handleChangeResponsavel(e); }}
                                    value={nomeTabelaServidor}
                                    onChange={e => { if (e.target.value !== "") { setNomeTabelaServidor(e.target.value) }; }}
                                    className="importar-tabela-select"
                                >

                                    <option value="">Selecione</option>

                                    {dadosLista.tabelas_disponiveis
                                        .map(lista => (
                                            <option key={lista} value={('configuracao-' + lista)}>{('configuracao-' + lista)}</option>
                                        ))
                                    }

                                </select>      
                            ):(tipoTabela === 'configuracao-MI') ? (
                                <select
                                    name="id_usuario"
                                    value={nomeTabelaServidor}
                                    onChange={e => { if (e.target.value !== "") { setNomeTabelaServidor(e.target.value) }; }}
                                    className="importar-tabela-select"
                                >

                                    <option value="">Selecione</option>

                                    {dadosLista.tabelas_disponiveis
                                        .map(lista => (
                                            <option key={lista} value={'configuracao-MI-' + (lista)}>{('configuracao-MI-' + lista)}</option>
                                        ))
                                    }

                                </select>      
                            ):(
                                <input
                                    className="importar-input"
                                    type="text"
                                    id="nome_tabela_servidor"
                                    name="nome_tabela_servidor"
                                    value={nomeTabelaServidor}
                                    onChange={e => { setNomeTabelaServidor(e.target.value); }}
                                />
                            )}

                            {tipoTabela === 'lista' ? (
                                <span className="exemplo-importar-tabela"> Exemplo: <strong>22-7-2-JULHO</strong> (Tabela nº 2 de 07/2022) </span>
                            ) : tipoTabela === 'lista_alternativa' ? (
                                <span className="exemplo-importar-tabela"> Exemplo: <strong>ALT-22-7-2-JULHO</strong> (Tabela alternativa nº 2 de 07/2022) </span>
                            ) : tipoTabela === 'lista_EV' ? (
                                <span className="exemplo-importar-tabela"> Exemplo: <strong>EV-22-7-2-JULHO</strong> (Tabela EV nº 2 de 07/2022) </span>
                            ) : tipoTabela === 'lista_BAT' ? (
                                <span className="exemplo-importar-tabela"> Exemplo: <strong>BAT-22-7-2-JULHO</strong> (Tabela BAT nº 2 de 07/2022) </span>
                            ) : tipoTabela === 'lista_GR' ? (
                                <span className="exemplo-importar-tabela"> Exemplo: <strong>GR-22-7-2-JULHO</strong> (Tabela GR nº 2 de 07/2022) </span>
                            ) : tipoTabela === 'lista_OM' ? (
                                <span className="exemplo-importar-tabela"> Exemplo: <strong>OM-22-7-2-JULHO</strong> (Tabela OM nº 2 de 07/2022) </span>
                            ) : tipoTabela === 'lista_NB' ? (
                                <span className="exemplo-importar-tabela"> Exemplo: <strong>NB-22-7-2-JULHO</strong> (Tabela NB nº 2 de 07/2022) </span>
                            ) : tipoTabela === 'configuracao' ? (
                                <span className="exemplo-importar-tabela"> Ex.: <strong>configuracao-22-7-2-JULHO</strong> (Tabela de configuraçãonº 2 de 07/2022) </span>
                            ) : tipoTabela === 'configuracao-MI' ? (
                                <span className="exemplo-importar-tabela"> Ex.: <strong>configuracao-MI-22-7-2-JULHO</strong> (Tabela de configuraçãonº 2 de 07/2022) </span>
                            ) : (
                                <></>
                            )}
                        </div>
                        
                        {tipoTabela === "lista" ? (
                            <>
                                <div className="input-group-importar">
                                    <label htmlFor="nome_tabela_visualizacao" className="label-client-text">
                                        Nome Tabela Visualização {erros.nome_tabela_visualizacao && <span title={erros.nome_tabela_visualizacao}> <FiAlertTriangle size={12} color="#ff0000"/> </span>}
                                    </label>
                                    <input
                                        className="importar-input"
                                        type="text"
                                        id="nome_tabela_visualizacao"
                                        name="nome_tabela_visualizacao"
                                        value={nomeTabelaVisualizacao}
                                        onChange={e => { setNomeTabelaVisualizacao(e.target.value); }}
                                    />
                                    <span className="exemplo-importar-tabela"> Exemplo: <strong>2022 Julho.2</strong> (Tabela nº 2 de 07/2022) </span>
                                </div>

                                <div className="input-group-importar">
                                    <label htmlFor="modulo_preferencial" className="label-client-text">
                                        Módulo preferencial {erros.modulo_preferencial && <span title={erros.modulo_preferencial}> <FiAlertTriangle size={12} color="#ff0000"/> </span>}
                                    </label>

                                    {modulosDisponiveis.length > 0 ? (
                                        <select
                                            name="modulo_preferencial"
                                            className="importar-select"
                                            value={moduloPreferencial}
                                            onChange={e => { alterarModuloPreferencial(e.target.value); }}
                                        >
                                            <option value="selecione">Selecione o módulo correto</option>

                                            {modulosDisponiveis.map(x => (
                                                <option key={x.modelo} value={JSON.stringify(x)}>{x.modelo}</option>
                                            ))}
                                        </select>
                                    ) : (
                                        <select name="modulo_preferencial" className="importar-select" >
                                            <option value="selecione">Aguardando arquivo</option>
                                        </select>
                                    )}
                                </div>

                                <div className="input-group-importar">
                                    <label htmlFor="preferencia_vigente" className="label-client-text">
                                        Definir como Tabela Vigente? {erros.preferencia_vigente && <span title={erros.preferencia_vigente}> <FiAlertTriangle size={12} color="#ff0000"/> </span>} 
                                    </label>
                                    <select
                                        name="preferencia_vigente"
                                        className="importar-select"
                                        value={preferenciaVigente}
                                        onChange={e => { alterarPreferenciaVigente(e.target.value); }}
                                    >
                                        <option value="selecione">Selecione o a opção desejada</option>
                                        <option value="Sim">Sim</option>
                                        <option value="Não">Não</option>
                                    </select>
                                </div>
                            </>
                        ) : tipoTabela === "lista_alternativa" ? (
                            <>
                                <div className="input-group-importar">
                                    <label htmlFor="nome_tabela_visualizacao" className="label-client-text">
                                        Nome Tabela Visualização {erros.nome_tabela_visualizacao && <span title={erros.nome_tabela_visualizacao}> <FiAlertTriangle size={12} color="#ff0000"/> </span>}
                                    </label>
                                    <input
                                        className="importar-input"
                                        type="text"
                                        id="nome_tabela_visualizacao"
                                        name="nome_tabela_visualizacao"
                                        value={nomeTabelaVisualizacao}
                                        onChange={e => { setNomeTabelaVisualizacao(e.target.value); }}
                                    />
                                    <span className="exemplo-importar-tabela"> Exemplo: <strong>Alternativa 2022 Julho.2</strong> (Tabela alternativa nº 2 de 07/2022) </span>
                                </div>

                                <div className="input-group-importar">
                                    <label htmlFor="preferencia_vigente" className="label-client-text">
                                        Definir como Tabela Vigente Alternativa? {erros.preferencia_vigente && <span title={erros.preferencia_vigente}> <FiAlertTriangle size={12} color="#ff0000"/> </span>} 
                                    </label>
                                    <select
                                        name="preferencia_vigente"
                                        className="importar-select"
                                        value={preferenciaVigente}
                                        onChange={e => { alterarPreferenciaVigente(e.target.value); }}
                                    >
                                        <option value="selecione">Selecione o a opção desejada</option>
                                        <option value="Sim">Sim</option>
                                        <option value="Não">Não</option>
                                    </select>
                                </div>
                            </>
                        ) : tipoTabela === "lista_EV" ? (
                            <>
                                <div className="input-group-importar">
                                    <label htmlFor="nome_tabela_visualizacao" className="label-client-text">
                                        Nome Tabela Visualização {erros.nome_tabela_visualizacao && <span title={erros.nome_tabela_visualizacao}> <FiAlertTriangle size={12} color="#ff0000"/> </span>}
                                    </label>
                                    <input
                                        className="importar-input"
                                        type="text"
                                        id="nome_tabela_visualizacao"
                                        name="nome_tabela_visualizacao"
                                        value={nomeTabelaVisualizacao}
                                        onChange={e => { setNomeTabelaVisualizacao(e.target.value); }}
                                    />
                                    <span className="exemplo-importar-tabela"> Exemplo: <strong>EV 2022 Julho.2</strong> (Tabela EV nº 2 de 07/2022) </span>
                                </div>

                                <div className="input-group-importar">
                                    <label htmlFor="preferencia_vigente" className="label-client-text">
                                        Definir como Tabela Vigente EV? {erros.preferencia_vigente && <span title={erros.preferencia_vigente}> <FiAlertTriangle size={12} color="#ff0000"/> </span>} 
                                    </label>
                                    <select
                                        name="preferencia_vigente"
                                        className="importar-select"
                                        value={preferenciaVigente}
                                        onChange={e => { alterarPreferenciaVigente(e.target.value); }}
                                    >
                                        <option value="selecione">Selecione o a opção desejada</option>
                                        <option value="Sim">Sim</option>
                                        <option value="Não">Não</option>
                                    </select>
                                </div>
                            </>
                        ) : tipoTabela === "lista_BAT" ? (
                            <>
                                <div className="input-group-importar">
                                    <label htmlFor="nome_tabela_visualizacao" className="label-client-text">
                                        Nome Tabela Visualização {erros.nome_tabela_visualizacao && <span title={erros.nome_tabela_visualizacao}> <FiAlertTriangle size={12} color="#ff0000"/> </span>}
                                    </label>
                                    <input
                                        className="importar-input"
                                        type="text"
                                        id="nome_tabela_visualizacao"
                                        name="nome_tabela_visualizacao"
                                        value={nomeTabelaVisualizacao}
                                        onChange={e => { setNomeTabelaVisualizacao(e.target.value); }}
                                    />
                                    <span className="exemplo-importar-tabela"> Exemplo: <strong>BAT 2022 Julho.2</strong> (Tabela BAT nº 2 de 07/2022) </span>
                                </div>

                                <div className="input-group-importar">
                                    <label htmlFor="preferencia_vigente" className="label-client-text">
                                        Definir como Tabela Vigente BAT? {erros.preferencia_vigente && <span title={erros.preferencia_vigente}> <FiAlertTriangle size={12} color="#ff0000"/> </span>} 
                                    </label>
                                    <select
                                        name="preferencia_vigente"
                                        className="importar-select"
                                        value={preferenciaVigente}
                                        onChange={e => { alterarPreferenciaVigente(e.target.value); }}
                                    >
                                        <option value="selecione">Selecione o a opção desejada</option>
                                        <option value="Sim">Sim</option>
                                        <option value="Não">Não</option>
                                    </select>
                                </div>
                            </>
                        ) : tipoTabela === "lista_GR" ? (
                            <>
                                <div className="input-group-importar">
                                    <label htmlFor="nome_tabela_visualizacao" className="label-client-text">
                                        Nome Tabela Visualização {erros.nome_tabela_visualizacao && <span title={erros.nome_tabela_visualizacao}> <FiAlertTriangle size={12} color="#ff0000"/> </span>}
                                    </label>
                                    <input
                                        className="importar-input"
                                        type="text"
                                        id="nome_tabela_visualizacao"
                                        name="nome_tabela_visualizacao"
                                        value={nomeTabelaVisualizacao}
                                        onChange={e => { setNomeTabelaVisualizacao(e.target.value); }}
                                    />
                                    <span className="exemplo-importar-tabela"> Exemplo: <strong>GR 2022 Julho.2</strong> (Tabela GR nº 2 de 07/2022) </span>
                                </div>

                                <div className="input-group-importar">
                                    <label htmlFor="preferencia_vigente" className="label-client-text">
                                        Definir como Tabela Vigente GR? {erros.preferencia_vigente && <span title={erros.preferencia_vigente}> <FiAlertTriangle size={12} color="#ff0000"/> </span>} 
                                    </label>
                                    <select
                                        name="preferencia_vigente"
                                        className="importar-select"
                                        value={preferenciaVigente}
                                        onChange={e => { alterarPreferenciaVigente(e.target.value); }}
                                    >
                                        <option value="selecione">Selecione o a opção desejada</option>
                                        <option value="Sim">Sim</option>
                                        <option value="Não">Não</option>
                                    </select>
                                </div>
                            </>
                        ) : tipoTabela === "lista_OM" ? (
                            <>
                                <div className="input-group-importar">
                                    <label htmlFor="nome_tabela_visualizacao" className="label-client-text">
                                        Nome Tabela Visualização {erros.nome_tabela_visualizacao && <span title={erros.nome_tabela_visualizacao}> <FiAlertTriangle size={12} color="#ff0000"/> </span>}
                                    </label>
                                    <input
                                        className="importar-input"
                                        type="text"
                                        id="nome_tabela_visualizacao"
                                        name="nome_tabela_visualizacao"
                                        value={nomeTabelaVisualizacao}
                                        onChange={e => { setNomeTabelaVisualizacao(e.target.value); }}
                                    />
                                    <span className="exemplo-importar-tabela"> Exemplo: <strong>OM 2022 Julho.2</strong> (Tabela OM nº 2 de 07/2022) </span>
                                </div>

                                <div className="input-group-importar">
                                    <label htmlFor="preferencia_vigente" className="label-client-text">
                                        Definir como Tabela Vigente OM? {erros.preferencia_vigente && <span title={erros.preferencia_vigente}> <FiAlertTriangle size={12} color="#ff0000"/> </span>} 
                                    </label>
                                    <select
                                        name="preferencia_vigente"
                                        className="importar-select"
                                        value={preferenciaVigente}
                                        onChange={e => { alterarPreferenciaVigente(e.target.value); }}
                                    >
                                        <option value="selecione">Selecione o a opção desejada</option>
                                        <option value="Sim">Sim</option>
                                        <option value="Não">Não</option>
                                    </select>
                                </div>
                            </>
                        ) : tipoTabela === "lista_NB" ? (
                            <>
                                <div className="input-group-importar">
                                    <label htmlFor="nome_tabela_visualizacao" className="label-client-text">
                                        Nome Tabela Visualização {erros.nome_tabela_visualizacao && <span title={erros.nome_tabela_visualizacao}> <FiAlertTriangle size={12} color="#ff0000"/> </span>}
                                    </label>
                                    <input
                                        className="importar-input"
                                        type="text"
                                        id="nome_tabela_visualizacao"
                                        name="nome_tabela_visualizacao"
                                        value={nomeTabelaVisualizacao}
                                        onChange={e => { setNomeTabelaVisualizacao(e.target.value); }}
                                    />
                                    <span className="exemplo-importar-tabela"> Exemplo: <strong>NB 2022 Julho.2</strong> (Tabela NB nº 2 de 07/2022) </span>
                                </div>

                                <div className="input-group-importar">
                                    <label htmlFor="preferencia_vigente" className="label-client-text">
                                        Definir como Tabela Vigente NB? {erros.preferencia_vigente && <span title={erros.preferencia_vigente}> <FiAlertTriangle size={12} color="#ff0000"/> </span>} 
                                    </label>
                                    <select
                                        name="preferencia_vigente"
                                        className="importar-select"
                                        value={preferenciaVigente}
                                        onChange={e => { alterarPreferenciaVigente(e.target.value); }}
                                    >
                                        <option value="selecione">Selecione o a opção desejada</option>
                                        <option value="Sim">Sim</option>
                                        <option value="Não">Não</option>
                                    </select>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        <label htmlFor="input_arquivo" className="label-client-text">Arquivo {erros.input_arquivo && <span title={erros.input_arquivo}> <FiAlertTriangle size={12} color="#ff0000"/> </span>} </label>
                        <br />
                        <input name="input_arquivo" type="file" id="input_arquivo" className="input_file files" onChange={(e) => {parseArquivo(e);}} />
                        
                        <div className="dados-cliente-wrapper-botoes">
                            <button
                                className="button-cancel"
                                onClick={() => {
                                    document.getElementById("input_arquivo").value = "";
                                    setFiles([]);
                                    setTableHeader([]);
                                    setTableBody([]);
                                    setTipoTabela('selecione');
                                    setNomeTabelaServidor('');
                                    setNomeTabelaVisualizacao("");
                                    setModulosDisponiveis([]);
                                    setModuloPreferencial("");
                                    setPreferenciaVigente("");
                                    setFrete("");
                                    setNomeArquivo("");
                                    setLogImportar(["Aguardando..."]);
                                    setErros({});
                                }}
                                type="button"
                            >
                                Limpar
                            </button>
                            <button className="button-submit" type="submit"> Salvar </button>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                    </form>

                    <div className="importar-log">
                        <div className="input-group-importar">
                            <label htmlFor="nome_razao_cliente" className="label-client-text">
                                Log importação
                            </label>
                            <div className="importar-input-textarea">
                                {logImportar.map(x => (
                                    <>
                                        <span className="">{x}</span>
                                        <br />
                                        <br />
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <h3>Pre-visualização</h3>
            <br />
            <div className="wrapper-table-importar">
                <table className="table-pre-visualizacao">
                    <thead>
                        <tr>
                            {tableHeader.length > 1 ? (
                                tableHeader.map((header) => (
                                    <th key={header}>
                                        {header}
                                    </th>
                                ))
                            ) : (
                                <th className="importar-tabela-vazia">Tabela vazia</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {(tableBody.length > 0 && tableBody) ? (
                            tableBody.map((tableBodyRow) => (
                                <tr key={tableBodyRow[0]}>
                                    {tableBodyRow.map((celula, i) => (
                                        <td key={tableBodyRow[0] + '_' + i}>
                                            {celula}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <>
                                <tr>
                                    <td className="importar-tabela-vazia texto-center"> ..... </td>
                                </tr>
                                <tr>
                                    <td className="importar-tabela-vazia texto-center"> ..... </td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
};