// Referências externas
import React, { useState, useEffect } from 'react';
import { FiFilePlus } from 'react-icons/fi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Referências internas
import { Header, Loading } from '../../common';
import api from '../../services/api';
import ModalNovaPropostaBranco from './form/ModalNovaPropostaBranco';
import ModalEditarPropostaBranco from './form/ModalEditarPropostaBranco';

export default function PropostaBranco() {
    const [proposals, setProposals] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [responsaveis, setResponsaveis] = useState([]);
    const [done, setDone] = useState(true);
    const [listasCarregando, setListasCarregando] = useState(true);
    const [propostaBrancoEdicao, setPropostaBrancoEdicao] = useState(true);
    const [dadosModal, setDadosModal] = useState({
        id_cliente_proposta: "0",
        id_usuario_responsavel_proposta: "0",
        data_apresentacao_proposta: "0",
        numero_proposta: "0",
        revisao_proposta: "0"
    });
    const [errosModal, setErrosModal] = useState({});
    const [enviandoDadosModal, setEnviandoDadosModal] = useState(false);

    const [buscaListaPropostas, setBuscaListaPropostas] = useState('');
    const [modalNovaPropostaBrancoAberto, setModalNovaPropostaBrancoAberto] = useState(false);
    const [modalEditarPropostaBrancoAberto, setModalEditarPropostaBrancoAberto] = useState(false);

    function validarFormularioEditarPropostaBranco() {
        let saida = {tamanho: 0};

        if (dadosModal.id_cliente_proposta === "0") {
            saida.id_cliente_proposta = "Valor inválido";
            saida.tamanho += 1;
        }

        if (dadosModal.id_usuario_responsavel_proposta === "0") {
            saida.id_usuario_responsavel_proposta = "Valor inválido";
            saida.tamanho += 1;
        }

        if (dadosModal.data_apresentacao_proposta === "0") {
            saida.data_apresentacao_proposta = "Valor inválido";
            saida.tamanho += 1;
        }

        if (dadosModal.numero_proposta === "0") {
            saida.numero_proposta = "Valor inválido";
            saida.tamanho += 1;
        }

        if (dadosModal.revisao_proposta === "0") {
            saida.revisao_proposta = "Valor inválido";
            saida.tamanho += 1;
        }

        return saida;
    }

    function validarFormularioNovaPropostaBranco() {
        let saida = {tamanho: 0};

        if (dadosModal.id_cliente_proposta === "0") {
            saida.id_cliente_proposta = "Valor inválido";
            saida.tamanho += 1;
        }

        if (dadosModal.id_usuario_responsavel_proposta === "0") {
            saida.id_usuario_responsavel_proposta = "Valor inválido";
            saida.tamanho += 1;
        }

        return saida;
    }

    function vocativo(proposta) {
        if(proposta.titulo_cliente !== "N/A") {
            return proposta.titulo_cliente + " " + proposta.nome_razao_cliente;
        } else {
            return proposta.nome_razao_cliente;
        }
    }

    function normalizar_texto(texto) {
        texto = texto.toString();
        texto = texto.toLowerCase();
        texto = texto.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
        texto = texto.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
        texto = texto.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
        texto = texto.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
        texto = texto.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
        texto = texto.replace(new RegExp('[Ç]','gi'), 'c');
        return texto;
    }

    const notificarSucessoAoSalvar = () => {
        toast.success('Sucesso ao salvar a proposta!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        toast.info('Número da proposta copiado para a área de transferência.', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarSucessoAoEditar = () => {
        toast.success('Sucesso ao editar a proposta!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }

    const notificarSucessoAoDuplicar = () => {
        toast.success('Sucesso ao duplicar a proposta!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }
    
    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    async function editarPropostaBranco() {
        try {
            setEnviandoDadosModal(true);

            const erros = validarFormularioEditarPropostaBranco();
            setErrosModal(erros);

            if (erros.tamanho === 0) {
                const resposta = await api.patch('propostas/branco', {
                    ...dadosModal,
                    "data_apresentacao_proposta": dadosModal.data_apresentacao_proposta + " 00:00:00"
                })
                setProposals(resposta.data);
                
                setModalEditarPropostaBrancoAberto(false);
                
                notificarSucessoAoEditar();

                setDadosModal({
                    id_cliente_proposta: "0",
                    id_usuario_responsavel_proposta: "0",
                    data_apresentacao_proposta: "0",
                    numero_proposta: "0",
                    revisao_proposta: "0"
                });
            }
        } catch (error) {
            notificarErro();
        }

        setEnviandoDadosModal(false);
    }

    async function salvarPropostaBranco() {
        try {
            setEnviandoDadosModal(true);

            const erros = validarFormularioNovaPropostaBranco();
            setErrosModal(erros);

            if (erros.tamanho === 0) {
                const resposta = await api.post('propostas/branco', dadosModal)
                setProposals([
                    resposta.data,
                    ...proposals
                ]);
                
                navigator.clipboard.writeText(resposta.data.numero_proposta);

                setModalNovaPropostaBrancoAberto(false);
                
                notificarSucessoAoSalvar();

                setDadosModal({
                    id_cliente_proposta: "0",
                    id_usuario_responsavel_proposta: "0",
                    data_apresentacao_proposta: "0",
                    numero_proposta: "0",
                    revisao_proposta: "0"
                });
            }

        } catch (error) {
            notificarErro();
        }

        setEnviandoDadosModal(false);
    }

    async function novaRevisao(propostaSelecionada) {
        try {
            setDone(false);

            const send = {
                "numero_proposta_duplicada": propostaSelecionada.numero_proposta,
                "revisao_proposta_duplicada": propostaSelecionada.revisao_proposta
            }
            const response = await api.post('propostas/duplicar-branco', send);

            notificarSucessoAoDuplicar();

            setProposals(response.data);
        } catch (error) {
            notificarErro();
        }

        setDone(true);
    }

    useEffect(() => {
        document.title = `SGC Brazil Solution`;

        async function solicitarPropostasEClientes() {
            setDone(false);
            try {
                const response = await api.get('propostas/branco')
                setProposals(response.data);
            } catch (error) {
                notificarErro();
            }
            setDone(true);
            
            setListasCarregando(true);
            try {
                const consultaClientes = await api.get('clientes')
                setClientes(consultaClientes.data);

                const consultaResponsaveis = await api.get('dados')
                setResponsaveis(consultaResponsaveis.data.responsaveis);
            } catch (error) {
                notificarErro();
            }
            setListasCarregando(false);
        }

        solicitarPropostasEClientes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <Header />
            <ToastContainer />

            <div className="body-container">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="type1-title">
                            <div>PROPOSTAS EM BRANCO</div>
                        </div>

                        <input
                            autoFocus
                            name="busca_lista_proposta"
                            className="text-input-search"
                            type="text"
                            id="busca_lista_proposta"
                            placeholder="Pesquisar"
                            onChange={e => { setBuscaListaPropostas(e.target.value); }}
                            autoComplete="off"
                        />

                        { modalNovaPropostaBrancoAberto && <ModalNovaPropostaBranco listasCarregando={listasCarregando} clientes={clientes} responsaveis={responsaveis} fechar={() => setModalNovaPropostaBrancoAberto(false)} dadosModal={dadosModal} setDadosModal={setDadosModal} errosModal={errosModal} enviandoDadosModal={enviandoDadosModal} setEnviandoDadosModal={setEnviandoDadosModal} salvarPropostaBranco={salvarPropostaBranco} normalizar_texto={normalizar_texto} /> }
                        { modalEditarPropostaBrancoAberto && <ModalEditarPropostaBranco listasCarregando={listasCarregando} clientes={clientes} responsaveis={responsaveis} fechar={() => setModalEditarPropostaBrancoAberto(false)} dadosModal={dadosModal} setDadosModal={setDadosModal} enviandoDadosModal={enviandoDadosModal} setEnviandoDadosModal={setEnviandoDadosModal} editarPropostaBranco={editarPropostaBranco}  propostaBrancoEdicao={propostaBrancoEdicao} vocativo={vocativo} /> }
                        
                        <button className="type1-main-button"  onClick={() => {setModalNovaPropostaBrancoAberto(true)}}>
                            <FiFilePlus/>
                            <span>Nova proposta</span>
                        </button>
                    </div>
                    <div className="type3-content">
                        {!done ? (
                            <div>
                                <Loading />
                            </div>
                        )  :  (
                            <table className="proposals-table">
                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>Revisão</th>
                                        <th>Cliente</th>
                                        <th>Data</th>
                                        <th>Agente Comercial</th>
                                        <th>Criador Proposta</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {(proposals.length > 0) && proposals
                                        .filter(proposal => {
                                            if (buscaListaPropostas.length > 0 && buscaListaPropostas.replace(new RegExp('[0-9]','gi'), '') === '') {
                                                const proposta_atual_normalizada = normalizar_texto(proposal.numero_proposta);
                                                const proposta_desejada_normalizada = normalizar_texto(buscaListaPropostas);

                                                if (proposta_atual_normalizada.includes(proposta_desejada_normalizada)) {
                                                    return proposal.nome_razao_cliente;
                                                }
                                            } else if (buscaListaPropostas.length > 0) {
                                                const cliente_atual_normalizado = normalizar_texto(proposal.nome_razao_cliente);
                                                const cliente_desejado_normalizado = normalizar_texto(buscaListaPropostas);

                                                if (cliente_atual_normalizado.includes(cliente_desejado_normalizado)) {
                                                    return proposal.nome_razao_cliente;
                                                }
                                            } else {
                                                return proposal.nome_razao_cliente;
                                            }
                                        })
                                        .map(proposal => {
                                            return (
                                                <tr key={String(proposal.numero_proposta)+' '+String(proposal.revisao_proposta)}>
                                                    <td>{proposal.numero_proposta}</td>
                                                    <td>{proposal.revisao_proposta}</td>
                                                    <td>{vocativo(proposal)}</td>
                                                    <td>{(new Date(proposal.data_apresentacao_proposta)).toLocaleDateString()}</td>
                                                    <td>{proposal.nome_usuario}</td>
                                                    <td>{proposal.nome_usuario_criador}</td>

                                                    <td>
                                                        <div className="type4-functions">
                                                            <div>
                                                                <button className="view-button1" onClick={() => {
                                                                    setPropostaBrancoEdicao(proposal);
                                                                    setModalEditarPropostaBrancoAberto(true);
                                                                }}>
                                                                    Editar
                                                                </button>
                                                            </div>

                                                            <div>
                                                                <button className="view-button2" onClick={() => novaRevisao(proposal)}>
                                                                    Nova revisão
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}