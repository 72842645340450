// Referências externas
import React, {useState, useEffect} from 'react';
import { FiRotateCw, FiAlertTriangle } from 'react-icons/fi';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Referências internas
import validar from './validarEstado';
import useForm from './useForm';
import '../../styles.css';
import ModalRestaurar from './ModalRestaurar';
import ModalUnitario from './ModalUnitario';

const Form = ( props ) => {
  const {
    erros,
    alteracao_desconto_WEG,
    alteracao_tabela,
    alteracao_concessionaria,
    alteracao_tipo_inversor,
    alteracao_responsavel,
    alteracao_estrutura,
    alteracao_modulo,
    alteracao_frete,
    apagar_consumo,
    restaurar_curva_consumo,
    alteracao_media,
    alteracao_mes_consumo,
    restaurar_curva_geracao,
    alteracao_mes_geracao,
    setValueObject,
    handleChange,
    handleSubmit} = useForm(
      props.notificarErroPreenchimentoFormulario,
      props.estadoFormulario,
      props.setEstadoFormulario,
      props.restringirBotoes,
      props.gerarProposta,
      props.dadosRoute,
      atualizar_estrutura_modulo_frete,
      validar
  );
  
  const [modalUnitarioAberto, setModalUnitarioAberto] = useState(false);
  const [modalRestaurarAberto, setModalRestaurarAberto] = useState(false);

  const [chave_tabela_vigente, setChave_tabela_vigente] = useState({chave: ''});

  const [tabelas_validas, setTabelas_validas] = useState([]);
  const [concessionarias_validas, setConcessionarias_validas] = useState([]);
  const [responsaveis_validos, setResponsaveis_validos] = useState([]);
  const [modulos_validos, setModulos_validos] = useState([]);
  const [tipos_frete_validos, setTipos_frete_validos] = useState([]);
  const [estruturas_validas, setEstruturas_validas] = useState([]);

  function atualizar_estrutura_modulo_frete(tabela_selecionada, tabelas_validas) {
        for (const i in tabelas_validas) {
      if (tabela_selecionada === tabelas_validas[i].chave) {
        setModulos_validos(tabelas_validas[i].modulos);
        setTipos_frete_validos(tabelas_validas[i].tipos_frete);
        setEstruturas_validas(tabelas_validas[i].estruturas);
      }
    }
  }
  
  function verificar_proposta_existente() {
    if (Object.keys(props.propostaExistente).length > 0) {
      atualizar_estrutura_modulo_frete(props.propostaExistente.tabela_considerada, tabelas_validas);

      setValueObject({
        tabela: props.propostaExistente.tabela_considerada,
        concessionaria: props.propostaExistente.concessionaria_proposta,
        responsavel: props.propostaExistente.nome_usuario,
        id_responsavel: props.propostaExistente.id_usuario_responsavel_proposta,
        comissao: Math.round(props.propostaExistente.comissao_proposta * 100) / 100,
        percentual_atendido: Math.round(props.propostaExistente.percentual_atendido * 100) / 100,
        tarifa: Math.round(props.propostaExistente.tarifa_proposta * 100) / 100,
        valor_fixo: Math.round(props.propostaExistente.valor_fixo * 100) / 100,
        relacao_ICMS: Math.round(props.propostaExistente.parcela_icms_proposta * 100) / 100,
        aplicacao_estrutura: props.propostaExistente.aplicacao_estrutura,
        tipo_estrutura: props.propostaExistente.tipo_estrutura,
        modulo_modelo: props.propostaExistente.sistema_modulo[0].modelo,
        modulo_potencia: Math.round(props.propostaExistente.sistema_modulo[0].potencia * 100) / 100,
        modulo_area: props.propostaExistente.sistema_modulo[0].area,
        modulo_peso: Math.round(props.propostaExistente.sistema_modulo[0].peso * 100) / 100,
        modulo_garantia1: props.propostaExistente.sistema_modulo[0].garantia1,
        modulo_garantia2: props.propostaExistente.sistema_modulo[0].garantia2,
        modulo_campo_adicional1: props.propostaExistente.sistema_modulo[0].campo_adicional1,
        laudo_estrutural: props.propostaExistente.laudo_estrutural,
        rede_380: props.propostaExistente.rede_380,
        smart_meter: props.propostaExistente.smart_meter,
        zero_export: props.propostaExistente.zero_export,
        opex_inversor: props.propostaExistente.opex_inversor,
        tipo_frete: props.propostaExistente.tipo_frete,
        acrescimo_frete: props.propostaExistente.acrescimo_frete,
        outros_valores: Math.round(props.propostaExistente.outros_valores * 100) / 100,
        margem_proposta: Math.round(props.propostaExistente.margem_proposta * 100) / 100,
        material_proposta: Math.round(props.propostaExistente.material_proposta * 100) / 100,
        desconto_weg_proposta: props.propostaExistente.desconto_weg_proposta.map(x => (Math.round(x * 100) / 100)),
        imposto_proposta: Math.round(props.propostaExistente.imposto_proposta * 100) / 100,
        consumo_medio: Math.round((props.propostaExistente.consumo.reduce((acc, cur) => acc + cur)) / 12),
        curva_consumo: props.propostaExistente.consumo,
        consumo_janeiro: Math.round(props.propostaExistente.consumo[0] * 100) / 100,
        consumo_fevereiro: Math.round(props.propostaExistente.consumo[1] * 100) / 100,
        consumo_marco: Math.round(props.propostaExistente.consumo[2] * 100) / 100,
        consumo_abril: Math.round(props.propostaExistente.consumo[3] * 100) / 100,
        consumo_maio: Math.round(props.propostaExistente.consumo[4] * 100) / 100,
        consumo_junho: Math.round(props.propostaExistente.consumo[5] * 100) / 100,
        consumo_julho: Math.round(props.propostaExistente.consumo[6] * 100) / 100,
        consumo_agosto: Math.round(props.propostaExistente.consumo[7] * 100) / 100,
        consumo_setembro: Math.round(props.propostaExistente.consumo[8] * 100) / 100,
        consumo_outubro: Math.round(props.propostaExistente.consumo[9] * 100) / 100,
        consumo_novembro: Math.round(props.propostaExistente.consumo[10] * 100) / 100,
        consumo_dezembro: Math.round(props.propostaExistente.consumo[11] * 100) / 100,
        relacao_geracao: Math.round(props.propostaExistente.relacao_geracao * 100) / 100,
        curva_geracao: props.propostaExistente.geracao,
        geracao_janeiro: Math.round(props.propostaExistente.geracao[0] * 100) / 100,
        geracao_fevereiro: Math.round(props.propostaExistente.geracao[1] * 100) / 100,
        geracao_marco: Math.round(props.propostaExistente.geracao[2] * 100) / 100,
        geracao_abril: Math.round(props.propostaExistente.geracao[3] * 100) / 100,
        geracao_maio: Math.round(props.propostaExistente.geracao[4] * 100) / 100,
        geracao_junho: Math.round(props.propostaExistente.geracao[5] * 100) / 100,
        geracao_julho: Math.round(props.propostaExistente.geracao[6] * 100) / 100,
        geracao_agosto: Math.round(props.propostaExistente.geracao[7] * 100) / 100,
        geracao_setembro: Math.round(props.propostaExistente.geracao[8] * 100) / 100,
        geracao_outubro: Math.round(props.propostaExistente.geracao[9] * 100) / 100,
        geracao_novembro: Math.round(props.propostaExistente.geracao[10] * 100) / 100,
        geracao_dezembro: Math.round(props.propostaExistente.geracao[11] * 100) / 100,

        tipo_inversor: props.propostaExistente.tipo_inversor,
        status_proposta: props.propostaExistente.status_proposta
      });
    };
  }
  
  useEffect(() => {
    if (Object.keys(props.dadosRoute).length > 0) {
      setChave_tabela_vigente(props.dadosRoute.tabela_vigente.chave);

      setTabelas_validas(props.dadosRoute.tabelas_disponiveis);
      setConcessionarias_validas(props.dadosRoute.concessionarias);
      setResponsaveis_validos(props.dadosRoute.responsaveis);
      setModulos_validos(props.dadosRoute.tabela_vigente.modulos);
      setTipos_frete_validos(Object.values(props.dadosRoute.tabela_vigente.tipos_frete));
      setEstruturas_validas(Object.values(props.dadosRoute.tabela_vigente.estruturas));

      let array_consumo = Object.values(props.dadosRoute.curvas.consumo1);
      let array_geracao = Object.values(props.dadosRoute.curvas.geracao1);

      setValueObject({
        tabela: props.dadosRoute.tabela_vigente.chave,
        valor_fixo: Math.round(props.dadosRoute.informacao_complementar_float.valor_fixo_padrao.valor * 100) / 100,
        modulo_modelo: props.dadosRoute.tabela_vigente.modulo_preferencial.modelo,
        modulo_potencia: Math.round(props.dadosRoute.tabela_vigente.modulo_preferencial.potencia * 100) / 100,
        modulo_area: props.dadosRoute.tabela_vigente.modulo_preferencial.area,
        modulo_peso: Math.round(props.dadosRoute.tabela_vigente.modulo_preferencial.peso * 100) / 100,
        modulo_garantia1: props.dadosRoute.tabela_vigente.modulo_preferencial.garantia1,
        modulo_garantia2: props.dadosRoute.tabela_vigente.modulo_preferencial.garantia2,
        modulo_campo_adicional1: props.dadosRoute.tabela_vigente.modulo_preferencial.campo_adicional1,
        tipo_frete: Object.values(props.dadosRoute.tabela_vigente.tipos_frete)[0].tipo,
        acrescimo_frete: Object.values(props.dadosRoute.tabela_vigente.tipos_frete)[0].acrescimo,
        margem_proposta: Math.round(props.dadosRoute.informacao_complementar_float.Margem.valor * 100) / 100,
        material_proposta: Math.round(props.dadosRoute.informacao_complementar_float.Material.valor * 100) / 100,
        desconto_weg_proposta: props.dadosRoute.desconto_weg.map(x => (Math.round(x * 100) / 100)),
        imposto_proposta: Math.round(props.dadosRoute.informacao_complementar_float.Imposto.valor * 100) / 100,
        curva_consumo: array_consumo,
        consumo_medio: Math.round( (array_consumo.reduce((acc, cur) => acc + cur)) / 12 ),
        consumo_janeiro: Math.round(array_consumo[0] * 100) / 100,
        consumo_fevereiro: Math.round(array_consumo[1] * 100) / 100,
        consumo_marco: Math.round(array_consumo[2] * 100) / 100,
        consumo_abril: Math.round(array_consumo[3] * 100) / 100,
        consumo_maio: Math.round(array_consumo[4] * 100) / 100,
        consumo_junho: Math.round(array_consumo[5] * 100) / 100,
        consumo_julho: Math.round(array_consumo[6] * 100) / 100,
        consumo_agosto: Math.round(array_consumo[7] * 100) / 100,
        consumo_setembro: Math.round(array_consumo[8] * 100) / 100,
        consumo_outubro: Math.round(array_consumo[9] * 100) / 100,
        consumo_novembro: Math.round(array_consumo[10] * 100) / 100,
        consumo_dezembro: Math.round(array_consumo[11] * 100) / 100,
        relacao_geracao: Math.round(props.dadosRoute.informacao_complementar_float.relacao_geracao_padrao.valor * 100) / 100,
        curva_geracao: array_geracao,
        geracao_janeiro: Math.round(array_geracao[0] * 100) / 100,
        geracao_fevereiro: Math.round(array_geracao[1] * 100) / 100,
        geracao_marco: Math.round(array_geracao[2] * 100) / 100,
        geracao_abril: Math.round(array_geracao[3] * 100) / 100,
        geracao_maio: Math.round(array_geracao[4] * 100) / 100,
        geracao_junho: Math.round(array_geracao[5] * 100) / 100,
        geracao_julho: Math.round(array_geracao[6] * 100) / 100,
        geracao_agosto: Math.round(array_geracao[7] * 100) / 100,
        geracao_setembro: Math.round(array_geracao[8] * 100) / 100,
        geracao_outubro: Math.round(array_geracao[9] * 100) / 100,
        geracao_novembro: Math.round(array_geracao[10] * 100) / 100,
        geracao_dezembro: Math.round(array_geracao[11] * 100) / 100
      });
    }

    verificar_proposta_existente();


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataRoute, props.propostaExistente, props.tabelaUtilizada])

  return (
      <form onSubmit={handleSubmit}>
          <Tabs>
            <TabList>
              <Tab>Geral {erros.geral && <FiAlertTriangle size={12} color="#ff0000"/>}</Tab>
              <Tab>Consumo {erros.consumo && <FiAlertTriangle size={12} color="#ff0000"/>}</Tab>
              <Tab>Geração {erros.geracao && <FiAlertTriangle size={12} color="#ff0000"/>}</Tab>
            </TabList>

            <div className="proposal-subelement">
              <div className="proposal-element-body">

                <TabPanel>
                    <div className="input-group-2">
                      <label htmlFor="concessionaria">
                        Concessionária {erros.concessionaria && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="concessionaria"
                        name="concessionaria"
                        value={props.estadoFormulario.concessionaria}
                        onChange={e => { alteracao_concessionaria(e, concessionarias_validas); }} >
                          <option value ='' >Selecione</option>

                          {concessionarias_validas.map(concessionaria => (
                              <option key = {concessionaria.nome_concessionaria} value = {concessionaria.nome_concessionaria}>{concessionaria.nome_concessionaria}</option>
                          ))} 

                      </select>
                    </div>

                    <div className="input-group-2">
                      <label htmlFor="responsavel">
                        Agente Comercial {erros.responsavel && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="responsavel"
                        name="responsavel"
                        value={props.estadoFormulario.responsavel}
                        onChange={e => { alteracao_responsavel(e, responsaveis_validos); }}>
                          <option value ='' >Selecione</option>

                          {responsaveis_validos
                            .map(x => x.nome_usuario)
                            .sort()
                            .map(responsavel => (
                                <option key = {responsavel} value = {responsavel}>{responsavel}</option>
                            ))
                          }

                      </select>
                    </div>

                    <div className="input-group-narrow-2">
                      <label htmlFor="comissao" className='form-label'>
                        Comissão (%) {erros.comissao && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.comissao}
                        type='number'
                        min="0"
                        max='25'
                        step="0.1"
                        name='comissao'
                        onChange={e => {handleChange(e);}}
                      />
                    </div>

                    <div className="input-group-narrow-2-right">
                      <label htmlFor="tarifa" className='form-label'>
                        Tarifa (R$) {erros.tarifa && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.tarifa}
                        type='number'
                        min="0"
                        max='2'
                        step="0.00001"
                        name='tarifa'
                        onChange={e => {handleChange(e);}}
                      />
                    </div>
                    
                    <div className="input-group-narrow-2">
                      <label htmlFor="relacao_ICMS" className='form-label'>
                        Parcela ICMS (%) {erros.relacao_ICMS && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.relacao_ICMS}
                        type='number'
                        min="0"
                        max='100'
                        step="0.1"
                        name='relacao_ICMS'
                        onChange={e => {handleChange(e);}}
                      />
                    </div>
                    
                    <div className="input-group-narrow-2-right">
                      <label htmlFor="valor_fixo" className='form-label'>
                        Valor fixo (R$) {erros.valor_fixo && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.valor_fixo}
                        type='number'
                        min="0"
                        step="0.01"
                        name='valor_fixo'
                        onChange={e => {handleChange(e);}}
                      />
                    </div>
                    
                    <div className="input-group-2">
                      <label htmlFor="aplicacao_estrutura">
                        Tipo de estrutura {erros.aplicacao_estrutura && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="aplicacao_estrutura"
                        name="aplicacao_estrutura"
                        value={props.estadoFormulario.aplicacao_estrutura}
                        onChange={e => { alteracao_estrutura(e, estruturas_validas); }} >
                          <option value =''>Selecione</option>

                          {estruturas_validas.map(estrutura => (
                              <option key = {estrutura.aplicacao_estrutura} value = {estrutura.aplicacao_estrutura}>{estrutura.aplicacao_estrutura}</option>
                          ))}

                      </select>
                    </div>

                    <p className="secao-formulario">
                      DETALHES
                    </p>
                    
                    <div className="input-group-2">
                      <label htmlFor="tabela">
                        Lista de preços {erros.tabela && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="tabela"
                        name="tabela"
                        value={props.estadoFormulario.tabela}
                        onChange={e => {alteracao_tabela(e, tabelas_validas);}}
                      >
                        {tabelas_validas && tabelas_validas
                            .filter(function (tabela) {return tabela.chave === props.estadoFormulario.tabela})
                            .map(tabela => (
                            <option key = {tabela.chave} value = {tabela.chave}>{tabela.valor}</option>
                        ))}

                        {tabelas_validas && tabelas_validas
                            .filter(function (tabela) {return tabela.chave !== props.estadoFormulario.tabela})
                            .map(tabela => (
                            <option key = {tabela.chave} value = {tabela.chave}>{tabela.valor}</option>
                        ))}
                      </select>

                      {props.estadoFormulario.tabela !== chave_tabela_vigente ? (
                        <div className="alerta-tabela">
                          <p>
                              Atenção! Tabela desatualizada
                          </p>
                          <span>
                              <FiAlertTriangle size={18} />
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    
                    <div className="input-group-2">
                      <label htmlFor="modulo_modelo">
                        Módulo {erros.modulo_modelo && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="modulo_modelo"
                        name="modulo_modelo"
                        value={props.estadoFormulario.modulo_modelo}
                        onChange={e => { alteracao_modulo(e, modulos_validos); }} >
                          <option value ='' >Selecione</option>

                          {modulos_validos && modulos_validos
                            .map(modulo => (
                                <option key = {modulo.modelo} value = {modulo.modelo}>{modulo.modelo}</option>
                          ))}

                      </select>
                    </div>

                    <div className="input-group-2">
                      <label htmlFor="tipo_frete">
                        Frete {erros.tipo_frete && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="tipo_frete"
                        name="tipo_frete"
                        value={props.estadoFormulario.tipo_frete}
                        onChange={e => { alteracao_frete(e, tipos_frete_validos); }} >
                          {tipos_frete_validos.map(frete => (
                              <option key = {frete.tipo} value = {frete.tipo}>{frete.tipo}</option>
                          ))} 

                      </select>
                    </div>

                    <div className="input-group-narrow-2">
                      <label htmlFor="laudo_estrutural">
                        Laudo estrutural {erros.laudo_estrutural && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="laudo_estrutural"
                        name="laudo_estrutural"
                        value={props.estadoFormulario.laudo_estrutural}
                        onChange={e => { handleChange(e); }} >
                          <option value ='0' >Não</option>
                          <option value ='1' >Sim</option>
                      </select>
                    </div>

                    <div className="input-group-narrow-2-right">
                      <label htmlFor="rede_380">
                        Rede 380 V {erros.rede_380 && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="rede_380"
                        name="rede_380"
                        value={props.estadoFormulario.rede_380}
                        onChange={e => { handleChange(e); }} >
                          <option value ='0' >Não</option>
                          <option value ='1' >Sim</option>
                      </select>
                    </div>

                    <div className="input-group-narrow-2">
                      <label htmlFor="smart_meter">
                        Smart meter {erros.smart_meter && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="smart_meter"
                        name="smart_meter"
                        value={props.estadoFormulario.smart_meter}
                        onChange={e => { handleChange(e); }} >
                          <option value ='0' >Não</option>
                          <option value ='1' >Sim</option>
                      </select>
                    </div>

                    <div className="input-group-narrow-2-right">
                      <label htmlFor="zero_export">
                        Zero export {erros.zero_export && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="zero_export"
                        name="zero_export"
                        value={props.estadoFormulario.zero_export}
                        onChange={e => { handleChange(e); }} >
                          <option value ='0' >Não</option>
                          <option value ='1' >Sim</option>
                      </select>
                    </div>

                    <div className="input-group-narrow-2">
                      <label htmlFor="opex_inversor">
                        Opex inversor {erros.opex_inversor && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="opex_inversor"
                        name="opex_inversor"
                        value={props.estadoFormulario.opex_inversor}
                        onChange={e => { handleChange(e); }} >
                          <option value ='0' >Não</option>
                          <option value ='1' >Sim</option>
                      </select>
                    </div>

                    <div className="input-group-narrow-2-right">
                      <label htmlFor="outros_valores">
                        Outros valores {erros.outros_valores && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.outros_valores}
                        type='number'
                        min="0"
                        step="0.01"
                        name='outros_valores'
                        onChange={e => {handleChange(e);}}
                      />
                    </div>

                    <div className="input-group-narrow-2">
                      <label htmlFor="tipo_inversor">
                        Tipo inversor {erros.tipo_inversor && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <select
                        id="tipo_inversor"
                        name="tipo_inversor"
                        value={props.estadoFormulario.tipo_inversor}
                        onChange={e => { alteracao_tipo_inversor(e); }} >
                          <option value ='' >Selecione</option>
                          <option value ='String'>String</option>
                          <option value ='Microinversor'>Microinversor</option>
                      </select>
                    </div>

                    <p className="secao-formulario">
                      RESTRITO
                    </p>
                    
                    <div className="input-group-narrow-2">
                      <label htmlFor="margem_proposta" className='form-label'>
                        Margem (%) {erros.margem_proposta && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.margem_proposta}
                        type='number'
                        min="0"
                        max='100'
                        step="0.05"
                        name='margem_proposta'
                        onChange={e => {handleChange(e);}}
                      />
                    </div>
                    
                    <div className="input-group-narrow-2-right">
                      <label htmlFor="material_proposta" className='form-label'>
                        Material (%) {erros.material_proposta && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.material_proposta}
                        type='number'
                        min="0"
                        max='100'
                        step="0.1"
                        name='material_proposta'
                        onChange={e => {handleChange(e);}}
                      />
                    </div>
                    
                    <div className="form-simulation-desconto-weg">
                      <label htmlFor="desconto_weg_proposta" className='form-label'>
                        Desconto WEG (%) {erros.desconto_weg_proposta && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <div className="form-simulation-desconto-weg-input">
                        <div>
                          <input
                            disabled
                            value={props.estadoFormulario.desconto_weg_proposta[0]}
                            type='number'
                            min="0"
                            max='100'
                            step="0.1"
                            name='desconto_weg_proposta[0]'
                            onChange={e => {alteracao_desconto_WEG(e, 0);}}
                          />
                        </div>
                        <div>
                          <input
                            disabled
                            value={props.estadoFormulario.desconto_weg_proposta[1]}
                            type='number'
                            min="0"
                            max='100'
                            step="0.1"
                            name='desconto_weg_proposta[1]'
                            onChange={e => {alteracao_desconto_WEG(e, 1);}}
                          />
                        </div>
                        <div>
                          <input
                            disabled
                            value={props.estadoFormulario.desconto_weg_proposta[2]}
                            type='number'
                            min="0"
                            max='100'
                            step="0.1"
                            name='desconto_weg_proposta[2]'
                            onChange={e => {alteracao_desconto_WEG(e, 2);}}
                          />
                        </div>
                        <div>
                          <input
                            value={props.estadoFormulario.desconto_weg_proposta[3]}
                            type='number'
                            min="0"
                            max='22'
                            step="0.1"
                            name='desconto_weg_proposta[3]'
                            onChange={e => {alteracao_desconto_WEG(e, 3);}}
                          />
                        </div>
                      </div>
                    </div>
                    
                    <div className="input-group-narrow-2">
                      <label htmlFor="margem_proposta" className='form-label'>
                        Imposto (%) {erros.imposto_proposta && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.imposto_proposta}
                        type='number'
                        min="0"
                        max='100'
                        step="0.1"
                        name='imposto_proposta'
                        onChange={e => {handleChange(e);}}
                      />
                    </div>
                
                
                </TabPanel>

                <TabPanel>
                    { modalUnitarioAberto && <ModalUnitario setModalUnitarioAberto={setModalUnitarioAberto} apagar_consumo={apagar_consumo}/> }
                    <button type="button" className="button3-digitar" onClick={(e) => {e.preventDefault(); setModalUnitarioAberto(true);}}>
                      Digitar mês a mês
                    </button>

                    <div className="input-group-narrow-2">
                      <label htmlFor="consumo_medio">
                        Média de consumo {erros.consumo_medio && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        // className={CSSAlteracao.consumo_medio ? "borda-alteracao" : ''}
                        value={props.estadoFormulario.consumo_medio}
                        type='number'
                        min="1"
                        step="1"
                        name='consumo_medio'
                        onChange={e => {alteracao_media(e);
                          // console.log('cotrole'); setCSSAlteracao({CSSAlteracao, consumo_medio: true})
                        }}
                      />
                    </div>

                    <div className="input-group-narrow-2-right">
                      <label htmlFor="percentual_atendido" className='form-label'>
                        Cons. atendido (%) {erros.percentual_atendido && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.percentual_atendido}
                        type='number'
                        min="0.1"
                        step="0.1"
                        name='percentual_atendido'
                        onChange={e => {handleChange(e);}}
                      />
                    </div>

                    <div className="wrapper-coluna-campos">
                      <div className="coluna-campos">
                        <div className="input-group-2">
                          <label htmlFor="consumo_janeiro">
                            Janeiro {erros.consumo_janeiro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_janeiro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_janeiro'
                            onChange={e => {alteracao_mes_consumo(e, 0);}}
                          />
                        </div>
                        
                        <div className="input-group-2">
                          <label htmlFor="consumo_fevereiro">
                            Fevereiro {erros.consumo_fevereiro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_fevereiro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_fevereiro'
                            onChange={e => {alteracao_mes_consumo(e, 1);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_marco">
                            Março {erros.consumo_marco && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_marco}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_marco'
                            onChange={e => {alteracao_mes_consumo(e, 2);}}
                          />
                        </div>
                        
                        <div className="input-group-2">
                          <label htmlFor="consumo_abril">
                            Abril {erros.consumo_abril && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_abril}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_abril'
                            onChange={e => {alteracao_mes_consumo(e, 3);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_maio">
                            Maio {erros.consumo_maio && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_maio}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_maio'
                            onChange={e => {alteracao_mes_consumo(e, 4);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_junho">
                            Junho {erros.consumo_junho && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_junho}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_junho'
                            onChange={e => {alteracao_mes_consumo(e, 5);}}
                          />
                        </div>
                      </div>

                      <div className="coluna-campos">
                        <div className="input-group-2">
                          <label htmlFor="consumo_julho">
                            Julho {erros.consumo_julho && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_julho}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_julho'
                            onChange={e => {alteracao_mes_consumo(e, 6);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_agosto">
                            Agosto {erros.consumo_agosto && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_agosto}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_agosto'
                            onChange={e => {alteracao_mes_consumo(e, 7);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_setembro">
                            Setembro {erros.consumo_setembro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_setembro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_setembro'
                            onChange={e => {alteracao_mes_consumo(e, 8);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_outubro">
                            Outubro {erros.consumo_outubro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_outubro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_outubro'
                            onChange={e => {alteracao_mes_consumo(e, 9);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_novembro">
                            Novembro {erros.consumo_novembro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_novembro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_novembro'
                            onChange={e => {alteracao_mes_consumo(e, 10);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="consumo_dezembro">
                            Dezembro {erros.consumo_dezembro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.consumo_dezembro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='consumo_dezembro'
                            onChange={e => {alteracao_mes_consumo(e, 11);}}
                          />
                        </div>
                    


                      </div>
                    </div>

                    { modalRestaurarAberto && <ModalRestaurar setModalRestaurarAberto={setModalRestaurarAberto} restaurar_curva_consumo={restaurar_curva_consumo}/> }
                    <button type="button" className="button3-restaurar" onClick={(e) => {e.preventDefault(); setModalRestaurarAberto(true);}}>
                        Restaurar Curva de Consumo
                    </button>
                </TabPanel>
              
                <TabPanel>
                    <div className="input-group-2">
                      <label htmlFor="relacao_geracao">
                        Relação geração (kWh/kWp) {erros.relacao_geracao && <FiAlertTriangle size={12} color="#ff0000"/>}
                      </label>
                      <input
                        value={props.estadoFormulario.relacao_geracao}
                        type='number'
                        min="30"
                        max="150"
                        step="0.01"
                        name='relacao_geracao'
                        onChange={e => {handleChange(e);}}
                      />
                    </div>

                    <div className="wrapper-coluna-campos">
                      <div className="coluna-campos">
                        <div className="input-group-2">
                          <label htmlFor="geracao_janeiro">
                            Janeiro {erros.geracao_janeiro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.geracao_janeiro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='geracao_janeiro'
                            onChange={e => {alteracao_mes_geracao(e, 0);}}
                          />
                        </div>
                        
                        <div className="input-group-2">
                          <label htmlFor="geracao_fevereiro">
                            Fevereiro {erros.geracao_fevereiro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.geracao_fevereiro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='geracao_fevereiro'
                            onChange={e => {alteracao_mes_geracao(e, 1);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="geracao_marco">
                            Março {erros.geracao_marco && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.geracao_marco}
                            type='number'
                            min="0"
                            step="0.01"
                            name='geracao_marco'
                            onChange={e => {alteracao_mes_geracao(e, 2);}}
                          />
                        </div>
                        
                        <div className="input-group-2">
                          <label htmlFor="geracao_abril">
                            Abril {erros.geracao_abril && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.geracao_abril}
                            type='number'
                            min="0"
                            step="0.01"
                            name='geracao_abril'
                            onChange={e => {alteracao_mes_geracao(e, 3);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="geracao_maio">
                            Maio {erros.geracao_maio && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.geracao_maio}
                            type='number'
                            min="0"
                            step="0.01"
                            name='geracao_maio'
                            onChange={e => {alteracao_mes_geracao(e, 4);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="geracao_junho">
                            Junho {erros.geracao_junho && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.geracao_junho}
                            type='number'
                            min="0"
                            step="0.01"
                            name='geracao_junho'
                            onChange={e => {alteracao_mes_geracao(e, 5);}}
                          />
                        </div>
                      </div>

                      <div className="coluna-campos">
                        <div className="input-group-2">
                          <label htmlFor="geracao_julho">
                            Julho {erros.geracao_julho && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.geracao_julho}
                            type='number'
                            min="0"
                            step="0.01"
                            name='geracao_julho'
                            onChange={e => {alteracao_mes_geracao(e, 6);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="geracao_agosto">
                            Agosto {erros.geracao_agosto && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.geracao_agosto}
                            type='number'
                            min="0"
                            step="0.01"
                            name='geracao_agosto'
                            onChange={e => {alteracao_mes_geracao(e, 7);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="geracao_setembro">
                            Setembro {erros.geracao_setembro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.geracao_setembro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='geracao_setembro'
                            onChange={e => {alteracao_mes_geracao(e, 8);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="geracao_outubro">
                            Outubro {erros.geracao_outubro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.geracao_outubro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='geracao_outubro'
                            onChange={e => {alteracao_mes_geracao(e, 9);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="geracao_novembro">
                            Novembro {erros.geracao_novembro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.geracao_novembro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='geracao_novembro'
                            onChange={e => {alteracao_mes_geracao(e, 10);}}
                          />
                        </div>

                        <div className="input-group-2">
                          <label htmlFor="geracao_dezembro">
                            Dezembro {erros.geracao_dezembro && <FiAlertTriangle size={12} color="#ff0000"/>}
                          </label>
                          <input
                            value={props.estadoFormulario.geracao_dezembro}
                            type='number'
                            min="0"
                            step="0.01"
                            name='geracao_dezembro'
                            onChange={e => {alteracao_mes_geracao(e, 11);}}
                          />
                        </div>
                      </div>
                    </div>

                    <button type="button" className="button3" onClick={(e) => restaurar_curva_geracao(e)}>
                        Restaurar Curva de Geração
                    </button>
                </TabPanel>
              
                <button className="submit-input" type="submit"> <div> <p>Gerar Proposta</p> <FiRotateCw/> </div> </button>                       
                
              </div>
            </div>
          
        </Tabs>
      </form>
  );
};

export default Form;